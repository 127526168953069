
.mobile-green-background {
  background-color: #53C95A;
  color: white;
  padding: 5px;
  border-radius: 5px;
}

.change-store-title {
  color: #53C95A;
  text-decoration: underline;
  cursor: pointer;
}
