@import "../mixin/color";

.credit-card {
  &-list-item {
    // border-bottom: 1px solid #F5F5F5;

    .credit-card-icon {
      display: inline-block;
      vertical-align: text-top;
      width: 30px;
      height: 20px;
    }
  }

  &-holder {
    width: 100%;
  }

  &-item {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 380px;
    margin-right: .5rem;
    overflow: hidden;
  }

  &-check {
    position: absolute;
    top: 1px;
    right: 4px;
    color: $green;
  }

  &-item-active {
    .credit-card-delete {
      right: .5rem;
    }
  }

  .btn-danger {
    background-color: $red;
    border-color: $red;
    transition: box-shadow .25s;
    padding: 2px 6px;
    margin-top: -6px;

    &:hover {
      box-shadow: 0 2px 6px -2px $red-hover;
    }
  }

  &-delete {
    transition: right .25s;

    position: absolute;
    bottom: .5rem;
    right: -2rem;
  }

  &-default {
    transition: right .25s;
    position: absolute;
    right: 2rem;
  }

  &-body {
    margin: 0 auto;

    cursor: pointer;
    border: 1px solid $grey30;
    padding: 1px;
    border-radius: 4px;
    background-color: $background-grey10;

    &.active {
      padding: 0;
      border-color: $green;
      border-width: 2px;
      background-color: $background-white;
    }
  }

  &-icon {
    background-image: url(../assets/cards/number-active.svg);
    background-repeat: no-repeat;
    background-size: 30px 20px;
    background-position: center center;
    width: 64px;
    height: 26px;
  }

  &-type-mastercard .credit-card-icon {
    background-image: url(../assets/cards/mastercard.svg);
  }

  &-type-mir .credit-card-icon {
    background-image: url(../assets/cards/mir.svg);
  }

  &-type-maestro .credit-card-icon {
    background-image: url(../assets/cards/maestro.svg);
  }

  &-type-amex .credit-card-icon {
    background-image: url(../assets/cards/amex.svg);
  }

  &-type-visa .credit-card-icon {
    background-image: url(../assets/cards/visa.svg);
  }

  &-type-apple .credit-card-icon {
    background-image: url(../assets/cards/apple_pay.svg);
  }
}


img[lazy=error] {
  width: 100%;
}

.payment-form__error {
  color: $red;
  font-size: 12px;
  padding: 0 10px;
  height: 20px;
}

.payment-field {
  padding: 12px 14px;
  border: 1px solid #E6E6E6;
  border-radius: 6px;
}

.payment-radio label {
  width: 100%;
  cursor: pointer;
  padding: 10px 12px;
  border: 1px solid #E6E6E6;
  border-radius: 6px;
}

.payment-radio .credit-card-icon {
  height: auto;
}

.payment-radio.credit-card label {
  transition: .15s border-color ease, .15s box-shadow ease;
}

.payment-radio input[type="radio"]:checked+label {
  border-color: $green;
  box-shadow: 0 0 2px 0 $green inset;
}

.payment-radio input[type="radio"] + label:before {
  margin-right: 7px;
  align-self: center;
}
