@import "../mixin/respond";
@import "../mixin/color";

$menu-element-height: 40px;

.menu-navex {
  background-color: $grey20;
  color: $grey !important;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-style: normal;
  padding: 0.35rem 1rem;

  .icon-fold {
    height: 20px;
    width: auto;
    margin-left: 6px;
  }

  & > &__header {
    color: $grey90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.35rem 0 0.35rem 0;
    min-height: $menu-element-height;

    .fa-chevron-down {
      transition: transform .2s ease-in-out;
    }
  }

  &.expand > &__options {
    max-height: calc($menu-element-height * 5 + 5px); // max 5 items in menu
  }

  & > &__options {
    max-height: 0;
    overflow: auto;
    transition: max-height .4s ease-in-out;
    background-color: inherit;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
  }

  & > &__options > .option {
    position: relative;
    display: block;
    background-color: transparent;
    border: 0;
    padding: .35rem 1px;
    width: 100%;
    color: $grey80;
    text-align: inherit;

    &.active {
      z-index: 2;
      color: $grey90;
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline !important;
    }
  }


  &--floating {
    position: relative;
    z-index: 1001;
  }
  &--floating > &__options {
    position: absolute;
    left: -0.5px;
    right: -0.5px;
    padding: 0 14px;
    z-index: 1;
    border-radius: 0 0 10px 10px;
    top: calc(100% - 10px);
    box-shadow: 0 5px 5px -5px rgba(0,0,0,0.3);
  }


  &--outline {
    border: 1px solid $grey30;
    background-color: #FFFFFF;
  }
  &--outline > &__options {
    border-color: inherit;
    border-style: solid;
    border-width: 0 1px;
    overflow: hidden;
    transition: max-height .4s ease-in-out, border-width .2s ease-in-out;
  }
  &--outline.expand > &__options {
    border-color: inherit;
    border-style: solid;
    border-width: 0 1px 1px 1px;
    overflow: auto;
  }
}
