
.referral-banner {
  width: 100%;
  background-color: #E7E7FF;
  border-radius: 12px;
  position: relative;
  padding: 16px 12px;
}

.referral-banner__content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.referral-banner__title {
  font-family: 'Montserrat';
  font-size: 21px;
  font-weight: 700;
  line-height: 26px;
  color: #333;
  margin-bottom: 0;
}

.referral-banner__text {
  font-size: 14px;
  line-height: 17px;
  color: #333;
  margin: 0;
  max-width: 200px;
}

.referral-banner__btn {
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #fff;
  border: none;
  background-color: #333;
  border-radius: 16px;
  width: max-content;
}

.referral-banner__image {
  position: absolute;
  bottom: 16px;
  right: 12px;
}

.referral-banner__image > img {
  width: 97px;
  height: auto;
}

.referral-banner__input {
  display: none;
}

@media screen and (min-width: 1024px) {
  .referral-banner {
    padding: 34px 24px;
    gap: 12px;
  }

  .referral-banner__wrapper {
    max-width: 664px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .referral-banner__content {
    width: 100%;
  }

  .referral-banner--mini {
    padding: 16px 12px;
    gap: 8px;
  }

  .referral-banner:not(.referral-banner--mini) .referral-banner__title {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .referral-banner:not(.referral-banner--mini) .referral-banner__text {
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    max-width: none;
  }

  .referral-banner:not(.referral-banner--mini) .referral-banner__btn {
    display: none;
  }

  .referral-banner:not(.referral-banner--mini) .referral-banner__image > img {
    width: 160px;
  }

  .referral-banner:not(.referral-banner--mini) .referral-banner__image {
    bottom: 50%;
    position: static;
  }

  .referral-banner--collection .referral-banner__wrapper {
    max-width: none;
  }

  .referral-banner__input {
    position: relative;
    display: block;
    width: 100%;
  }

  .referral-banner__input > input {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 18px 56px 18px 16px;
    color: #333;
    border: 1px solid #E6E6E6;
    border-radius: 12px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .referral-banner__input > button {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    border: none;
    background: none;
  }

  .referral-banner__input > button > svg {
    width: 24%;
    height: 24px;
  }
}
