@import "../mixin/color";
@import "../mixin/respond";

.action-cart {
  &__body {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 35px;
    margin-top: 5px;
  }

  &__items {
    flex: 2;
    padding: 15px 0;
  }

  &__pricer {
    flex: 1;
    position: sticky;
    top: 80px; // header offset
    align-self: flex-start;
    padding: 15px 0;
    z-index: 100;
  }
}

.cart-items {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: $grey90;
    margin: 0;
  }

  &__count {
    color: $green
  }

  &__clear-all {
    display: inline-block;
    padding: 8px 0 8px 8px;
    color: $grey40 !important;
    border: none;
    text-align: center;
    outline: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 14px;

    &:hover {
      color: $grey50 !important;
    }
  }

  /* Product Carousel (Suggested replacements) */
  .product-carousel__title {
    font-size: 15px;
  }
}

.cart-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;

  &__badge {
    width: 16px;
    height: 16px;
  }

  &__name {
    color: $grey90;
    font-size: 14px;
  }

  &__title {
    position: relative;
  }

  .product-card__labels {
    position: relative;
    bottom: auto;
    left: auto;
  }

  &__image {
    width: 80px;
    height: 80px;
    border-radius: 8px;
    position: relative;
    overflow: hidden;

    img {
      object-fit: contain;
      background-color: #f6f6f6;
      width: 100%;
      height: 100%;
    }
  }

  &__image-oos {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    color: #FFF;
    font-size: 80%;
    backdrop-filter: blur(2px);
  }

  &--oos &__image {
    width: 100px;
    height: 100px;
  }

  &--oos &__image-oos {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: 1fr auto;
    grid-template-areas: "label remove" "counter counter";
    flex: 1;
    padding-left: 10px;
  }

  &__label {
    grid-area: label;
  }

  &__clear {
    float: right;
    padding: 6px;
    color: $grey40 !important;
    border: none;
    text-align: center;
    outline: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__quantity {
    display: flex;
  }

  &__price {
    align-self: end;
    padding-right: 10px;

    &--gift {
      color: #F57D40;
    }
  }

  .cart-sold-out {
    .quantity-control {
      .btn {
        .fa-angle-down {
          transition: all ease-in-out 0.4s;
          transform: rotate(0deg);
        }

        &[disabled] {
          .fa-angle-down {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

@include respond-below(md) {
  .action-cart__items {
    padding: 0;
  }

  .cart-items {
    &__header {
      align-items: center;
    }

    &__title {
      font-size: 20px;
    }

    &__clear-all {
      font-size: 12px;
    }
  }

  .cart-item {
    &__label {
      grid-area: label;
      font-size: 12px;
      line-height: 1.3;
    }

    &__price {
      font-size: 14px;
    }

    &__name {
      font-size: 11px;
    }
  }
}


.cart-pricer {
  display: flex;
  flex-direction: column;

  &__tooltip {
    position: relative;
    display: inline-block;
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 24px;
    color: $grey90;
  }

  &__item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding: 5px 0;
    margin: 0;

    > *:last-child {
      font-weight: 700;
    }

    > span {
      position: relative;
    }
  }

  &--total {
    font-size: 20px;
    font-weight: bold;
  }

  &--item-green {
    color: $green;
    display: flex;
  }

  &--item-yellow {
    color: $secondary;
    display: flex;
  }

  &--item-icon {
    cursor: pointer;
  }

  &__ctrl {
    margin-top: 10px;
  }

  &__button {
    display: block;
    gap: 2px;
    background-color: $green;
    border: none;
    color: white;
    padding: 12px 22px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transform: translateZ(0);
    transition: .2s background-color ease-in-out;

    font-family: "Montserrat", sans-serif;

    &:hover {
      background-color: $btn-hover-green;
      color: white;
    }

    &[disabled] {
      background-color: $grey40 !important;
      cursor: initial;
    }

    .fa {
      position: absolute;
      left: 20px;
      top: 100%;
      transition: .2s top ease-in-out;
    }

    &[loading] .fa {
      top: calc(50% - 8px);
    }
  }
}

.pricer-tooltip {
  display: none;
  background-color: $grey80;
  border-radius: 16px;
  color: white;
  font-size: 14px;
  padding: 16px;
  z-index: 1;
  min-width: 390px;
  line-height: 18px;
  position: absolute;
  bottom: 32px;
  left: 0;
}

.cart-pricer--item-icon:hover + .pricer-tooltip {
  display: block;
}

.cart-pricer__item--small {
  .cart-pricer__item-value, .cart-pricer__item-name {
    font-size: 14px;
  }
}
.cart-pricer__item-description {
  font-size: 14px;
  margin-top: .5rem;
  color: $grey50;
}
.cart-pricer__item--large {
  font-weight: bold;

  .cart-pricer__item-value, .cart-pricer__item-name {
    font-size: 20px;
  }
}

@include respond-below(md) {
  .action-cart {
    &__body {
      display: flex;
      flex-direction: column;
    }

    &__btn {
      display: block;
    }
  }

  .cart-pricer {
    &__fixed {
      position: fixed;
      bottom: 12px;
      right: 10px;
      left: 10px;
      z-index: 999;
    }

    &__button {
      border-radius: 16px;
      font-family: "Montserrat", sans-serif;
    }

    &__shrink-chat-widget {
      right: 86px;
    }
  }

  .cart-pricer--checkout {
    position: relative;
    bottom: 0;
    right: 0;

    > button {
      flex-direction: row;
      border-radius: 12px;
      padding: 14px;
    }
  }
}

@include respond-above(lg) {
  .action-cart {
    &__btn {
      display: none;
    }
  }
}
