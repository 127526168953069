
.header-login {
  background-color: #f5f5f5;
  height: 48px;
  padding: 0px 14px;
  color: #4f4f4f !important;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-style: normal;
  white-space: nowrap;
}

.header-login__icon {
  height: 20px;
  width: auto;
  margin-left: 6px;
}

.friends-account {
  background-color: #fff4d6 !important;
}

.header-login:hover {
  background-color: #F6FCF7;
}

.friends-account:hover {
  background-color: #fef6deea !important;
}
