@import "../mixin/color";

.quantity-control {
  border-radius: 3rem;
  //background-color: $background-green;
  background-color: $quantity-control-bg;
  overflow: hidden;
  align-items: stretch;
  //color: $text-color-white;
  color: $quantity-control-text;
  width: auto;
  font-family: Montserrat, "sans-serif";
  font-size: 1rem;
  flex-wrap: nowrap;

  &#{&}-lg {
    .btn {
      padding: 0.3rem 0.8rem;
    }
  }

  &--hsp {
    border-bottom-left-radius: 0;
  }

  .input-group-append button[disabled] .fa-plus {
    opacity: .5;
  }

  > .input-group-prepend, > .input-group-append {
    margin: 0;
  }

  &:hover {
    background-color: $quantity-control-bg--hover;
  }

  &, .btn {
    transition: opacity .15s ease-in-out, color .15s ease-in-out, background-color .15s ease-in-out;
  }

  .btn {
    border-radius: 0;
    background-color: rgba(0,0,0,0);
    //background-color: $quantity-control-bg;
    color: inherit;
    font-weight: bold;
    border: 0;
    padding: 0.2rem 0.55rem;

    &:hover {
      background-color: rgba(0,0,0,0.2);
      //background-color: $quantity-control-bg--hover;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
    }

    &.warning {
      background-color: $secondary40;
      color: $secondary80;
    }
  }

  .control-item {
    flex-grow: 1;
  }

  .measure-item {
    cursor: default;
  }

  &:hover input.form-control, .form-control:focus {
    border-bottom-width: 3px;
    border-top-width: 3px;
  }

  .form-control {
    text-align: right;
    border: 0 solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.15);
    padding: 0 .25rem 0 0;
    background-color: transparent;
    height: auto;
    color: inherit;
    font-weight: bold;
    width: 1.75rem;
    transition: border-width .15s ease-in-out, margin-bottom .15s ease-in-out;
    border-radius: 0;
  }

  input.form-control {
    width: 2.25rem;
  }

  .input-group-prepend, .input-group-append {
    .btn {
      background-color: transparent;
      color: inherit;

      &:hover {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }

  .input-group-append {
    span {
      border: 0;
      padding-left: .2rem;
      padding-right: .4rem;
      line-height: 1;
      color: inherit;
      background: transparent;
      width: 100%;
      font-weight: bold;
    }
  }
}

.quantity-control-updated {
  background-color: $secondary;
  &:hover {
    background-color: $secondary40;
  }
}


.quantity-control {
  &#{&}--green {
    background-color: $green;
    color: white;

    &:hover {
      background-color: $green60;

      .btn:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}

/** Product card */
.product-card.product-card--promotion {
  .quantity-control {
    background-color: $secondary60;
    color: $grey90;

    &:hover {
      background-color: $secondary70;
      color: $grey90;

      .btn:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }
}


.product.content, .product-card--active {
  .quantity-control {
    background-color: $green;
    color: white;

    &:hover {
      background-color: $green60;

      .btn:hover {
        background-color: rgba(0,0,0,0.1);
      }
    }
  }

  .product-item-cart-out {
    .quantity-control {
      background-color: $quantity-control-bg;
      color: $quantity-control-text;

      &:hover {
        background-color: $quantity-control-bg--hover;
      }
    }
  }
}
