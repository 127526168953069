@import "../mixin/color";
@import "../mixin/border";
@import "../mixin/respond";

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  margin: 0;

  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    -webkit-transition: .4s;
    transition: .4s;
  }

  &-slider:before {
    position: absolute;
    content: "";
    width: 23px;
    top: -2px;
    left: -1px;
    bottom: -2px;
    background-color: $background-white;
    transition: left .4s ease-in-out;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  }

  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:checked + &-slider {
    background-color: $background-green
  }

  input:focus + &-slider {
    //box-shadow: 0 0 1px #2196F3;
  }

  input:checked + &-slider:before {
    left: calc(100% - 22px);
  }

  /* Rounded sliders */
  &-slider.round {
    border-radius: 20px;
  }

  &-slider.round:before {
    border-radius: 50%;
  }
}


.button-swiper {

  position: static;

  .swiper-fade-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 80px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 100%);
    z-index: 3;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 45px;
    height: 45px;
    background-size: 98% 98%;
    background-repeat: no-repeat;
  }

  .swiper-button-prev {
    left: -45px;
    background-image: url("../assets/icons/left-arrow-active.svg") !important;

    &::after {
      content: '';
    }
  }

  .swiper-button-next {
    right: -45px;
    background-image: url("../assets/icons/right-arrow-active.svg") !important;

    &::after {
      content: '';
    }
  }
}


@media (max-width: 1035px) {
  .button-swiper {
    margin-left: -15px;
    margin-right: -15px;

    padding-left: 15px;
    padding-right: 15px;

    .swiper-fade-right {
      display: none;
    }
  }
}

.resize-observer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  pointer-events: none;
  display: block;
  overflow: hidden;
  opacity: 0;
}

.price--wrapper {
  .price--sign.price--prefix {
    margin-right: 0;
  }

  .price--sign.price--suffix::before {
    content: ' ';
    //margin-left: .25em;
  }
}

.rounded-image {
  border-radius: 0.8rem;
  width: 100%;
}

@keyframes preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}

div[is="resize-image"], img.preloader {
  position: relative;
}

div[is="resize-image"], img.preloader::after {
  content: '';
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid $green;
  border-radius: 50%;
  border-top-color: transparent;
}

.btn {
  &-default {
    color: #212529;
    background-color: #eee;
    border-color: #eee;

    &:hover {
      color: darken(#212529, 5);
      background-color: darken(#eee, 5);
      border-color: darken(#eee, 5);
    }
  }
  &-green {
    color: #FFFFFF;
    background: $background-green;
  }
  &-success {
    background-color: $background-green;
    border-color: $green;
    &:hover, &--hover:hover {
      background-color: $btn-hover-green;
      border-color: $btn-hover-green;
      color: #FFF;
    }
    &:active {
      background-color: $background-green70 !important;
      border-color: $green70 !important;
    }
  }

  &-success-solid {
    background-color: $background-green;
    border-color: $green;
    border-radius: $br-2;
    color: #FFF;
    &:hover, &--hover:hover {
      background-color: $btn-hover-green;
      border-color: $btn-hover-green;
      color: #FFF;
    }
    &:active {
      background-color: $background-green70 !important;
      border-color: $green70 !important;
    }

    &.btn-lg {
      padding: 0.5rem 3rem;
    }
  }


  &--green {
    color: $green;

    &:hover {
      color: $btn-hover-green;
    }
  }

  &--accent10 {
    background-color: $accent10;
  }

  &--accent30 {
    color: $green;
    background-color: $accent30;
    &:hover {
      color: $btn-hover-green;
      background-color: $accent20;
    }
  }

  &--pink {
    color: #ffffff;
    background-color: $pink;

    &:hover {
      color: #ffffff;
      background-color: lighten($pink, 7);
    }

    &:focus, &:active {
      color: #ffffff;
      background-color: darken($pink, 7);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($pink, .5);
    }
  }

  &--facebook {
    background-color: #3C5B96;
    color: #fff;

    &:hover {
      background-color: lighten(#3C5B96, 5);
      color: #fff;
    }

    &:active {
      background-color: darken(#3C5B96, 5);
    }
  }

  &-family, &-membership {
    font-family: "Montserrat", sans-serif;

    background-color: $secondary;
    color: $text-black;
    &:hover {
      background-color: $btn-hover-secondary;
      color: $text-black;
    }
  }

  &-family-outline, &-membership-outline {
    font-family: "Montserrat", sans-serif;

    color: $secondary70;
    background-color: transparent;
    background-image: none;
    border-color: $secondary;

    border-width: 2px;
    font-weight: bold;

    &:hover {
      color: $secondary80;
      border-color: $secondary70;
    }
  }

  &-solid {
    &-secondary {
      background-color: $grey20;
      color: $grey50;
      border-color: $grey30;

      &:hover {
        background-color: $grey10;
        border-color: $grey40;
      }
    }

    &-black {
      background-color: black;
      border-color: black;
      color: white;

      &:hover {
        background-color: rgb(29, 29, 29);
        border-color: black;
      }
    }

    &-dark {
      border-radius: 22px;
      background-color: $grey90;
    }
  }

  &-outline {
    &-default {
      color: $grey80;
      background-color: transparent;
      background-image: none;
      border-color: $grey30;

      &:hover {
        color: $grey90;
        background-color: $grey30;
        border-color: $grey40;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(#ced4da, .5);
      }
    }

    &-muted {
      color: $grey;
      background-color: transparent;
      background-image: none;
      border-color: #ced4da;

      &:hover {
        color: #fff;
        background-color: #ced4da;
        border-color: #ced4da;
      }

      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(#ced4da, .5);
      }
    }
  }
}

.text {
  &--green {
    color: $green;
  }
  &-danger {
    color: $red !important;
  }
  &-success {
    color: $green !important;
  }
  &--pink {
    color: $pink !important;
  }
  &--accent {
    color: $accent;
  }
  &--grey {
    color: $grey;
  }
  &--grey-20 {
    color: $grey20;
  }
  &--grey-80 {
    color: $grey80;
  }
  &--secondary-70 {
    color: $secondary70;
  }
}

/**
 * Генерация классов для border-radius, прим.:
 * .brr-1: border-{top|bottom}-right-radius: 10px;
 * .brt-md-2: @media(md) { border-top-{left|right}-radius: 16px };
 */
@for $i from 0 through length($brs) {
  $br: if($i > 0, nth(map-keys($brs), $i), "none") ;
  $size: map-get($brs, $br);
  $infix: if($br == "none", "", "-#{$br}");

  .br-#{$i} {
    border-radius: $size;
  }

  @each $radius-type, $radius-corners in $radius-types {
    .br#{$radius-type}-#{$i} {
      @each $radius-corner in $radius-corners {
        border-#{$radius-corner}-radius: $size !important;
      }
    }

    @each $breakpoint in map-keys($breakpoints) {
      @include respond-above($breakpoint) {
        .br#{$radius-type}-#{$breakpoint}-#{$i} {
          @each $radius-corner in $radius-corners {
            border-#{$radius-corner}-radius: $size !important;
          }
        }
      }
    }
  }
}

.panel-double {
  display: flex;
  flex-wrap: wrap;

  @include respond-below(lg) {
    display: block;
  }

  & > div {
    display: flex;
    align-items: center;

    & > div {
      flex-grow: 1;
    }

    &:first-child {
      border-radius: $br-1 0 0 $br-1;
      @include respond-below(lg) {
        border-radius: $br-1 $br-1 0 0;
      };
    }

    &:last-child {
      border-radius: 0 $br-1 $br-1 0;
      @include respond-below(lg) {
        border-radius: 0 0 $br-1 $br-1;
      };
    }
  }

  &--manifest {
    > div:first-child {
      background-color: $accent10;
    }
    > div:last-child {
      background-color: $grey10;
    }
  }

  &--invitation {
    > div {
      background-color: $secondary30;
    }

    .referral-placeholder {
      background-color: $secondary40;
      border: 1px dashed $secondary90;

      &:before, &:after {
        content: '★';
        color: $secondary90;
      }
    }
  }

  &--referral {
    > div {
      background-color: $accent10;

      & + div {
        background-color: $accent30;
      }
    }

    .referral-placeholder {
      background-color: #ffffff;
      border: 1px dashed $grey;
    }
  }

  &--marketing-pos {
    background-color: $accent10;
    overflow: hidden;
    border-radius: $br-3;

    img {
      width: 100%;
    }
  }

  &--week-farmer {
    background-color: $accent10;
    overflow: hidden;
    border-radius: $br-3;

    img {
      width: 100%;
    }

    > div {
      & + div {
        flex-grow: 0;
      }
    }
  }
}

.referral-placeholder {
  border-radius: $br-1;
  padding: 0.25rem .5rem;
  display: inline-block;
  position: relative;
  font-weight: bold;
  font-size: 21px;

  &:before, &:after {
    content: ' ';
    position: relative;
    font-size: 70%;
    vertical-align: middle;
  }

  &:before {
    margin-right: .15rem;
  }

  &:after {
    margin-left: .15rem;
  }
}

.wf-info {
  vertical-align: text-top;
}



.bg {
  &--secondary-30 {
    background-color: $secondary30;
  }

  &--secondary-50 {
    background-color: $secondary50;
  }

  &--secondary-60 {
    background-color: $secondary60;
  }

  &--secondary {
    background-color: $secondary;
  }

  &--accent10 {
    background-color: $accent10;
  }

  &--accent20 {
    background-color: $accent20;
  }

  &--accent30 {
    background-color: $accent30;
  }
}

.font-size-md {
  font-size: 14px;
}

.apple-icon {
  background: url(../assets/icons/apple-icon.svg) center no-repeat;
  float: left;
  width: 25px;
  height: 22px;
  background-size: contain;
  margin-right: 6px;
}

.google-icon {
  background: url(../assets/icons/google-icon.svg) center no-repeat;
  float: left;
  width: 22px;
  height: 22px;
  background-size: contain;
  margin-right: 6px;
}

.verified-icon {
  background: url(../assets/icons/verified.svg) center no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  vertical-align: top;
}

.check-icon {
  background: url(../assets/icons/check.svg) center no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  vertical-align: top;
}

.farmers-market-icon {
  background: url(../assets/icons/farmers-market-icon.svg) center no-repeat;
  width: 16px;
  height: 16px;
  background-size: contain;
  display: inline-block;
  vertical-align: top;
}

.icon-cart {
  background: url(../assets/icons/cart.svg) center no-repeat;
  width: 20px;
  height: 20px;
  background-size: contain;
  display: inline-block;
  vertical-align: top;
}

.icon-2x {
  transform: scale(2);
}

.inline-button {
  display: inline-block;
  position: relative;
  overflow: hidden;

  border-radius: 3px;
  box-shadow: 0 1px 2px 1px rgba(0,0,0,0.1), 0 1px 1px 0 rgba(0,0,0,0.2);

  padding: .442rem .5rem;

  cursor: pointer;

  transition: box-shadow ease .5s, background-color ease .3s;

  &#{&}-fg {
    box-shadow: none !important;
    background-color: $grey10;
    color: $grey80;
    border-radius: 8px;

    &.active {
      background-color: $green20;
      color: $green;
    }
  }

  &.active {
    background-color: $background-green;
    color: $text-color-white;
    box-shadow: 0 1px 0 0 rgba(0,50,0,0.15);
  }

  &.disabled {
    background-color: $background-grey30;
    color: $grey;
    cursor: default;
  }
}

.toggle-mobile {
	input {
    opacity: 0;
    position: absolute;
    & + label {
      position: relative;
      display: inline-block;
      user-select: none;
      transition: .4s ease;
      height: 28px;
      width: 48px;
      border: 1px solid #e4e4e4;
      border-radius: 60px;

      &:before {
        content: '';
        position: absolute;
        display: block;
        transition: .2s cubic-bezier(.24,0,.5,1);
        height: 28px;
        width: 49px;
        top: 0;
        left: 0;
        border-radius: 28px;
        background-color: #E6E6E6;
      }

      &:after {
        content: '';
        position: absolute;
        display: block;
        box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 0px 0 rgba(0,0,0,0.04), 0 4px 9px rgba(0,0,0,0.13), 0 3px 3px rgba(0,0,0,0.05);
        transition: .35s cubic-bezier(.54,1.60,.5,1);
        background: whitesmoke;
        height: 25px;
        width: 25px;
        top: 1px;
        left: 0px;
        border-radius: 60px;
      }
    }

    &:checked {
      & + label {
        &:before {
          background: #53C95A;
          transition: width .2s cubic-bezier(0,0,0,.1);
        }
        &:after {
          left: 24px;
        }
      }
    }
	}
}


.melon-loader {
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;

  &::before, &::after {
    content: '';
    position: absolute;
  }

  &::before {
    transition: .25s height ease-out;
    background-image: url("../assets/icons/melon-loader.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    width: 50px;
    height: 0;
    z-index: 1001;
  }

  &.dark::after {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::after {
    top: 50%;
    left: 50%;
    right: 50%;
    bottom: 50%;

    transition: .25s all ease;
    transition-delay: .25s;

    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1000;
    border-radius: inherit;
  }

  &.active {
    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::before {
      height: 40px;
    }
  }
}

.social-bullets {
  .fa-facebook-f, .fa-instagram {
    font-size: 120%;
  }

  a {
    color: $grey90;

    &:hover {
      color: black;
    }
  }
}

.hr-divider {
  border-width: 1px 0 0 0;
  border-color: $grey20;
  border-style: solid;
  margin-bottom: .5rem;
  margin-top: .5rem
}

.badge {
  &-corporate {
    background-color: $corporate-bg;
    color: #ffffff;
  }

  &-banner-price {
    position: absolute;
    right: .2rem;
    bottom: .5rem;
    border-radius: 1rem;
    background-color: #ffffff;
    padding: 0 .4rem;

    b {
      position: relative;
      font-size: 24px;
    }
    sup {
      position: relative;
    }
    del {
      position: relative;
      font-weight: bold;
      color: red;
    }
  }

  &-bonus-amount {
    border-radius: 3px;
    background: $background-green;
    color: $text-color-white;
  }

  &-family {
    border-radius: 2rem;
    color: $grey90;
    background-color: $secondary;
    display: inline-block;
    padding: 0 0.5em;
    font-size: 80%;
    font-weight: bold;
  }

  &-melonclub {
    border-radius: 2rem;
    color: $grey90;
    background-color: $secondary;
    display: inline-block;
    padding: 0 0.5em;
    font-style: italic;
  }

  @at-root label#{&} {
    > input[type="checkbox"] {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }
}

.popover-menu > div > .dropdown-menu {
  position: relative;
  display: block;
  border: 0;
  float: none;
  background-color: transparent;

  > .dropdown-item {
    width: auto;

    &:active, &:hover {
      background-color: $accent10;
    }
  }
}

.yelp-reviews {
  overflow-x: auto;
  gap: 10px;

  .review-image {
    height: 270px;
  }
}

@media (max-width: 768px) {
  .yelp-reviews {
    overflow-x: auto;
    .review-image {
      height: 230px;
    }
  }
}

/** StarsRating component START **/

$rating-star-color-default: $grey40;
$rating-star-color-active: $secondary;

.rating-stars {
  font-size: 180%;
  white-space: nowrap;

  &--sm {
    font-size: 100%;
  }

  &--md {
    font-size: 125%;
  }

  &--lg {
    font-size: 160%;
  }

  &--xl {
    font-size: 180%;
  }

  &:not(.disabled) {
    // all elements after hovered
    > span:hover ~ span {
      //color: red;
    }

    // all elements before hovered
    > span:has(~ span:hover) {
      color: $rating-star-color-active;
    }

    // hovered star
    > span:hover {
      color: $rating-star-color-active;
    }
  }

  .wiggle-once {
    > i {
      animation-name: wiggle-star;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }

  // all elements in the row
  > span {
    color: $rating-star-color-default;
    margin: 0 .1rem;
    display: inline-block;
  }

  // hovered star
  > span.active {
    color: $rating-star-color-active;
  }
}

@keyframes wiggle-star {
  0% {transform: skewX(9deg);}
  10% {transform: skewX(-8deg);}
  20% {transform: skewX(7deg);}
  30% {transform: skewX(-6deg);}
  40% {transform: skewX(5deg);}
  50% {transform: skewX(-4deg);}
  60% {transform: skewX(3deg);}
  70% {transform: skewX(-2deg);}
  80% {transform: skewX(1deg);}
  90% {transform: skewX(0deg);}
  100% {transform: skewX(0deg);}
}
/** StarsRating component END **/


.fa-input-append {
  position: relative;

  input ~ .fa {
    position: absolute;
    right: 1rem;
    top: 50%;
    margin-top: -.5rem;
  }
}
