.sf-head {
  &__images {
    position: relative;
  }

  &__img {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top;

    &-wrapper {
      height: 250px;
      width: 100vw;
      margin-left: -15px;
    }
  }

  &__logo {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;

    &-wrapper {
      width: 112px;
      height: 112px;
      position: absolute;
      bottom: -72px;
      left: 20px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
  }

  &__title {
    color: #333;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin: 0;
  }

  &__location {
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      width: 20px;
      height: 20px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      line-height: 100%;
      color: #828282;
      white-space: nowrap;
    }
  }

  &__description {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 0;
    margin-top: 16px;
  }

  &:has(.sf-head__img-wrapper) {
    .sf-head__content {
      margin-top: 84px;
    }
  }
}

.sf-best {
  margin-top: 16px;

  h2 {
    color: #000;
    font-family: 'Montserrat';
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 16px;
  }

  &__products {
    display: flex;
    flex-direction: column;
  }

  &__product {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid #F5F5F5;

    &:last-child {
      border: none;
    }

    &-img {
      min-width: 160px;
      min-height: 160px;
      max-width: 160px;
      max-height: 160px;
      position: relative;

      .labels {
        flex-direction: column;
        position: absolute;
        bottom: 0.5rem;
        left: 0.5rem;
        z-index: 510;
      }

      img {
        width: 160px;
        height: 160px;
        object-fit: cover;
        border-radius: 12px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
    }

    &-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4px;
      align-items: flex-start;
      width: 100%;
    }

    &-name {
      font-family: 'Inter';
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      color: #4F4F4F;

      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &-measure {
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: #BDBDBD;
    }

    &-brand {
      margin-bottom: 16px;
      font-family: 'Inter';
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #4F4F4F;
    }

    &-description {
      font-family: 'Inter';
      font-size: 13px;
      line-height: 18px;
      color: #828282;
      margin-bottom: 16px;

      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-price {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &--actual {
        font-family: 'Montserrat';
        color: #4F4F4F;
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
      }

      &--old {
        font-family: 'Montserrat';
        font-size: 9px;
        font-weight: 600;
        line-height: 11px;
        color: #4F4F4F;
        text-decoration: line-through;
      }
    }

    &-family {
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      color: #333;
      background-color: #FFD458;
      padding: 2px 4px;
      border-radius: 10px;
    }

    &-add {
      color: #F5F5F5;
      font-family: 'Montserrat';
      font-size: 15px;
      font-weight: 700;
      line-height: 100%;
      width: 33px;
      height: 33px;
      text-align: center;
      background-color: #53C95A;
      border-radius: 100%;
      padding: 6px 12px;
      border: none;
    }
  }
}

.sf-products {
  margin-top: 20px;

  h2 {
    color: #000;
    font-family: 'Montserrat';
    font-size: 21px;
    font-weight: 700;
    line-height: 26px;
    margin-bottom: 16px;
  }
}
