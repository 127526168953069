@import "../mixin/color";

.fade-leave, .fade-leave-active {
  position: absolute;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-leave {
  transition-delay: 1500ms;
}

.tab-content .tab-pane > div {
  transition: all 1s;
}

.nowrap {
  @media(max-width: 991px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.checkout {
  ul, ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  &-cart {
    margin-left: 16px;

    @media(max-width: 991px) {
      margin-left: 0;
    }

    &-content {
      padding: 1rem;
      border: 1px solid $grey30;
      border-radius: 0.5rem;
      -webkit-border-radius: 0.5rem;
      -moz-border-radius: 0.5rem;

      @media(max-width: 991px) {
        border-radius: 0;
        margin-left: 0;
        border: none;
      }

      & .cart-fields {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 500;
        color: $main-text-color;

        &-wrapper {
          border-bottom: 1px solid $grey30;
          padding-bottom: 15px;
        }

        &-total {
          font-size: 21px;
          font-weight: bold;
          line-height: 25px;
          padding-bottom: 8px;
          padding-top: 16px;
        }

        &-detail {
          font-size: 14px;
          height: 100% !important;
          margin-bottom: 0;
          color: $main-text-color;
        }
      }

      .cart-wrapper {
        padding: 0;
      }

      .total-sum {
        font-size: 21px;
        line-height: 25px;
      }

      .other-sum {
        font-size: 14px;
      }

      .dashed {
        border-top-style: dashed;
        border-width: 2px;
      }

    }

    .fo-banner {
      img {
        max-width: 100%;
      }
    }
  }

  &-form {
    label {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .delivery-type-express {
      background: $orange;
      border-radius: 3px;
      &.disabled {
        background: $background-grey30;
        [type="radio"] + label:before {
          box-shadow: 0 0 0 0.1em $orange;
        }
      }
    }

    .form-check-input + label {
      margin-left: 1.3rem;
      line-height: 1;

      &::before {
        margin-left: -1.3rem;
        margin-right: .4rem;
      }
    }
  }

  .lead {
    margin-bottom: 0;
    font-weight: bold;
    color: $main-text-color;
  }

  .form-group {
    color: $main-text-color;
  }

  .col-form-label {
  }

  &-order {
    .delivery-dates, .delivery-times {
      .swiper-container {
        border-radius: .5rem;
        overflow: hidden;

        .swiper-button-disabled {
          display: none !important;
        }
      }
      .swiper-slide {
        width: auto;
      }
    }

    .swiper-side-shadow {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 2rem;
      border-radius: .5rem;
      overflow: hidden;

      &.left {
        left: 0;
        box-shadow: inset 1rem 0 6px -12px rgba(0,0,0,0.1);
      }
      &.right {
        right: 0;
        box-shadow: inset -1rem 0 6px -12px rgba(0,0,0,0.1);
      }
    }

    .inline-button {
      color: $main-text-color;

      &.delivery-date {
        padding: .1rem .5rem;
      }

      &.delivery-time {
        padding: .442rem .5rem;
      }

      input {
        position: absolute;
        left: -100px;
      }
    }
  }

  .extra-charge {
    padding-top: 30px;
    font-size: 11px;
    line-height: 18px;
    color: $grey;
    margin: 0;
  }

  .btn-link, .btn-link:hover {
    text-decoration: underline !important;
  }
}

.media {
  &-image {
    width: 70px;
    height: 70px;
    border-radius: 6px;

    img {
      margin: 0 auto;
    }

    @media(max-width: 380px) {
      width: 35px;
      height: 35px;
      img {
        max-width: 100%;
      }
    }
  }

  &-body {
    p {
      @media(max-width: 380px) {
        font-size: 80%;
      }
    }
  }
}

input[type=checkbox] {
  accent-color: $green70;
}

.print-invoice {
  display: inline-block;
  position: relative;
}
.print-invoice .question {
  width: 25px;
  height: 25px;
  background: $background-grey40;
  border-radius: 25px;
  display: inline-block;
  text-align: center;
  color: $text-color-white;
}

.print-invoice .question:hover {
  cursor: pointer;
}

.print-invoice .question:hover + .info {
  display: block;
}

.print-invoice .info {
  background: $background-green20;
  border-radius: 10px;
  width: 400px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 12px 20px;
  position: absolute;
  display: none;
  top: -264px;
  left: -178px;
  z-index: 1;
}

.print-invoice .info:after {
  width: 20px;
  height: 20px;
  background: $background-green20;
  position: absolute;
  left: 180px;
  bottom: -10px;
  content: '';
  transform: rotate(45deg);
  z-index: -2;
}

.border-link {
  border-bottom: 1px dashed $green;

  &:hover {
    border-bottom-color: $btn-hover-green;
  }
}

.swiper-holder {
  position: relative;
}


.tips-section {
  margin-top: 25px;
  margin-bottom: 35px;

  &__input {
    margin: 15px 0;
  }
}

.tips-toolbar {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;

  &__item {
    margin-right: 10px;

    input[type="radio"] + label::before{
      display: none;
    }

    label {
      display: inline-block;
      background-color: $background-white;
      padding: 5px 14px;
      font-size: 16px;
      border: 1px solid $grey30;
      border-radius: 4px;
      cursor: pointer;
      margin-bottom: 10px;
    }

    input[type="radio"]:checked + label {
      color: $secondary80;
      background-color: $secondary30;
      border-color: $secondary40;
    }
  }
}

/*
.order-checkout-title {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  color: $main-text-color;
}
*/

.addresses {
  &__title {
    font-size: 1rem;
  }

  &__saved {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    h5 {
      font-size: 1rem;
    }
  }

  &__btn {
    background-color: transparent;
    border: none;
    color: $green;
    padding: 2px 8px;
    text-align: center;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
    margin-left: -8px;
  }
}

#addAddress .close {
  color: $red;
}

.address-modal {
  h4 {
    font-family: "Montserrat", sans-serif;
  }
}

.add-addresses {
  &__btn {
    background-color: transparent;
    border: none;
    color: $green;
    padding: 8px 8px;
    text-align: center;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
  }
}


.checkout-alert {

  &__product{
    display: flex;
    margin-bottom: 1.2rem;
  }

  &__price{
    color: $orange;
    font-size: 1rem;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    font-weight: 600;
    margin-top: 0.5rem;
    margin-bottom: 1.4rem;
    text-align: center;
  }

  p {
    margin: 0;
  }

  &__content {
    text-align: left;
    margin-left: 12px;
    display: flex;
    flex-direction: column;

  }

  &__btn {
    border-radius: 13px;
    padding: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    font-size: 17px;
  }
}








.radio-options {
  margin: 0;
  position: relative;

  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
  }

  h4, .h4 {
    font-weight: bold;
    color: $green90;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    margin: 0;
  }

  &__content {
    background-color: $grey20;
    border-radius: 12px;
    padding: 9px 14px;
    max-width: 290px;
    transition-duration: 0.2s;
    cursor: pointer;
    display: inline-block;

    font-size: 14px;

    &-sub {
      font-size: 75%;
      color: $grey50;
    }
  }

  &__info {
    margin-top: 4px;
    margin-bottom: 0;
    font-size: 14px;
    color: $grey;
  }

  &__item {
  }

  &__wrapper {
    position: relative;
  }

  input[type='radio']:checked:enabled + &__content {
    background-color: $green50;
    color: white;
  }

  input[type='radio']:disabled + &__content {
    background-color: $grey10;
    color: $grey40;
    cursor: not-allowed;
  }
}

.radio-options {
  &-panel &__content {
    padding: 16px;
    background-color: white;
    border: 1px solid $grey30;
  }

  &-panel input[type='radio']:checked:enabled + &__content {
    background-color: $green20;
    border-color: $green50;
  }

  &-panel input[type='radio']:checked:disabled + &__content {
    background-color: $green10;
    border-color: $green30;
  }
  &-panel input[type='radio']:disabled + &__content {
    background-color: $grey10;
    border-color: $grey30;
  }
}

.radio-options {
  &-yellow input[type='radio']:checked:enabled + &__content {
    background-color: $secondary40;
    color: inherit;
  }
  &-yellow input[type='radio']:checked:disabled + &__content {
    background-color: $secondary30;
    color: inherit;
  }
}

@include media-breakpoint-down(md) {
  .radio-options {
    &-md-nowrap &__wrapper {
      overflow-x: auto;
      margin-left: -15px;
      margin-right: -15px;
    }

    &-md-nowrap &__list {
      flex-wrap: nowrap;
      padding-left: 15px;
      padding-right: 15px;
    }

    &-md-nowrap &__item {
      flex: 0 0 auto;
    }
  }

  .radio-options {
    &-md-generic &__list {
      display: block;
    }

    &-md-generic &__item {

    }

    &-md-generic &__content::before {
      content: '';
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 1em;
      border: 2px solid #FFFFFF;
      box-shadow: 0 0 0 .1em $radio-default;
      transition: 0.25s ease all;
      margin-top: 0.05em;
      margin-right: 0.5em;
      position: absolute;
      left: 0;
    }

    &-md-generic input[type='radio']:checked + &__content::before {
      background-color: $radio-checked;
      box-shadow: 0 0 0 .1em $radio-checked;
    }

    &-md-generic &__content {
      background-color: inherit !important;
      border: none;

      max-width: none;
      position: relative;

      padding-top: 0.6em;
      padding-bottom: 0;
      padding-left: 1.8em;
    }
  }
}

.payment-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  flex-direction: column;
}

.payment-option {
  display: inline-flex;
  width: 290px;

  &__text {
    font-weight: 400;
    color: $green90;
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  &__item {
    width: 100%;
  }

  &__content {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid $grey30;
    border-radius: 12px;
    padding: 12px 16px 12px 20px;
    transition-duration: 0.2s;
    cursor: pointer;
  }

  &__icon {
    margin-right: 8px;
  }

  label {
    width: 100%;
  }
}

@include media-breakpoint-down(md) {
  .payment-options {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .payment-option {
    display: block;
    width: 100%;
  }
}

.radio-options--payment-types {
  .radio-options__item > div {
    margin-top: 1em;
  }
}

.address-options {
  &__item {
    margin: 8px 0;
  }

  &__input {
    margin-right: 10px;;
  }

  &__label {
    cursor: pointer;
  }

  label:before {
    width: 14px !important;
    height: 14px !important;
    margin: 0 !important;;
    margin-left: 2px !important;
    margin-bottom: -1px !important;
  }

  &__ctrl {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
    padding: 0;
    margin-left: 4px;
    padding-right: 8px;

    a {
      color: $grey30;

      &:hover {
        color: $grey50;
      }
    }
  }

  &__text {
    color: $green90;
    font-size: 14px;
    margin-left: 8px;
  }

  &__apt-inputs {
    display: flex;
    gap: 28px;
  }

  &__apt-input {
    flex: 1;
  }

  &__new {
    margin-top: 12px !important;
  }
}

@include media-breakpoint-down(lg) {
  .address-options {

    &__item {
      display: flex;
      flex-wrap: nowrap;
    }

    &__label {
      white-space: nowrap; /* Текст не переносится */
      overflow: hidden; /* Обрезаем всё за пределами блока */
      text-overflow: ellipsis;
    }

    &__apt-inputs {
      display: flex;
      gap: 16px;
    }
  }
}

.checkout-order {
  &__grid {
    display: flex;
    flex-direction: row;
    gap: 40px;
  }

  &__fieldset {
    flex: 2;
  }

  &__explication {
    flex: 1;
    padding: 15px 20px;
    position: sticky;
    top: 0;
    align-self: flex-start;
  }

  &__stepper {
    margin-bottom: 20px;
  }
}

@include media-breakpoint-down(md) {
  .checkout-order {
    &__grid {
      flex-direction: column;
    }

    &__explication {
      align-self: stretch;
      padding: 0;
    }

    &__stepper {
      display: none;
    }
  }
}

input[type='radio']:checked + .tip-options__content {
  background-color: $secondary40;
}

.checkout .streetline-map-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    color: $green;
    border: none;
    display: inline-block;
    padding: 0;
    padding-left: 10px;
    text-align: center;
    outline: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 12px;
  }
}

.checkout {
  section {
    margin-bottom: 24px;
    width: 100%;
  }

  *::-webkit-scrollbar {
    display: none;
  }

  *::-webkit-scrollbar-thumb {
    display: none;
  }

  .btn {
    border-radius: 12px;
    padding: 10px 16px;
  }
}

@include media-breakpoint-down(lg) {
  .checkout {
    h2, .h2 {
      font-size: 22px;
      margin-bottom: 12px;
    }

    h3, .h3 {
      font-size: 18px;
      margin-bottom: 8px;
    }

    h4, .h4 {
      font-size: 14px;
      margin-bottom: 6px;
    }

    section {
      margin-bottom: 24px;
    }
  }
}


.checkout-bonus {
  background: $background-grey20;
  border-radius: 12px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &-toggler {
    .switch {
      position: relative;
      display: inline-block;
      width: 58px;
      height: 30px;
    }

    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $background-grey30;
      border: 1px solid $grey30;
      -webkit-transition: .3s;
      transition: .3s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      left: 0;
      bottom: 0;
      background-color: $background-white;
      -webkit-transition: .3s;
      transition: .3s;
    }

    input:checked + .slider {
      background-color: $background-green;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(28px);
      -ms-transform: translateX(28px);
      transform: translateX(28px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 25px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }

  &__info {
    margin-left: 8px;

    header {
      font-size: 16px;
      font-family: "Montserrat", sans-serif;
    }
  }

  &__price {
    display: flex;
    flex-direction: row;
    gap: 4px;
    color: $grey;
    font-size: 14px;
  }

  &__warn {
    margin-top: 5px;
    font-size: 14px;
    color: $grey;
    padding-left: 4px;
    margin-bottom: 0;
  }

  .bonus-value {
    color: $grey;
  }
}


.validate-message {
  font-size: 80%;

  &.text-danger {
  }
}

.card-list__item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #F5F5F5;
}

.radio-options__item-icon {
  margin-right: 14px;
}

.expires-text {
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  color: #828282;
}

.pan-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
}

.apple-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #333333;
}

.radio-options__add-card {
  width: 100%;
  border: none;
  background: none;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
}

.radio-options__add-card-icon {
  margin-right: 10px;
}
