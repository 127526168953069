
.filter-v3__title {
  align-items: baseline;
  font-size: 16px;
  line-height: 20px;
  padding-top: 4px;
  font-family: "Inter", sans-serif;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.filter-v3__title-price {
  padding-bottom: 4px;
}

.filter-v3__range {
  padding: 0 14px 0 16px;
  margin-bottom: 16px;
}
