@import "../mixin/color.scss";
@import '../partial/geobox/atlas.scss';

.modal-body {
  .geobox {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .address-map {
      height: auto;
    }

    &-map-none {
      flex-grow: 0;
    }
  }
}

.geobox {
  .address-map {
    width: 100%;
    position: relative;
    flex-grow: 1;
    height: 40vh;
    z-index: 90;

    border-radius: 5px;
  }

  &-map-none {

  }

  .autocomplete-input-button {
    transition: all .5s ease-in-out;
    overflow: hidden;

    &.enabled {

    }
    &.disabled {
      width: 0;
      padding: 0;
      margin: 0;
      border: 0;
    }
  }
}

.address-modal {
  height: 100%;
}

.pin {
  width: 36px;
  height: 36px;
  background-color: $green;
  border-radius: 60px 60px 0px 60px;
  position: relative;
  transform: rotate(45deg);
  -webkit-mask: radial-gradient(circle 10px, #0000 92%, $green);
  mask: radial-gradient(circle 10px, #0000 92%, $green);
}
