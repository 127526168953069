@import "../mixin/color";
@import "../mixin/border";

.login-modal {
  .nav-link {
    @media (max-width: 368px) {
      font-size: 80%;
      padding: 0.5rem;
    }
  }
}


.get-code {
  &-text {
    margin-top: 12px;
    color: $main-text-color;
    &-title {
      font-size: 21px;
      line-height: 25px;
      text-align: center;
      font-weight: 700;
      margin-bottom: 12px;
    }
    &-text {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.25px;
    }
  }
  &-input {
    label {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: $grey40;
    }
    input {
      border-width: 0;
      border-bottom-width: 1px;
      border-radius: 0;
      height: 24px;
      padding-left: 0;
    }
  }
  &-submit {
    margin-top: 15px;
    //button {
    //  background: #19AB4B;
    //  height: 48px;
    //}
    //a {
    //  color: #19AB4B;
    //}
  }
}

.send-code {
  &-header {
    font-size: 21px;
    line-height: 25px;
    text-align: center;
    color: $main-text-color;
    font-weight: 700;
    margin-top: .5rem;
    margin-bottom: 2rem;
    display: flex;
    p {
      flex-grow: 1;
      margin-bottom: 0;
      padding-right: 2rem;
    }
  }
  &-input {
    width: 10rem;
    margin-bottom: 27px;
    input {
      font-size: 125%;
      font-weight: 700;
      border-color: $grey30;
      text-align: center;
      letter-spacing: .5rem;
    }
  }
  &-text {
    line-height: 1.25;
    text-align: center;
    letter-spacing: 0.25px;
    color: $main-text-color;
    &-get {
      margin-top: 5px;
    }
  }
}

.btn--green {
  color: $green;

  &:hover {
    color: $btn-hover-green;
  }
}

.modal--login {
  .form-control {
    border-color: $grey30;
    border-radius: $br-2;
    height: calc(3.25rem + 2px);
  }

  .btn {
    width: 100%;
    border-radius: $br-2;
    padding: 0.885rem 0.75rem;
  }

  .btn-solid-secondary {
    border-color: $grey30;
  }
}

.header-login__popover {
  border: 0;

  .popover-menu {
    padding: 0;
  }
}
