
.popup-small-batch.popup-screen-bottom {
  left: 0;
  width: 100%;
}

.popup-small-batch {
  z-index: 1000;
  padding-top: 16px;
}

.popup-small-batch__close {
  position: absolute;
  right: 16px;
  top: 0;
  z-index: 1010;
}

.popup-small-batch__wrapper {
  position: relative;
}

.popup-small-batch__content {
  padding: 0 16px;
  position: relative;
  z-index: 1000;
}

.popup-small-batch__content > img {
  width: 44px;
  height: 44px;
  margin: 0 auto;
  display: block;
}

.popup-small-batch__content > h4 {
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: #333;
  text-align: center;
  margin-bottom: 12px;
}

.popup-small-batch__content > p {
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  color: #828282;
  margin-bottom: 12px;
}

.popup-small-batch__content > button {
  background-color: #53C95A;
  border: none;
  color: #fff;
  border-radius: 16px;
  padding: 12px 48px;
  margin: 0 auto 20px auto;
  display: block;
}
