
$shadow-layers: (
        1: '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)',
        2: '0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12)',
        3: '0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2)',
        4: '0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2)',
        5: '0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2)',
        6: '0 16px 24px 2px rgba(0, 0, 0, 0.14), 0  6px 30px 5px rgba(0, 0, 0, 0.12), 0  8px 10px -5px rgba(0, 0, 0, 0.2)',
        7: '0  9px 46px  8px rgba(0, 0, 0, 0.14), 0 11px 15px -7px rgba(0, 0, 0, 0.12), 0 24px 38px  3px rgba(0, 0, 0, 0.2)'

//1: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)',
//2: '0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23)',
//3: '0 10px 20px rgba(0,0,0,.19), 0 6px 6px rgba(0,0,0,.23)',
//4: '0 14px 28px rgba(0,0,0,.25), 0 10px 10px rgba(0,0,0,.22)',
//5: '0 19px 38px rgba(0,0,0,.3), 0 15px 12px rgba(0,0,0,.22)'
) !default;

$shadow-layers-success: (
        1: '0 2px 2px 0 rgba(0, 60, 0, 0.14), 0 3px 1px -2px rgba(0, 60, 0, 0.2), 0 1px 5px 0 rgba(0, 60, 0, 0.12)',
        2: '0 3px 4px 0 rgba(0, 60, 0, 0.14), 0 3px 3px -2px rgba(0, 60, 0, 0.2), 0 1px 8px 0 rgba(0, 60, 0, 0.12)',
        3: '0 4px 5px 0 rgba(0, 60, 0, 0.14), 0 1px 10px 0 rgba(0, 60, 0, 0.12), 0 2px 4px -1px rgba(0, 60, 0, 0.2)',
        4: '0 6px 10px 0 rgba(0, 60, 0, 0.14), 0 1px 18px 0 rgba(0, 60, 0, 0.12), 0 3px 5px -1px rgba(0, 60, 0, 0.2)',
        5: '0 8px 10px 1px rgba(0, 60, 0, 0.14), 0 3px 14px 2px rgba(0, 60, 0, 0.12), 0 5px 5px -3px rgba(0, 60, 0, 0.2)',
        6: '0 16px 24px 2px rgba(0, 60, 0, 0.14), 0  6px 30px 5px rgba(0, 60, 0, 0.12), 0  8px 10px -5px rgba(0, 60, 0, 0.2)',
        7: '0  9px 46px  8px rgba(0, 60, 0, 0.14), 0 11px 15px -7px rgba(0, 60, 0, 0.12), 0 24px 38px  3px rgba(0, 60, 0, 0.2)'
        //1: '0 1px 3px rgba(0,60,0,.12), 0 1px 2px rgba(0,60,0,.24)',
        //2: '0 3px 6px rgba(0,60,0,.16), 0 3px 6px rgba(0,60,0,.23)',
        //3: '0 10px 20px rgba(0,60,0,.19), 0 6px 6px rgba(0,60,0,.23)',
        //4: '0 14px 28px rgba(0,60,0,.25), 0 10px 10px rgba(0,60,0,.22)',
        //5: '0 19px 38px rgba(0,60,0,.3), 0 15px 12px rgba(0,60,0,.22)'
) !default;

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

@mixin shadow-transition($duration: .3s, $animation: ease) {
  transition: box-shadow $duration $animation;
}

@mixin shadow-layer($key) {
  @include box-shadow(unquote(map-get($shadow-layers, $key)));
}

@mixin shadow-layer-success($key) {
  @include box-shadow(unquote(map-get($shadow-layers-success, $key)));
}

@each $shadow-layer in map-keys($shadow-layers) {
  $shadow-layer-value: map-get($shadow-layers, $shadow-layer);

  .z#{$shadow-layer} {
    @include box-shadow(unquote($shadow-layer-value));
  }
}
