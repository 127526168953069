@import "../mixin/color";

.promotion-checkout {
  padding-left: 0;
  padding-right: 0;

  .checkout {
    min-height: 540px;
  }

  &__bg {

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;

    background-color: $grey20;
    box-shadow: 0 0 50px 50px $grey20;

    &-l {
      background-image: url('../assets/landing/promotional-bg__localpick_left.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center left;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 50%;
      z-index: 10;

      mix-blend-mode: darken;
    }

    &-r {
      background-image: url('../assets/landing/promotional-bg__localpick_right.jpg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 60%;
      z-index: 10;

      mix-blend-mode: darken;
    }

    &-c {
      position: absolute;
      background-image: url('../assets/landing/pm-logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      width: 5rem;
      margin: 0 auto;
      top: 3rem;
      aspect-ratio: 1;
      z-index: 30;
      left: 0;
      right: 0;
    }
  }


  &__flow {
    position: relative;
    z-index: 50;
  }

  .checkout-order__fieldset {
    position: relative;
    margin: 0 -15px;
    padding: 0 15px;
  }

  &__sections {
    min-height: 50vh;

    > section {
      padding-top: 10rem;

      > .title {
        text-align: center;
      }
    }
  }

  .radio-options {
    &__content {
      background-color: $background-white;
      font-weight: 500;
    }
  }
}


@media (max-width: 768px) {
  .promotion-checkout {
    &__bg-l {
      left: -15%;
    }

    &__bg-r {
      right: -20%;
    }
  }
}


/* Layout "heading" */
.promotion-checkout {
  &__header {
    display: none;
  }

  &--layout-heading &__bg {
    > div {
      display: none;
    }
  }

  &--layout-heading &__sections {
    > section {
      padding-top: 2rem;

      @media (max-width: 780px) {
        padding-top: 0;
      }
    }
  }

  &--layout-heading &__header {
    background-image: url('../assets/landing/promotional-header-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    display: block;

    text-align: center;
    color: #FFF;

    @media (max-width: 780px) {
      position: relative;
      top: -2rem;
    }

    &-logo {
      //position: absolute;
      position: relative;
      background-image: url('../assets/landing/pm-logo.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center;
      width: 5rem;
      margin: 0 auto;
      padding-top: 10rem;
      //top: 3rem;
      aspect-ratio: 1;
      z-index: 30;
      left: 0;
      right: 0;
    }

    &-title {
      font-weight: bold;
      text-transform: uppercase;
    }

    &-text {
      margin-top: 2rem;
      padding: 0 2rem 2rem 2rem;
    }
  }
}
