@import '../mixin/color';
@import '../mixin/respond';

.container > .products > .row.flex-nowrap {
  overflow: auto;

  &.product-swiper {
    overflow: hidden;
  }
}

.product {
  &-item {
    .title {
      em {
        background-color: #fff7b3;
      }
    }
  }

  .properties {
    margin-bottom: 1rem;

    &--manufacturer, &--brand {
      color: $main-text-color;
      .value {
        color: $green;
      }
    }
  }

  .information {
    color: $main-text-color;
    max-width: 100%;
    overflow: auto;
  }
}

.swiper-slide, div[is="swiper-slide"] {
  height: auto;
  display: block;
}

.product-swiper, div[is="product-swiper"] {
  padding-bottom: 1.5rem;
  position: relative;
  overflow: hidden;
  z-index: 1;

  .product-card {
    width: 100%;
  }

  &.swiper-container {
    &-horizontal > .swiper-scrollbar {
      left: 0;
      width: 100%;
    }

    .swiper-scrollbar {
      background: transparent;
      bottom: 0;
      height: 5px;
    }

    .swiper-pagination {
      bottom: 0;

      &-bullet {
        margin: 0 1rem;

        &-active {
          background: $contrast;
        }
      }
    }

    .swiper-scrollbar-drag {
      background-color: $background-grey20;
    }

    .swiper-button-prev, .swiper-button-next {
      width: 60px;
      height: 60px;
      background-size: contain;
      @media (max-width: 1285px) {
        width: 40px;
        height: 40px;
      }
      @media (max-width: 1035px) {
        width: 30px;
        height: 30px;
      }
    }

    .swiper-button-prev {
      left: -65px;
      background-image: url("../assets/icons/left-arrow-active.svg") !important;
      @media (max-width: 1285px) {
        left: -35px;
      }
      @media (max-width: 1035px) {
        left: -20px;
      }

      &::after {
        content: '';
      }
    }

    .swiper-button-next {
      right: -65px;
      background-image: url("../assets/icons/right-arrow-active.svg") !important;
      @media (max-width: 1285px) {
        right: -35px;
      }
      @media (max-width: 1035px) {
        right: -20px;
      }

      &::after {
        content: '';
      }
    }
  }

  .top-products-link {
    background: $background-green;
    justify-content: center;
    align-items: center;
    border-radius: 3px;

    &:hover {
      background: $background-green70;
      text-decoration: underline !important;
    }
  }

  .product-item {
    margin-top: 0;
    margin-bottom: 0;
    width: 100%;

    .image a {
      border-radius: 0;
    }
  }
}

.product-item {
  margin-bottom: .5rem;
  margin-top: .5rem;

  > .d-flex {
    justify-content: space-between;
  }

  &.list-product {
    @include respond-below(md) {
      margin-bottom: 0;
      margin-top: 0;

      padding-left: 0;
      padding-right: 0;

      .image {
        align-self: center;

        height: 96px;
        line-height: 96px;
        width: 33%;
        flex-shrink: 0;
        display: inline-block;
        padding-top: 0;

        a {
          position: relative;
          display: inline-block;
        }
      }
      .product-item-box {
        min-height: 140px;
        border: 0;
        border-bottom: 1px solid $grey20;
        border-radius: 0;
        box-shadow: none;
      }

      .product-item-body {
        flex-direction: row;
      }

      &.first {
        border-top: 1px solid $grey20;
      }
    }
  }

  & &-labels {
    position: absolute;
    left: 8px;
    top: 10px;
    z-index: 510;

    &-new, &-action, &-local, &-gift {
      border-radius: 5px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.006px;
      color: $text-color-white;
      padding: 3px 4px;
      display: block;
      margin-bottom: 8px;
    }

    &-new {
      background: $green-secondary;
    }

    &-local {
      background: $secondary70;
    }

    &-action {
      background: $red;
      max-width: 40px;
    }

    &-gift {
      background: #F57D40;
    }

    .gift-icon {
      width: 13px;
      margin: 0 3px 2px 0;
    }

    .gift-icon img {
      display: flex;
    }
  }

  & &-stickers {
    position: absolute;
    left: 8px;
    top: 10px;
    z-index: 510;

    &-farm {
      border-radius: 5px;
      font-size: 12px;
      line-height: 14px;
      text-align: center;
      letter-spacing: 0.006px;
      color: $text-color-white;
      padding: 3px 4px;
      display: block;
      margin-bottom: 8px;
    }
  }

  &-box.full-width-below-md &-labels {
    left: 26px;
    @include respond-below(md) {
      top: 4px;
      left: 16px;
    }
  }

  /*
  .cart-btn span {
    display: inline-block;
  }

  &.width-0 {
    .cart-btn span {
      display: none;
    }
  }
  */

  &-box {
    height: 100%;

    display: flex;
    flex-direction: column;
    overflow: hidden;

    border: 0;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;

    box-shadow: 0 0 2px rgba(8, 35, 48, 0.12);
    transition: box-shadow ease 200ms;

    &:hover {
      box-shadow: 0 4px 12px rgba(8, 35, 48, 0.12);
      border-color: $grey20;

      .title a {
        color: $green;
      }
    }
  }

  &-body {
    flex-grow: 1;
    position: relative;

    display: flex;
    flex-direction: column;
  }

  &-content {
    display: flex;
    flex-direction: column;

    flex-grow: 1;

    padding-left: 15px;
    padding-right: 15px;

    @include respond-below(md) {
      justify-content: space-evenly;
    }
  }

  &-text {
    flex-grow: 1;
  }

  &-price {
    display: flex;
    flex-direction: column;

    .price {
      display: flex;
      flex-direction: row;
    }
  }

  .seller-label {
    color: $grey;
    font-size: 14px;
    margin-bottom: 0.7em;
  }

  &-footer {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .image {
    text-align: center;

    width: 100%;
    padding-top: 100%;
    position: relative;

    a {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 500;

      display: flex;
      align-items: center;
      justify-content: center;

      height: 100%;
      overflow: hidden;

      @include respond-below(md) {
        border-radius: 5px;
      }
    }

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .title {
    a {
      font-size: 14px;
      color: $contrast;
      display: block;
    }

    @include respond-below(md) {
      a {
        font-size: 14px;
      }
    }
  }

  .price {
    font-size: 16px;
    @include respond-below(md) {
      font-size: 14px;
      color: $contrast;
      padding-top: 6px;
    }

    &-discounted {
      color: $red;

      s {
        color: $contrast;
      }

      b {
        margin-left: .25rem;
      }
    }
  }

  .price-new {
    font-size: 20px;
    line-height: 1.2;
  }

  .controls-container {
    display: flex;
    margin-top: .5rem;

    @include respond-between(md, lg) {
      justify-content: space-between;
    }
  }

  &.product-is-unavailable {
    .controls-container {
      font-size: 14px;
      line-height: 34px;
    }
  }

  .control-buttons {
    width: 160px;
    display: inline-block;
    vertical-align: middle;

    button {
      width: 2.5rem;
    }
  }

  .in-cart {
    &.product-item-box {
      @include respond-above(md) {
        border-color: $grey20;
        box-shadow: 0 2px 4px rgba(8, 60, 24, 0.13);

        &:hover {
          border-color: $grey20;
          box-shadow: 0 4px 12px rgba(8, 60, 24, 0.15);
        }
      }
    }
  }
}

.cart-btn {
  border-radius: 2rem;
  padding: 0.2rem 0.55rem;
  border: 0;

  &-single {
    padding: 0.2rem 0.75rem;
  }

  &.btn-success {
    background-color: $background-green;
    border-color: $green;

    &:hover {
      background-color: $btn-hover-green;
    }
  }

  &-success {
    background-color: $green;
    border-color: $green;
    color: $text-color-white;

    &:hover {
      background-color: $btn-hover-green;
    }
  }

  &-danger {
    background-color: $red;
    border-color: $red;
    color: $text-color-white;

    &:hover {
      background-color: $red-hover;
    }
  }

  &-danger-light {
    background-color: $grey20;
    border: 1px solid $pink;
    color: lighten($pink, 10);

    &:hover {
      background-color: $background-white;
    }
  }
}

/**** Cart Control Extra styles */
/** Cart row */
.cart-wrapper {
  .product-item {
    .cart-btn-danger {
      background-color: $red;
      color: $text-color-white;

      &:hover {
        background-color: $red-hover;
      }

      .ico-cart-arrow-up {
        background-image: url("../assets/social/cart-btn-icon-remove-item.svg?stroke=%23" + str-slice('#{$red}', 2, 7));
      }
    }
  }
}
/**** Cart Control Extra styles end */

.product {
  h1 {
    @include respond-below(md) {
      font-size: 1.75rem;
    }
  }

  h1.product-h1 {
    color: $main-text-color;
    @include respond-below(md) {
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.44px;
      color: $main-text-color;
    }
  }

  .price-single {
    font-size: 1.5rem;
  }

  &-delivery-info {
    background: $secondary30;

    p {
      line-height: 1.25rem;
    }
  }

  &-bonus {

    &-icon {
      vertical-align: baseline;
      margin-bottom: -1px;
    }

    &_default {
      background-color: $orange;
    }

    &_high {
      background-color: $secondary70;
    }

    &-value {
      font-size: 32px;
      line-height: 38px;
    }

    &-text {
      font-size: 12px;
      line-height: 14px;
      @include respond-below(md) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  .tag-btn {
    background: $background-grey10;
    border-radius: 4px;
    color: $grey;
    font-size: 0.8rem;
  }

  .notify {
    border: 1px solid $secondary70;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 0.8em;
    margin-top: 20px;
  }

  .seller-label {
    font-size: 14px;
    color: $grey;
    margin-top: 35px;
  }

  .image img {
    max-width: 100%;
  }
}

.products {
  position: relative;
}

.products-list {
  .product-item-text {
    @include respond-below(md) {
      flex-grow: 0;
    }
  }
}

.recommendations {
  h5 {
    @include respond-below(md) {
      font-size: 1.75rem;
    }
  }

  &.fw {
    @media(max-width: 360px) {
      max-width: unset;
      width: calc(100% + 30px);
      margin-left: -15px;
    }
  }
}

.product-label-bonus {
  cursor: pointer;

  .label-bonus {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.006px;

    padding: 2px 5px;
    border-radius: 3px;

    &-icon {
      vertical-align: baseline;
      margin-bottom: -1px;
    }

    &_default {
      font-size: 11px;
      color: $grey;
      background-color: $background-grey10;
    }

    &_high {
      color: $text-color-white;
      background-color: $secondary70;
    }
  }
}

.tag-popover {
  border-color: $grey30 !important;
  color: $grey10 !important;
  max-width: 140px !important;
  font-weight: 600 !important;
  background: $background-grey !important;
  top: -2px !important;

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $grey20;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      margin-top: 0;
      margin-bottom: 0;
    }

    .tooltip-arrow {
      background: $background-white;
      border: 1px solid $grey;
      position: inherit !important;
    }

    .tooltip-arrow:after, .tooltip-arrow:before {
      top: 100%;
      left: 10%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .tooltip-arrow:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $grey;
      border-width: 5px;
      margin-left: -5px;
    }

    .tooltip-arrow:before {
      border-color: rgba(224, 224, 224, 0);
      border-top-color: $grey;
      border-width: 6px;
      margin-left: -6px;
    }
  }

  .tooltip-content {
    line-height: normal;
    font-size: 80%;
  }

  &.bonus-popover {
    border: 0;
    font-size: 12px;
    line-height: 13px;
    @media (max-width: 992px) {
      .tooltip-arrow:after, .tooltip-arrow:before {
        left: calc(50% - 5px) !important;
      }
    }

    .tooltip-arrow:before {
      border-top-color: $grey !important;
    }
  }
}

.product-tag {
  width: 28px;
  line-height: 13px;
  z-index: 2;
  cursor: pointer;

  .express-tag {
    max-width: 100%;
    height: 1.2rem;
    vertical-align: text-bottom;
  }
}

.product-bonus-text {
  font-size: 18px;
  line-height: 20px;
}

.product-button-yellow {
  color: $text-color-white;
  font-size: 14px;
  padding: 0px 5px 0px 5px;
  background: $secondary70;
  border-radius: 5px;

}

.product-page-button-yellow {
  font-size: 1.5rem;
  padding: 5px 5px 5px 5px;
  background: $secondary70;
  border-radius: 5px;
  color: $text-color-white;
}

.card-gift-block {
  background: #61A3F1;
  box-sizing: border-box;
  border-radius: 5px;
  color: $text-color-white;
  width: 100%;
  margin-bottom: 4px;
}

.card-gift-icon {
  width: 25px;
  margin: 5px 10px 5px 10px;
}

.search-item {
  &__link {
    width: 70px;
    margin-right: 5px;
  }

  &__img {
    margin: 0 auto;
    width: 60px;
    object-fit: contain;
    border-radius: 0.6rem;
    -webkit-border-radius: 0.6rem;
    -moz-border-radius: 0.6rem;
    mix-blend-mode: darken;
  }
}

.product-card-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  grid-column-gap: 0.6rem;
  grid-row-gap: 0.8rem;

  @include respond-below(sm) {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-column-gap: 0.2rem;
    grid-row-gap: 0.2rem;
  }

  @include respond-below(md) {
    grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
    grid-column-gap: 0.4rem;
    grid-row-gap: 0.4rem;
  }
}

.product-card {
  display: flex;
  flex-direction: column;

  &__members {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    line-height: 24px;
    margin-top: 2px;
    white-space: normal;
    max-width: 100%;
    align-self: baseline;
    > div {
      border-radius: 9px;
      color: #333;
      padding: 0 8px;
      background-color: #FFD458;

      @include respond-below(md) {
        width: min-content;
      }
    }
  }

  &__brand {
    color: #4F4F4F;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @at-root .quantity-control--hpd &__price-actual {
    font-size: 15px;
    line-height: 15px;
  }

  &__discount {
    font-family: 'Montserrat';
    font-size: 9px;
    font-weight: 600;
    line-height: 9px;
    color: #828282;
  }

  &__measure {
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    color: #ADADAD;
  }

  &__small-batch {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;

    &--product {
      justify-content: flex-start;

      img {
        width: 18px;
        height: 18px;
      }
    }

    img {
      width: 16px;
      height: 16px;
    }

    span {
      color: #377FF6;
      font-size: 13px;
      font-weight: 500;
      line-height: 13px;
    }

    i {
      color: #377FF6;
      font-size: 10px;
    }
  }

  &__order-soon {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 6px;

    img {
      width: 16px;
      height: 16px;
    }

    span {
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 500;
      line-height: 13px;
      color: #E56058;
    }
  }

  &__sponsored {
    color: #8C8C8C;
    font-family: 'Inter', serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
  }

  &--md {
    flex-direction: row;

    .product-card__content {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
    }

    .product-card__link {
      border-radius: .5rem;
    }

    .product-card__body {
      margin-top: 0;
      margin-left: .5rem;
      flex-grow: 1;
    }

    .product-card__controls {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .product-card__price--discount {
      margin-bottom: .2rem;
    }

    .product-card__price > s {
      font-size: 75%;
    }

    .product-card__labels-item {
      padding: 0 3px;
    }

    .controls-container {
      margin-top: 0;

      .quantity-control > div .btn {
        padding: 0.2rem 0.3rem;
      }

      input.form-control {
        width: 1.35rem;
        padding-right: .1rem;
      }
    }
  }

  &__content {
    position: relative;
    text-align: center;
    width: 100%;
  }

  &__content .labels, .labels {
    flex-direction: column;
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    z-index: 510;
  }

  &__stickers {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: absolute;
    top: 11px;
    left: 10px;
    z-index: 510;
  }

  &__stickers-item {
    height: 34px;
    width: 34px;
    background-size: contain;
    background-repeat: no-repeat;
    color: $text-color-white;
    position: relative;
    z-index: 1000;

    &.farm {
      background-image: url("../assets/icons/farmers-sticker.svg");
    }

    &.local {
      background-image: url("../assets/icons/local-sticker.svg");
    }
  }

  &__link {
    position: relative;
    z-index: 500;
    background-color: $grey20;
    display: block;
    overflow: hidden;
    border-radius: 1.2rem;

    @include aspect-ratio(1, 1);
  }

  &__slider {
    position: relative;
    left: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    transition: left .2s ease-in-out;
    background-color: inherit;

    z-index: 520;
  }

  &__slide {
    height: 100%;
    width: 100%;
    object-fit: contain;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    @include aspect-ratio(1, 1);
    background-color: inherit;
    z-index: 530;

    &:hover img {
      transform: scale(1.05);
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    mix-blend-mode: darken;
    transition: .15s all ease-in-out;
  }

  &__body {
    position: relative;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__title {
    line-height: 120%;
    font-size: 14px;
    color: $grey80 !important;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 34px;
  }

  &__price-wrapper &__price {
    display: inline-block;
  }

  &__price {
    margin: 0;
    font-family: "Montserrat", sans-serif;
    color: $grey80;

    > .labels-item {
      display: inline-block;
    }

    > * {
      vertical-align: middle;
    }

    > b {

    }

    > s {
      color: $grey60;
      font-weight: 500;
      font-size: 80%;
    }
  }

  &__price--discount {
    background-color: #ffcc00;
    padding: 0 .15rem;
    color: $grey90;
    text-align: right;
    white-space: nowrap;

    //> * {
    //  vertical-align: middle;
    //}

    &::before {
      display: inline-block;
      background-image: url('../assets/labels/sale.png');
      background-size: contain;
      width: 2.25em;
      height: 1em;
      margin-right: .25rem;
      content: ' ';
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: middle;
      margin-top: -2px;
    }
  }

  &:hover &__title {
    color: $green !important;
  }

  &__zoom {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  &__controls {
    display: flex;
    flex-direction: column;
  }

  &__view {

  }

  &__thumbnails {
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  &__thumbnail {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 10px;
    background-color: $grey20;
    flex-grow: 1;
    overflow: hidden;

    transition: box-shadow .2s ease-in-out;

    &--active {
      box-shadow: 0 0 0 2px $green40;
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }

    img {
      mix-blend-mode: darken;
      object-fit: cover;
    }
  }

  &__bullets {
    position: absolute;
    bottom: -0.5rem;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    z-index: 501;
  }

  &__bullet {
    background-color: $grey30;
    width: 4px;
    height: 4px;
    border-radius: 4px;
    margin: 0 1.5px;

    &.active {
      background-color: $grey80;
    }
  }
}

.product-card:not(.product-card--active) {
  .quantity-control .fa-plus {
    color: #53C95A;
  }
}

.product-images-swiper {
  &--preview {
    height: 100%;
    width: 100%;

    .swiper-pagination-bullet {
      position: relative;
    }

    .swiper-pagination-bullet-active {
      background-color: rgba(0, 0, 0, 0.75);
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-button-prev, .swiper-button-next {
      &::after {
        content: '';
      }
    }

    /*
    .swiper-button-prev, .swiper-button-next {
      background-color: rgba(60,60,60,0.5);
      border-radius: 100px;
      width: 36px;
      height: 36px;
      overflow: hidden;
    }
    */
  }

  &--thumbs {
    height: 64px;
    box-sizing: border-box;
    padding: 10px 0;
    width: 100%;

    .swiper-slide {
      min-width: 64px !important;
      height: 100%;
      opacity: 0.4;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $grey20;
      border-radius: 5px;
      margin: 0 5px;
    }

    .swiper-slide-thumb-active {
      opacity: 1;
    }

    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      mix-blend-mode: darken;
    }
  }
}

/* Product carousel */

.product-carousel {
  &__header {
    display: flex;
    justify-content: space-between;
    font-family: "Montserrat", sans-serif;
    align-items: center;
    margin-bottom: 0.8rem;

    p {
      font-size: 1.1rem;
      margin: 0;
    }
  }

  &__title {
    font-size: 1.5rem;
    margin: 0;
    color: $main-text-color;
    font-weight: 700;
  }

  &__title:hover {
    color: $green;
    text-decoration-color: $green !important;
  }

  @include respond-above(lg) {
    &--md {
      .product-swiper {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &--md &__title {
    font-size: 1rem;
    margin-left: 0;
  }

  &__see-all {
    margin-right: 0.5rem;
    color: $main-text-color;
    font-weight: 700;
  }

  &__see-all:hover {
    color: $green;
    text-decoration: none;
  }

  &__ctrl {
    white-space: nowrap;
  }
}

@media (max-width: 540px) {
  .product-carousel__ctrl {
    display: none;
  }

  .product-carousel__header h3 {
    font-size: 1.2rem;
  }
}

.carousel-btn {
  background-color: $background-grey30;

  -webkit-mask-image: url("../assets/icons/carousel-left.svg");
  mask-image: url("../assets/icons/carousel-left.svg");

  & + & {
    -webkit-mask-image: url("../assets/icons/carousel-right.svg");
    mask-image: url("../assets/icons/carousel-right.svg");
  }

  border: none;
  text-decoration: none;
  cursor: pointer;
  color: $text-color-white;

  border-radius: 30px;
  width: 30px;
  height: 30px;
  vertical-align: bottom;

  &:focus {
    outline: 0 !important;
  }

  &:hover {
    background-color: $btn-hover-green;
  }
}


.ico {
  display: inline-block;
  width: 1.5rem;
  height: 1.25rem;
  vertical-align: text-bottom;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.ico-cart-arrow-up {
  background-image: url("../assets/social/cart-btn-icon-remove-item.svg?stroke=white");
}
.ico-cart-arrow-down {
  background-image: url("../assets/social/cart-btn-icon.svg?stroke=white");
}

.product-popup-holder {
  display: inline;
}
.product-popup-label--family {
  position: absolute;
  z-index: 900;

  margin-right: .5rem;
  font-weight: bold;
  padding: .5rem .75rem;
  background-color: $secondary30;
  color: $grey80;
  border-radius: .5rem;
  box-shadow: 0 3px 6px -2px rgba(0, 0, 0, 0.5);
  font-size: 14px;
  text-align: left;

  a {
    color: $grey90;
    text-decoration: underline;
  }

  .row {
    color: $secondary70;
    align-items: center;
  }

  ul {
    font-size: 13px;
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  li {
    position: relative;

    &::after {
      //content: '•';
      position: absolute;
      left: -.5rem;
    }
  }

  img {
    width: 50px;
  }

  .sub {
    font-weight: normal;
    margin-top: .25rem;
    white-space: nowrap;
  }

  &.variance-2 {
    .row {
      color: $grey90;
    }

    .col {
      font-size: 120%;
      line-height: 1.2;
    }
  }
}

.product-carousel__promo {
  height: 100%;

  .panel-double {
    margin-top: .5rem;
    margin-bottom: .5rem;

    height: 64%;

    border-radius: 1.2rem;
    overflow: hidden;
  }

  .media {
    background-color: $accent30;
    border-radius: 16px;
    margin-top: .5rem;
    margin-bottom: .5rem;

    height: 64%;

    align-items: center;
    padding: 0 3rem;
  }

  .media-image:first-child {
    max-width: 128px;
  }

  .media-image {
    height: 80%;
    width: auto;
    max-width: 138px;
    display: flex;
    align-items: center;
  }

  .media-body {
    padding: 0 2rem;

    p:first-child {
      font-size: 120%;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
  }
}


.favorite {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 510;

  &.loading {
    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .icon {
    width: 20px;
    height: 20px;

    transition: width .1s ease-in-out, height .1s ease-in-out;
  }
}

.favorite__item {
  display: flex;

  border: 1px solid $grey30;
  border-radius: 6px;

  margin: 0;

  &.dropdown-item {
    margin: .25rem .5rem;
    padding: .25rem .5rem;
    font-size: 13px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .icon {
    color: $grey40;
    width: 20px;
    height: 20px;

    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    transition: width .2s ease-in-out, opacity .2s ease-in-out, color .2s ease-in-out;
    text-align: center;
    opacity: 0;

    > img {
      width: 100%;
      vertical-align: top;
    }
  }

  .target {
    display: none;
  }

  &:hover {
    .target {
      display: block;
    }
  }

  &--active {
    font-weight: bold;

    .icon {
      color: $accent50 !important;
      opacity: 1;
    }
  }

  &[disabled], &.working {
    .icon {
      opacity: 1;
    }
  }

  &:hover {
    background-color: $accent20;
  }
}

.favorite__popover {
  border: 0;

  .form {
    padding: 0 .5rem 0 .5rem;
    overflow: hidden;
    max-height: 0;

    transition: margin-top .25s ease-in-out, padding .25s ease-in-out, max-height .25s ease-in-out;

    margin-top: .5rem;

    .icon {
      left: .7rem;
    }

    &--visible {
      max-height: 6rem;
      padding: .25rem .5rem .5rem .5rem;
      margin-top: 0;
    }

    .form-control {
      padding-left: 2rem;
    }
  }

  .dropdown-menu {
    padding-top: .25rem;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.favorite__modal {
  border: 0;

  .form {
    .form-control {
      padding-left: 2.5rem;
    }

    .icon {
      left: .9rem;
    }
  }

  .favorite__item {
    .icon {
      margin-right: .5rem;
    }
  }
}

.favorite__menu {
  .form {
    .icon {
      position: absolute;
      z-index: 100;
      color: $grey40;
      transition: color .25s ease-in-out;
      margin-top: 1px;
    }

    .form-control {
      border-radius: 6px;
    }

    .form-control:focus + .icon {
      color: $green;
    }
  }

  .dropdown-item {
    &.working span {
      opacity: .75;
    }

    a {
      color: $grey90;
    }

    a:first-child {
      flex-grow: 1;
    }

    a:last-child {
      color: $green;
    }
  }
}

.benefits {
  @extend .container;
  padding-top: 10px ;
  padding-bottom: 10px ;
}

@media (max-width: 767px) {
  .benefits {
    padding: 32px 0;
    margin-left: 0;
    margin-right: 0;
    background-color: #FFFBEF;
  }
}
