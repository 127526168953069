
.delivery-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  height: 90vh; /* Высота модалки в 90% от высоты экрана */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.modal-image {
  flex: 1;
  background: #f7f7f7;
}

.modal-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto; /* Добавляем прокрутку */
  max-height: 100%;
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
}

.close-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  flex: 1;
  overflow-y: auto; /* Прокрутка для тела модалки */
  margin-bottom: 16px; /* Добавляет отступ снизу, чтобы кнопка была видимой */
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 8px;
}

.description {
  font-size: 16px;
  color: #555;
  margin-bottom: 24px;
}

.delivery-days {
  margin-bottom: 24px;
}

.schedule-label {
  font-size: 14px;
  color: #333;
  margin-bottom: 12px;
}

.days-container {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.day-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.day-indicator {
  width: 12px;
  height: 12px;
  background: #ccc;
  border-radius: 50%;
  flex-shrink: 0; /* Не позволяет индикатору сжиматься */
}

.day-indicator.active {
  background: #53c95a;
}

.delivery-day {
  font-size: 14px;
  color: #333;
}

.schedule-day {
  display: flex;
  align-items: center; /* Центрирование по вертикали */
  gap: 8px; /* Отступ между индикатором и текстом */
}

.schedule-times {
  list-style: none;
  padding: 0;
  margin: 0;
}

.schedule-times li {
  font-size: 14px;
  color: #555;
  margin-bottom: 4px;
}

/* Контейнер списка */
.list-container {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Отступ между элементами */
  margin-top: 16px;
}

/* Элемент списка */
.list-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  cursor: pointer;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.list-item:hover {
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}

/* Иконка слева */
.list-icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.list-icon img {
  width: 100%;
  height: auto;
  opacity: 0.8;
}

/* Текстовый блок */
.list-text {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.list-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.list-subtitle {
  font-size: 14px;
  color: #888;
  margin: 4px 0 0;
}

/* Стрелка справа */
.list-arrow {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-arrow img {
  width: 100%;
  height: auto;
  opacity: 0.6;
}

.schedule-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Отступы между блоками */
  justify-content: space-between;
  margin-top: 16px;
}

.day-schedule {
  width: 45%; /* Два столбца */
  display: flex;
  flex-direction: column;
}

.day-title {
  font-size: 16px;
  color: #333;
}

.back-button {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #53c95a;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.back-button:hover {
  color: #45b049;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

button:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 480px) {
  .modal-content {
    flex-direction: column; /* Вертикальное расположение */
    max-height: 100vh;
    overflow-y: auto;
  }

  .modal-image {
    height: 40%; /* Фиксированная минимальная высота для изображения */
  }

  .modal-form {
    padding: 16px; /* Минимальные отступы для компактности */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .modal-header {
    flex-direction: column;
    margin-bottom: 12px;
  }

  .title {
    font-size: 18px; /* Уменьшаем заголовок */
    margin-bottom: 8px;
  }

  .description {
    font-size: 14px;
    margin-bottom: 12px;
  }

  .day-container {
    margin-bottom: 8px; /* Уменьшаем расстояние между днями доставки */
  }

  .day-indicator {
    width: 12px; /* Меньший индикатор */
    height: 12px;
  }

  .delivery-day {
    font-size: 12px; /* Меньший текст дня */
  }

  .list-container {
    gap: 8px; /* Уменьшаем отступы между элементами */
  }

  .list-item {
    padding: 10px; /* Компактный padding для элементов списка */
    font-size: 14px;
  }

  .back-button,
  .close-button {
    font-size: 16px; /* Уменьшаем размер кнопок */
  }
}
