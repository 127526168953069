@import "../mixin/color";
@import "../mixin/respond";

$express-bg-color: #ffee58;
$express-text-color: $main-text-color;

.cart-express-switcher {
  padding: 1rem;
  margin: .5rem 0;
  background-color: $express-bg-color;
  color: $express-text-color;
  font-size: .85rem;
  box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
  border-radius: .25rem;

  display: flex;
  align-items: center;

  & > div {
    flex-grow: 1;
  }
}

.cart-header {
  position: relative;
  border: 1px solid $grey30;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  width: 100%;
  height: 60%;
  border-radius: 4px;

  display: flex;
  align-items: center;

  @include respond-below(lg) {
    border: 0;
    width: auto;
  }

  @media (max-width: 329px) {
    width: 60px;
  }

  .icon {
    color: $green;
    margin-left: 1rem;
    width: 50px;

    @include respond-below(lg) {
      font-size: 2rem;
      width: auto;
      margin-left: 0;
    }
  }

  .counter {
    @include respond-below(lg) {
      font-size: 22px;
      color: $green;
      font-weight: bold;
    }
  }

  .caret {
    color: $green;
    position: absolute;
    top: 50%;
    margin-top: -.75rem;
    right: 1rem;
  }

  .new-basket {
    width: 26px;
    height: 21px;
    background-image: url('../assets/banners/new-basket.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
  }
}

.cart-side {
  &-title {
    font-size: 1.3rem;
    color: $main-text-color;
  }
}

.cart-wrapper {
  overflow: hidden;
  border-radius: .5rem;
  padding: .8rem .8rem .4rem ;

  @media (max-width: 380px) {
    .media-image {
      width: 64px;
      height: 64px;
    }
  }

  .cart {

    &-delivery {
      &-item {
        margin-top: .45rem;
      }

      &-title {
        line-height: 2rem;
      }

      &-groups {
        background-color: $background-white;
        border-radius: .3rem;
        margin-left: -.5rem;
        margin-right: -.5rem;
        box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.05);
      }

      &-summary {
        background-color: $background-white;

        border-bottom-left-radius: .25rem;
        border-bottom-right-radius: .25rem;

        padding: .5rem 1rem;
        overflow: hidden;
        box-shadow: 0 3px 0px -1px rgba(0,0,0,0.05);
      }

      &-content {
        padding: .5rem;
        background-color: $red;
        border-radius: .25rem;
        margin-top: .5rem;
        margin-bottom: .5rem;
        color: $text-color-white;
        box-shadow: 0 1px 0 1px rgba(0,0,0,0.15);
      }
    }

    &-group {
      &-title {
        background: $secondary30;
        padding: .1rem 1rem;
      }
    }
  }

  .cart-clear-btn {
    // TODO: check
    color: $green;
    border: 1px solid $green;
    background-color: $background-grey10;
    opacity: 0.8;

    &:hover {
      background-color: $background-white;
      border-color: $btn-hover-green;
      color: $btn-hover-green;
    }
  }
}

.basket-column {
  height: 100%;

  > .cart-side {
    border: 1px solid $grey30;
    border-radius: .5rem;
    padding: .8rem .8rem .4rem ;
  }
}

.cart-item-wrapper {

  // TODO: check
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;

  border-bottom: 1px solid $grey20;
  background-color: $background-white;
  overflow: hidden;

  &.first {
    padding-top: 8px;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  &.last {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .cart-product-title {
    //font-size: 1rem;

    // TODO: check
    display: flex;
    justify-content: space-between;
    padding-bottom: 4px;
    font-size: 14px;
    align-items: flex-start;

    a {
      color: $main-text-color;
      width: 90%;
      &:hover {
        color: $green;
      }
    }
  }

  .cart-product-price {
    text-align: right;
    font-weight: bold;
    color: $main-text-color;
    font-size: .85rem;
  }

  .product-item {
    margin: 0;
    padding-right: 4px;
  }

  .cart-item {
    //padding: .5rem 1rem;

    // TODO: check
    min-height: 70px;
    padding-left: 5px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;

    & .cart-product-title {
      padding-right: 8px;
    }

    .controls {
      //width: auto;
      //margin-right: 10px;

      // TODO: check
      margin-right: 0;
      width: 70%;
    }

    .cart-btn {
      @media (max-width: 370px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      all: unset;
      color: $grey30;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      padding: 0;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      cursor: pointer;

      &:hover {
        color: $green;
      }
    }
  }

  .media {
    position: relative;

    &-image {
      //margin-left: .75rem;

      // TODO: check
      margin-left: 3px;
      margin-right: 16px;

      img {
        border-radius: 6px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }
}


// TODO: check
.measure{
  &-text{
    font-size: 14px;
    font-weight: 500;
    padding-right: 2px !important;
    padding-left: 0px !important;
    height: auto !important;
    color: $main-text-color;
  }
}

.gift-outer-block {
  display: flex;
  justify-content: space-between;
}

.gift-icon {
  width: 15px;
  margin: 0 0 5px 8px;
}

.product-remove {
  position: relative;
  z-index: 10;
}

.cart-flip {
  .cart-item-wrapper {
    max-height: 150px;
  }
  .cart-delivery-title, .cart-group-title {
    max-height: 3rem;
  }
  .cart-delivery-summary {
    max-height: 6rem;
  }

  &--clipped {
    max-height: 580px;
    overflow: auto;
  }
}


.checkout-order-deadline {
  background-color: rgba(253, 68, 56, 0.1);
  border-radius: 10px;
  padding: 15px;
  max-width: 390px;
  text-align: center;

  p {
    color: $grey;
  }
}

.cart-membership {
  background-color: $secondary30;
  border-radius: 10px;
  padding: 16px;

  img {
    width: 100%;
  }

  .btn {
    margin-top: 1rem;
    border-radius: 16px;
  }

  > div > div:last-child {
    padding-left: 16px;
  }
}
