.farmer {
  min-width: 155px;
}

.farmer__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.farmer__avatar {
  position: relative;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 8px;
  border: 2px solid #F8C94F;
}

.farmer__avatar::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #ffffff;
}

.farmer__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.farmer__name {
  font-size: 15px;
  font-weight: 700;
  max-width: 155px;
  margin-bottom: 2px;
}

.farmer__location {
  font-size: 11px;
  font-weight: 500;
  max-width: 155px;
}

.farmer__description {
  font-size: 12px;
  font-weight: 500;
  max-width: 155px;
  margin-bottom: 2px;
}

.farmer__info {
  color: #333333;
}


.bestsellingfarmers {
  &__showall {
    text-wrap: normal;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    a {
      display: flex;
      width: 153px;
      padding: 11px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 16px;
      background: #FFD458;
      color: #0F0F13;
      text-align: center;
      font-family: Montserrat;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
    }
  }

  &__index {
    text-wrap: normal;
    display: flex;
    flex-flow: column;
    align-items: center;

    &-image {
      width: 100%; height: 100%; object-fit: cover; object-position: center; border-radius: 1.2rem; aspect-ratio: 1/1;
    }
    &-badge {
      position: absolute; bottom: 10px; left: 10px; max-width: 90%; padding: 3px 5px; color: #1E1E1E; background-color: #FFD458; border-radius: 10px; font-weight: 600; font-size: 9px; line-height: 11px;
    }
    &-url {
      font-family: Montserrat;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 4px;
      padding: 0 .5rem;
    }
    &-title {
      font-size: 11px;
      font-weight: 500;
      line-height: 12px;
      letter-spacing: 0;
      text-align: center;
      color: #828282;
      margin-bottom: 4px;
    }
    &-subtitle {
      text-wrap: normal;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      letter-spacing: 0;
      text-align: center;
      margin: 0 .5rem;
    }
  }
}
