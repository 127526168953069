
.catalog-scroll {
  overflow-y: auto !important;
}

.catalog-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: rgba(145, 145, 145, 0.15);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.catalog-scroll::-webkit-scrollbar-thumb {
  background: rgb(177, 177, 177);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}
