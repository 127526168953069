@import 'vue-slider-component/theme/default.css';
@import '../mixin/respond';
@import '../mixin/color';

.filter {
  background: $background-grey20;
  border: 1px solid $grey30;
  border-radius: 2px;
  margin: 16px 0 0 0;
  padding: 8px 8px 0;
  &-header {
    padding-bottom: 8px;
    line-height: 14px;
    cursor: pointer;
    position: relative;
    font-weight: bolder;

    &-switch {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &-content {
    border-top: 1px solid $grey30;
    margin: 0 -8px;
    padding: 0 8px 8px;

    &-item {
      padding: 8px 0;
      @media(max-width: 390px) {
        font-size: 75%;
      }

      &-title {
        margin-bottom: 8px;
        font-weight: bolder;
      }

      .price-range {
        padding-top: 28px;
      }
    }
  }

  .active {
    display: block;
    opacity: 1;
  }
}

.filter-sort {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  .form-control {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    padding-top: 3px;
    padding-bottom: 4px;
    border: 1px solid $grey30;
    box-sizing: border-box;
    border-radius: 3px;
    height: 32px;
    -moz-appearance: none;
    color: $main-text-color;
    max-width: 250px;
    @media (max-width: 374px) {
      height: 30px;
      font-size: 11px;
      line-height: 19px;
      max-width: 200px;
    }
  }
  .filter-span {
    border: 1px solid $grey30;
    box-sizing: border-box;
    border-radius: 3px;
    padding-top: 4px;
    padding-bottom: 4px;
    cursor: pointer;
    @media (max-width: 374px) {
      font-size: 11px;
      line-height: 19px;
    }
    &:hover {
      background: $background-grey20;
    }
    &.active {
      background: $background-green;
      color: $text-color-white;
    }
  }
}

/* Catalog page */
.catalog {
  h1 {
    @include respond-below(sm) {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0.44px;
      color: $main-text-color;
    }
  }
  .recommend-title {
    @include respond-below(sm) {
      font-size: 1rem;
    }
  }
  .nav {
    &-item {
      .nav-link {
        @include respond-below(lg) {
          border-top-width: 0 !important;
          min-height: 2rem !important;
          .nav-text {
            margin-left: unset;
            margin-right: unset;
            text-align: left;
            color: $green;
          }
        }
      }
      &:first-of-type {
        @include respond-below(lg) {
          .nav-link {
            border-top-width: 1px !important;
          }
        }
      }
      &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4)  {
        @include respond-below(lg) {
          .nav-link {
            border-top-width: 1px !important;
          }
        }
        @include respond-below(md) {
          .nav-link {
            border-top-width: 0 !important;
          }
        }
      }
      .nav-link.catalog-link-banner {
        border: 3px solid $green !important;
        position: relative;
        @include respond-below(lg) {
          border-top-width: 3px !important;
        }
        @include respond-below(md) {
          border-top-width: 2px !important;
          padding-right: 40px;
        }
        img {
          position: absolute;
          bottom: 0;
          right: 4px;
        }
      }
    }
  }


  .gift-icon {
    width: 30px;
    height: 26px;
    margin-bottom: 6px;
    @media (max-width: 992px) {
      width: 60px;
      height: 62px;
    }
  }

  .catalog-gift-message {
    background: #61A3F1;
    height: 47px;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    padding-top: 9px;
    color: $text-color-white;
    font-weight: 400;
    font-size: 20px;
  }
}
/* Catalog page end */

.catalog-card__content {
  .catalog-item__pin-new {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: $pink;
    top: 24px;
    right: 12px;
  }
 }

@media screen and (min-width: 768px) {
  .catalog-card__content {
    .catalog-item__pin-new {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background-color: $pink;
      display: block;
      position: static;
    }
  }

  .catalog-card__content-title {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

/* Catalog popup header */
.catalog-popup {
  position: relative;
  margin: 0 8px;

  .catalog-item__pin-new {
    display: block;
    flex-grow: initial !important;
    margin-right: 6px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: $pink;
  }

  .catalog-item-style {
    &__farmers {
      margin-bottom: .5rem;

      a {
        &::after {
          content: '';
          background-image: url('../assets/icons/farmers-flower-icon.svg?fill=' + encode-color($grey90) + '&stroke=' + encode-color($grey90));
          background-size: contain;
          background-repeat: no-repeat;
          width: 22px;
          height: 22px;
          display: inline-block;
          vertical-align: text-bottom;
          margin-left: 0.5rem;
        }

        &:hover::after {
          background-image: url('../assets/icons/farmers-flower-icon.svg?fill=' + encode-color($green) + '&stroke=' + encode-color($green));
        }
      }
    }
  }

  &__button {
    border: 1px solid $grey30;
    cursor: pointer;
    padding: 10px 14px;
    border-radius: 8px;
    background-color: $background-white !important;

    &:hover {
      background-color: $background-green10 !important;
    }
  }

  .droparea {
    display: none;
    position: absolute;
    top: 30px;
    left: -40px;
    padding-top: 20px;
    z-index: 9999;
  }

  &:hover .droparea, &.show .droparea {
    display: block;
  }

  .catalog__wrap {
    background-color: $background-white;
    width: 930px;
    height: 490px;
    padding: 14px 25px 16px 0;
    border-radius: 0 0 8px 8px;
    border: 0;
    display: flex;
  }

  @include respond-below(xl) {
    .catalog__wrap {
      width: 800px;
    }
  }

  a {
    color: $main-text-color;
  }

  li > a {
    padding: 2px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      background-repeat: no-repeat;
    }
  }

  .catalog__list {
    width: 280px;

    & ul a {
      padding: 12px 2px;
    }
  }

  .catalog__list {
    ul a {
      display: flex;
      align-items: center;
      position: relative;

      span {
        display: block;
        flex-grow: 1;
      }

      img {
        margin-right: 8pt;
      }

      .bi-chevron-right {
        color: $grey40;
        margin-right: 12px;
      }
    }

    li {
      padding-left: 16px;
    }

    li:hover, .catalog-item-active {
      background-color: $grey20;

      a /*, .fa*/ {
        //color: $green;
        color: $grey90;
      }
    }
  }

  .catalog__sublist {
    flex: 1 1 0;
    overflow: auto;

    > ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    li {
      display: block;
      padding: 0 .5rem 0 0;
      width: 150px;

      a {
        display: block;
        position: relative;
        color: $grey90;

        &:hover {
          color: $green !important;
        }
      }

      img {
        width: 100%;
        display: block;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        transition: opacity .2s ease-out;
        opacity: 0;
        object-fit: cover;

        @include aspect-ratio(106, 87);

        &.loaded {
          opacity: 100%;
        }
      }

      .catalog-name {
        white-space: normal;
        padding: 0.35rem 0;
      }

      /* @todo: refactor !important styles */
      &.see-all {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 114px !important;
        width: 138px !important;
        background-color: #F5F5F5;
        border-radius: 10px;
        color: #1E1E1E;
        gap: 2px;
        font-weight: 500;
      }
    }
  }

  .catalog__sublist-title {
    font-size: 1.5rem;
    font-family: "Montserrat", sans-serif;
    color: $main-text-color;
  }

  .catalog-icon {
    width: 20px;
    height: 20px;
    font-size: 15px;
  }

  .catalog-scroll {
    overflow-y: auto;
  }
  .catalog-scroll::-webkit-scrollbar {
    width: 3px;
    background-color: $background-grey30;
    border-radius: 3px;
  }

  .catalog-scroll::-webkit-scrollbar-thumb {
    background: $background-grey40;
    border-radius: 3px;
  }
}
/* Catalog popup header end */


.catalog-card{
  &__content{
    width: 80px;

    @media screen and (min-width: 768px) {
      width: 140px;
      height: 140px;
      border: 1px solid $grey20;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
    }

    &:hover {
      border-color: $grey30;
    }
    & a {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &-title{
      font-size: 14px;
      line-height: normal;
      text-align: center;
      color: $grey80;
      font-weight: 500;
      font-style: normal;
    }
    &-image{
      width: 42px;
      height: 42px;
      margin-top: 28px;
      margin-bottom: 12px;
    }
  }
}

.container-catalog {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;

  &__title {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.7rem;
    font-weight:600;
    color: #333333;
  }

  &__elements {
    display: grid;
    grid-template-columns: 250px auto 250px;
    margin-top: 0.6rem;
  }

  &__filters-mobile {
    display: none;
  }

  &__ctrl {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-bottom: 0.5rem;
    min-height: 30px;
    font-size: 14px;
  }

  &__filters {
    margin-top: 2.5rem;
  }

  &__breadcrumbs {
  }

  &__pagination {
    font-size: 13px;
  }

  &__search {
    margin-bottom: 1rem;
  }
}

@media (max-width: 1105px) {
  .container-catalog {
    &__elements {
      grid-template-columns: 250px auto;
    }

    &__cart {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .container-catalog {
    &__title {
      font-size: 1.35rem;
    }

    &__elements {
      grid-template-columns: auto;
    }

    &__cart {
      display: none;
    }

    &__filters {
      display: none;
    }

    &__filters-mobile {
      display: block;
    }

    &__ctrl {
      > div {
        flex: 1;
      }
    }
  }
}

.catalog-tags-wrapper {
  position: relative;
}

.catalog-tags {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: nowrap;
  overflow-x: scroll;
  margin-bottom: 1rem;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  &__item {
    flex: 0 0 auto;
    font-weight: 500;
    padding: 6px 12px !important;
    background-color: #F5F5F5 !important;
    color: $grey80 !important;
    border-radius: 10px !important;
    font-size: 13px;

    &:hover{
      background-color: #EEFAEF !important;
      color:  #53C95A !important;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 992px) {
    &-wrapper {
      height: 45px;
    }

    & {
      margin-top: 0.8rem;
      margin-bottom: 8px;
      padding-bottom: 3px;
      width: 100%;
      position: absolute;
      bottom: 0;
    }

    &::-webkit-scrollbar {
      display: block;
      background-color: #f2f2f2;
      height: 2px;
    }

    ::-webkit-scrollbar-thumb {
      background: #dbdbdb;
      border-radius: 2px;
    }
  }
}

.search-catalog {
  &__ctrl {
    display: flex;
    flex-direction: row;
    gap: 0.6rem;
  }

  &__input {
    flex: 1;
    border-radius: 0.5rem;
    border-color: $grey30;
  }

  &__btn {
    padding: 0.3rem 2.8rem;
    border-radius: 0.5rem;
  }
}
