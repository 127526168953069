.new-makers {
  h4 {
    font-family: 'Montserrat';
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    color: #1E1E1E;
  }

  &__content {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 16px;
    overflow-y: scroll;
  }

  &__farmer {
    display: flex;
    flex-direction: column;
    min-width: 168px;
    width: 168px;

    &-img {
      overflow: hidden;
      border-radius: 16px;
      margin-bottom: 8px;

      img {
        width: 168px;
        height: auto;
        object-fit: cover;
      }
    }

    &-name {
      font-family: 'Inter';
      font-size: 13px;
      line-height: 18px;
      color: #4F4F4F;
      display: flex;
      flex-direction: column;
      margin-bottom: 12px;

      p:first-child {
        font-weight: 700;
        margin: 0;
      }

      p:last-child {
        font-weight: 500;
        margin: 0;
      }
    }

    &-description {
      color: #333333;
      font-family: 'Inter';
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 8px;
      margin-top: auto;
    }

    &-badges {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &-badge {
      img {
        width: auto;
        height: auto;
        max-width: 34px;
        max-height: 34px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .new-makers {
    h4 {
      margin-bottom: 16px;
    }

    &__content--jc {
      justify-content: space-between;
    }
  }
}
