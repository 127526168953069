@import "../mixin/respond";
@import "../mixin/color";

.tags {
  gap: 6px;
  display: flex;
  flex-direction: row;

  .tag {
    flex: 0 0 auto;
    font-weight: 500;
    padding: 6px 12px !important;
    background-color: $grey20 !important;
    color: $grey80 !important;
    border-radius: 10px !important;
    font-size: 13px;
    cursor: pointer;
    transition: color ease-in-out .2s, background-color ease-in-out .2s;

    &:hover {
      background-color: $accent20 !important;
      color:  $accent60 !important;
    }

    &.active {
      background-color: $accent60 !important;
      color:  white !important;
    }

    @at-root #{selector-unify(&, 'label')} {
      > input[type="checkbox"] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
    }
  }

  &-mobile {
    .collection-item-link {
      display: block;
    }

    a {
      text-decoration: none;
      outline: none;
    }

    .item {
      margin-bottom: 1rem;

      a span {
        display: inline-block;
      }
    }

    .banner {
      width: 100%;
      margin-right: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      min-height: 220px;
      position: relative;
      height: auto;
      border-radius: 6px;

      .title {
        font-size: 18px;
        color: $text-color-white;
        font-weight: bold;
        position: relative;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        line-height: normal;
        .fas {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }

  &-desktop {
    .item {

      display: block;
      text-decoration: none;
      outline: none;
      height: 100%;
      min-height: 520px;
      background-size: cover !important;
      background-repeat: no-repeat;
      &:hover {
        background-color:rgba(0, 0, 0, 0.7);

        .title {
          text-decoration: underline;
        }
      }

      .body {
        min-height: 120px;
      }

      .title {
        font-size: 24px;
        color: $text-color-white;
        font-weight: bold;
        border-radius: 5px;
        position: relative;
        line-height: normal;
        .fas {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }

      .text {
        font-size: 18px;
        color: $text-color-white;
      }
    }
  }
}

.is-mobile {
  .tags {
    //  TODO
  }
}

.is-desktop {
  .tags {
    //  TODO
  }
}

.tag-banner {
  height: 250px;
  overflow: hidden;
  position: relative;
  @include respond-below(lg) {
    height: 125px;
  }

  img {
    position: relative;
    margin-top: -250px;
    @include respond-below(lg) {
      margin-top: -50px;
    }
  }
}
