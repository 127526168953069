@import "../mixin/color";

.tooltip-trigger:hover + .tooltip, .tooltip-hover:hover, .tooltip.show {
  display: block;
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

$tooltip-arrow-size: 10px;

.tooltip-toaster {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: .5rem;
  left: .5rem;
  right: .5rem;
  z-index: 1000;
  gap: 5px;

  .tooltip {
    position: relative;
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    overflow: hidden;

    &.show {
      max-height: 50vh;
      padding-top: .75em;
      padding-bottom: .75em;
    }
  }
}

.tooltip {
  z-index: 1;
  //display: none;
  background: $background-white;
  border-radius: 1em;
  padding: .75em 1em;

  visibility: hidden;
  opacity: 0;
  transition: visibility 0s linear 0.25s, opacity 0.25s linear, padding-top .25s ease-in-out, padding-bottom .25s ease-in-out, max-height .5s ease-in-out;


  &.tooltip-toast {
    position: fixed;

    &.tooltip--placement-top {
      top: 1rem;
      bottom: auto;
    }

    &.tooltip--placement-bottom {
      top: auto;
      bottom: 1rem;
    }
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: $tooltip-arrow-size;
    border: 0;
    z-index: 1;
  }

  .tooltip-arrow {
    &:before {
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }
  }

  &--placement-center {
    left: 50%;
    transform: translateX(-50%);
  }

  &--placement-top {
    bottom: calc(100% + #{$tooltip-arrow-size / 4});

    .tooltip-arrow {
      left: calc(50% - #{$tooltip-arrow-size}) !important;
      bottom: -$tooltip-arrow-size;

      &:before {
        border-top-color: inherit;
        border-width: $tooltip-arrow-size;
        margin-left: -$tooltip-arrow-size;
      }
    }
  }

  &--placement-bottom {
    top: calc(100% + #{$tooltip-arrow-size / 4});

    .tooltip-arrow {
      left: calc(50% - #{$tooltip-arrow-size}) !important;
      bottom: calc(100% + #{$tooltip-arrow-size});

      &:before {
        border-bottom-color: inherit;
        border-width: $tooltip-arrow-size;
        margin-left: -$tooltip-arrow-size;
      }
    }
  }

  .tooltip-content {
  }

  .tooltip-arrow {
    border-color: $background-white;
  }
}

.tooltip--size-small {
  .tooltip-content {
    font-size: 80%;
  }
}

.tooltip--color-dark {
  background-color: $grey80;
  color: white;

  .tooltip-arrow {
    border-color: $grey80;
  }
}

.cart-pricer .tooltip {
  min-width: 390px;

  .tooltip-content {
    font-size: 12px;
  }
}
