@import "../mixin/respond";
@import "../mixin/color";

@include respond-below(sm) {
  .modal-dialog {
    max-width: 100%;
  }
}

.modal.d-flex {
  justify-content: center;

  .modal-dialog {
    margin: 0;
    max-height: 100%;
  }

  .modal-content {
    //height: 100%;
  }
}

.modal {

  &-background {
    transition: background-color 0.3s ease, opacity .3s ease;
    background-color: rgba(0, 0, 0, 0);
    display: block;
    height: 0;
    overflow: hidden;
    opacity: 0;

    &.in {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }

    &.show {
      overflow: auto;
      height: 100%;
    }
  }

  &-backdrop {

  }
}

.modal-content {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: none;
}

.close {
  position: absolute;
  right: 20px;
  text-shadow: none;
  color: white;
  z-index: 999;

  span {
    background-image: url("../assets/en/banners/close-btn.svg");
    background-repeat: no-repeat;
    background-size: 100% auto;
    height: 40px;
    width: 40px;
    display: block;
  }
}

.delivery-modal {
  width: 100%;

  .modal-content {
    border-radius: 20px;
    padding: 15px;
    box-shadow: none;
  }

  .close {
    right: 0;
  }

  .btn {
    border-radius: 10px;
    min-width: 170px;
  }

  @include respond-below(md) {
    .btn {
      min-width: 140px;
      margin-bottom: 10px;
    }
  }

  .title {
    font-size: 21px;
  }
}

.confirmModal {
  .modal-dialog {
    width: 500px;
    height: 334px;
    border-radius: 10px;
    line-height: 24px;

    @include respond-below(lg) {
      width: auto;
    }

    .disclaimer {
      display: block;
      text-align: left;
      background: #EFEFF4;
      padding: 1.5em 2em;
      font-size: 14px;
      line-height: 16px;
    }
  }

  .btn {
    border-radius: 4px;
    font-size: 16px;
    height: 47px;
  }

  &-header {
    font-size:18px;

    color: $grey80;
  }

  &-text {
    font-size: 14px;
  }

  &-warning {
    font-size: 12px;
    line-height: 14px;
    font-weight: 300;
    text-align: center;
    letter-spacing: 0.007px;
    color: $grey;
  }
}

@mixin modal-fullscreen() {
  padding: 0 !important;

  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-body {
    overflow-y: auto;
  }

}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-down($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .modal-fullscreen#{$infix} {
      @include modal-fullscreen();
    }

  }
}


.subscribe-modal-dialog {
  max-width: 680px;
  .close {
    span {
      padding: 25px;
    }
  }
  .modal-content {
    background: transparent;
  }
}

.subscribe-content {
  .content {
    display: flex;
    flex-direction: column;
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-self: center;
    justify-content: center;
    flex-grow: 1;
  }

  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-self: stretch;
    min-height: 400px;
    @include respond-below(md) {
      background-position: top;
    }
  }
  .content {
    background: #FFFFFF;
    align-self: stretch;
  }
}

.subscribe--startup-popup {
  .subscribe-content .img {
    min-height: 300px;

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }
}

.modal-purchase-subscription {
  transition: max-width .2s ease-in-out;

  .modal-top {
    padding: 1.25rem 1.5rem;

    img {
      height: 25px;
    }
  }

  .img {
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    align-self: stretch;
    min-height: 400px;
    @include respond-below(md) {
      background-position: top;
    }
  }

  .media {
    img {
      width: 2rem;
    }
    h5 {
      font-weight: bold;
      font-size: 15px;
    }
    p {
      font-size: 13px;
    }
  }
}

$feedback-product-image-size: 120px;
$feedback-product-image-small: 40px;

.modal__feedback-flow {
  .image-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: $feedback-product-image-size;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    background-color: $grey20;
    mix-blend-mode: darken;
    transition: width .5s ease-in-out, height .5s ease-in-out, opacity .5s ease-in-out;

    &.empty {
      background-color: transparent;
    }

    > img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .image.active {
    width: $feedback-product-image-size;
    height: $feedback-product-image-size;
    opacity: 1;
  }

  // one sibling before and after
  .image:has(+.image.active), .image.active+.image {
    width: $feedback-product-image-small;
    height: $feedback-product-image-small;
    opacity: .5;
  }
}

.feedback-products-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &--ghost {
    position: static;
  }
}

.feedback-products-modal__content {
  height: 100%;
  border-radius: 0;
}

.feedback-products-modal__close {
  position: fixed;
  top: 20px;
  right: 20px;
  border: none;
  background: none;
}

.feedback-products-modal__close > img {
  width: 34px;
  height: 34px;
}
