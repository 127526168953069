

.store-choose {
  &--modal {
    bottom: 20%;
    position: absolute !important;
    right: 0;
    left: 0;
    max-height: 80%;
  }

  @media (max-device-width: 400px) {

  }

  .modal-content {
    border-radius: 1rem;
  }

  .choose-card {
    background-color: #f7f9fa;
    font-size: 14px;
    cursor: pointer;
    border: 0;

    .small-font {
      font-size: 10px;
      color: #53C95A;
      font-weight: bold;
      text-transform: uppercase;
    }

    .title {
      color: #505050;
    }

    .white-backgroud {
      background-color: #fff;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
    }

    .green-background {
      background-color: #53C95A;
      color: white;
      padding: 5px;
      border-radius: 5px;
      font-size: 12px;
    }

    .custom-button {
      width: 250px;
    }
  }
}

