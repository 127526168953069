.local-heroes {
  background-color: #FFFBEF;
  padding: 16px 0;

  &__title {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    font-weight: 700;
    line-height: 32px;
    color: #000;
  }

  &__description {
    color: #000;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    padding: 0 12px;
  }

  &__content {
    margin: 0 16px;
    scrollbar-width: thin;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }

  &__farmers {
    display: flex;
    flex-wrap: nowrap;
  }

  &__farmer {
    min-width: 180px;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-right: 10px;

    &-name {
      font-family: 'Montserrat', sans-serif;
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 4px;
    }

    &-location {
      font-size: 11px;
      font-weight: 500;
      line-height: 12px;
      text-align: center;
      color: #828282;
      margin-bottom: 4px;
    }

    &-announce {
      max-width: 180px;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      text-align: center;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;

    &-wrapper {
      height: 90px;
      width: 180px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
    }

    &-badge {
      position: relative;
      top: -25px;
      padding: 3px 5px;
      color: #1E1E1E;
      background-color: #FFD458;
      border-radius: 10px;
      font-weight: 600;
      font-size: 9px;
      line-height: 11px;
    }
  }

  &__btn {
    display: block;
    width: 153px;
    padding: 11px;
    gap: 10px;
    border-radius: 16px;
    background: #FFD458;
    color: #0F0F13;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;

    &-wrapper {
      min-width: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }
  }

  &__sponsored {
    color: #8C8C8C;
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    margin-top: 8px;
  }
}

@media screen and (min-width: 1024px) {
  .local-heroes {
    &__description {
      padding: 0;
    }

    &__content {
      overflow-x: hidden;

      flex-direction: column;
    }

    &__farmer {
      min-width: 175px;

      &-announce {
        max-width: 175px;
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;

      &-wrapper {
        width: 175px;
      }
    }

    &__btn {
      width: 340px;
      padding: 18px 0;

      &-wrapper {
        margin-top: 16px;
      }
    }
  }
}
