.rates {
  &-container {
    text-align: center;
  }

  &-title {
    text-align: center;
    font-size: 24px
  }

  &-description {
    text-align: center;
  }

  &-row {
    display: flex;
    overflow-x: auto;
    padding: 0 16px;
    scrollbar-width: none
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    &-content {
      width: 280px;
      height: 150px;
      padding: 12px;
      background: #FCFCFC;
      border-radius: 8px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 8px;
      display: inline-flex;

      &-stars {
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5.14px;
        display: inline-flex
      }

      &-star {
        width: 24px;
        height: 24px;
        position: relative;
        color: #FFD458
      }
    }

  }

  &-date-container {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex
  }

  &-date {
    color: #BDBDBD;
    font-size: 13px;
    font-family: Inter;
    font-weight: 500;
    line-height: 17.70px;
    word-wrap: break-word
  }

  &-comment {
    align-self: stretch;
    color: black;
    font-size: 16px;
    font-family: Inter;
    line-height: 23.15px;
    max-height: 140px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-wrap: break-word
  }

  &-app-download-button-wrapper {
    text-wrap: normal;
    min-width: 180px;
    display: flex;
    flex-flow: column;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &-app-download-button {
    display: flex;
    padding: 11px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 16px;
    background: #FFD458;
    color: #0F0F13;
    text-align: center;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 120% */
  }
}
