
.referral {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  background-color: #fff;
}

.referral-gift {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
  border-radius: 12px;
  background-color: #E7E7FF;
  padding: 24px;
  width: 100%;
}

.referral-gift__img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.referral-gift__img > img {
  width: 160px;
  height: auto;
}

.referral-gift__title {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #333;
}

.referral-gift__text {
  color: #333;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: center;
}

.referral-share {
  display: none;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  border: 2px solid #F5F5F5;
  border-radius: 12px;
  padding: 24px;
}

.referral-share__title {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #333;
}

.referral-share__input {
  position: relative;
  width: 100%;
}

.referral-share__input > input {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  padding: 18px 56px 18px 16px;
  color: #333;
  border: 1px solid #E6E6E6;
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.referral-share__input > button {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  border: none;
  background: none;
}

.referral-share__input > button > svg {
  width: 24%;
  height: 24px;
}

.referral-share__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.referral-share__btn {
  color: #fff;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  background-color: #53C95A;
  border: none;
  border-radius: 16px;
  padding: 16px 0;
  width: 100%;
  cursor: pointer;
}

.referral-share__link {
  color: #53C95A;
  font-family: 'Montserrat';
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
  width: 100%;
}

.referral-statistic {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
}

.referral-statistic__item {
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.referral-statistic__title {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  color: #53C95A;
}

.referral-statistic__text {
  font-family: 'Roboto';
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  color: #333;
}

@media screen and (min-width: 768px) {
  .referral-share {
    display: flex;
  }

  .referral-share__input--mobile {
    display: none;
  }

  .referral-share__btn--mobile {
    display: none;
  }

  .referral-statistic__item {
    border-radius: 12px;
    border: 2px solid #F5F5F5;
  }
}
