
$link-color: #333333;
$zindex-popover: 1060;

.search-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search {
  flex-grow: 1;

  form {
    position: relative;
  }

  input {
    height: inherit;
    background: #F5F5F5;
    border-radius: 10px;
    border: 0;
    outline: none;
    padding: 12px;
    padding-left: 43px;
  }

  button {
    cursor: pointer;
    position: absolute;
    display: inline-block;
    outline: none;
    border: none;
    left: 0;
    top: 13px;
    background: transparent;
    height: inherit;
    padding: 0 .75rem;
    padding-left: 15px;
    color: #828282;

    &:hover {
      color: #666666;
    }
  }

  &-popup {
    z-index: $zindex-popover;
    top: 3.25rem;

    .col--products {
      min-width: 25rem;
    }

    .col--catalogs {
      min-width: 10rem;
    }
  }
}

.fa-search:before {
  color: #4F4F4F;
}

.section {
  &-title, &-links {
    color: $link-color;
  }

  &-links {
    font-size: 0.8125rem;
    line-height: 1.2;
  }
}
