
.filter-v3__title {
  align-items: baseline;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.filter-v3__title-ctrl {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.filter-v3__reset {
  border: none;
  border-bottom: 2px dotted #828282;
  padding: 0;
  color: #828282;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  font-family: Inter, sans-serif;
}

.filter-v3__tree {
  margin: 0;
  padding: 22px 0 0 0;
  list-style: none;
  display: block;
}

.filter-v3__tree-item {
  margin-bottom: 16px;
  color: #333;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.filter-v3__tree-item > div {
  padding: 8px 4px;
  border-radius: 6px;
}

.filter-v3__tree-item[active] > div, .filter-v3__tree-item > div:hover  {
  background-color: #F2F2F280;
  color: #53C95A;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.filter-v3__tree-item img {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 4px;
}

.filter-v3__tree-item-content {
  list-style: none;
  margin: 0;
  padding: 12px 0 0 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.filter-v3__tree-item-content > li {
  color: #828282;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  padding: 4px;
  border-radius: 6px;
}

.filter-v3__tree-item-content > li[active], .filter-v3__tree-item-content > li:hover {
  transition-property: color, background-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  background-color: #F2F2F280;
  color: #53C95A;
}

.filter-v3__checkgroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 12px;
}

.filter-v3__checkgroup label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  position: relative;
}

.filter-v3__checkgroup label > input {
  visibility: hidden;
  height: 0;
  width: 0;
  position: absolute;
}

.filter-v3__checkgroup label > input:checked + div {
  display: none;
}

.filter-v3__checkgroup label > input:not(:checked) + div {
  display: block;
}

.filter-v3__checkgroup label > input:checked ~ img {
  display: block;
}

.filter-v3__checkgroup label > input:not(:checked) ~ img {
  display: none;
}

.filter-v3__checkgroup label > div {
  background-color: #F5F5F5;
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.filter-v3__checkgroup label > img {
  width: 24px;
  height: 24px;
}

.filter-v3__tags-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 12px;
}

.filter-v3__tags-search {
  position: relative;
  margin: 8px 0;
}

.filter-v3__tags-search > input {
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: #101014;
  border: none;
  padding: 10px 0 10px 40px;
}

.filter-v3__tags-search > input::placeholder {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 100%;
  color: #BDBDBD;
}

.filter-v3__tags-search > img {
  position: absolute;
  top: calc(50% - 2px);
  left: 12px;
  transform: translateY(-50%);
  width: 21px;
  height: 21px;
}

.filter-v3__tags-list {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  max-height: 205px;
  overflow-y: scroll;
}

.filter-v3__tags-list div {
  border-radius: 10px;
  background-color: #F5F5F5;
  cursor: pointer;
  font-size: 12px;
  padding: 5px 12px;
  transition: background-color 300ms ease;
  color: #212529;
  width: auto;
}

.filter-v3__tags-list div[active] {
  background-color: #29a630;
  color: #fff;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 300ms ease-in;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.filter-v3__item--togglers {
  display: flex;
  gap: 4px;
  align-items: center;
}

.filter-v3__item-toggler {
  background-color: #F5F5F5;
  border-radius: 16px;
  padding: 0 20px;
  color: #101014;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition-property: color, background-color;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.filter-v3__item-toggler[active] {
  background-color: #53C95A;
  color: #fff;
}
