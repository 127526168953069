
.filter-v3 {
  display: flex;
  flex-direction: column;
}

.filter-v3__item {
  margin-bottom: 40px;
}

.filter-v3__item:has(.filter-v3__item--togglers) {
  margin-bottom: 20px;
}

.filter-v3__item--togglers {
  display: flex;
  gap: 4px;
  align-items: center;
}

.filter-v3__title {
  align-items: baseline;
  font-size: 16px;
  line-height: 20px;
  font-family: "Inter", sans-serif;
  color: #333333;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.filter-v3__title-ctrl {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.filter-v3__reset {
  border: none;
  border-bottom: 2px dotted #828282;
  padding: 0;
  color: #828282;
  font-size: 14px;
  background-color: transparent;
  cursor: pointer;
  font-family: Inter, sans-serif;
}
