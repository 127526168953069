
.zip-modal {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  display: flex;
  flex-direction: row;
  background: white;
  border-radius: 16px;
  overflow: hidden;
  max-width: 900px;
  width: 100%;
  height: 520px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.modal-image {
  flex: 1;
  height: 100%;
  background: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.modal-form {
  flex: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.modal-header .logo {
  height: 40px;
}

.close-button {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
}

.description {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.zip-input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.submit-button {
  padding: 12px;
  background: #d4f1d7;
  color: #333;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;
}

.submit-button:disabled {
  background: #f0f0f0;
  cursor: not-allowed;
}

.submit-button:not(:disabled):hover {
  background: #b7e4bc;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 14px;
}

.email-input {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.another-zip {
  margin-top: 16px;
  font-size: 14px;
  color: #007bff;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}
.another-zip:hover {
  color: #0056b3;
}

.delivery-days {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin: 20px 0;
}

.day-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.day-indicator {
  width: 16px;
  height: 16px;
  background: #ccc;
  border-radius: 50%;
  margin-bottom: 8px;
  transition: background 0.3s;
}

.day-indicator.active {
  background: #4caf50;
}

.delivery-day {
  font-size: 14px;
  color: #555;
}

/* --- Адаптивность --- */
@media screen and (max-width: 768px) {
  .modal-content {
    flex-direction: column; /* Вертикальное расположение контента */
    max-height: 90vh; /* Ограничиваем высоту модалки */
    height: auto; /* Динамическая высота */
  }

  .modal-image {
    height: 200px; /* Увеличиваем высоту изображения для планшетов */
    flex: 0 0 auto; /* Фиксированная высота */
    overflow: hidden; /* Обрезаем лишнее содержимое */
  }

  .modal-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Масштабирование с сохранением пропорций */
  }

  .modal-form {
    padding: 24px; /* Умеренные отступы для планшетов */
    flex: 1; /* Растягиваем форму на оставшееся пространство */
    overflow-y: auto; /* Добавляем прокрутку, если контент большой */
  }

  .title {
    font-size: 22px; /* Умеренный размер заголовка */
    margin-bottom: 12px;
  }

  .description {
    font-size: 15px; /* Уменьшаем описание */
    margin-bottom: 18px;
  }

  .zip-input {
    padding: 12px; /* Удобный размер ввода */
    font-size: 15px;
  }

  .submit-button {
    padding: 12px; /* Удобная кнопка для планшетов */
    font-size: 15px;
  }

  .close-button {
    font-size: 22px; /* Подгоняем кнопку закрытия */
    top: 12px;
    right: 12px;
  }
}


@media screen and (max-width: 480px) {
  .modal-header {
    display:none
  }
  .modal-content {
    flex-direction: column; /* Вертикальное расположение */
    max-height: 100%;
    height: 100%; /* Динамическая высота */
  }

  .modal-image {
    height: 20%; /* Фиксированная высота для мобильных */
    flex: 0 0 auto; /* Отключаем гибкость */
    overflow: hidden; /* Обрезаем лишнюю часть */
  }

  .modal-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Картинка заполняет контейнер с обрезкой */
  }

  .modal-form {
    padding: 16px; /* Уменьшаем отступы */
    flex: 1;
    overflow-y: auto; /* Добавляем прокрутку, если контент превышает экран */
  }

  .close-button {
    top: 10px;
    right: 10px;
    font-size: 20px; /* Уменьшаем размер кнопки закрытия */
  }

  .title {
    font-size: 18px; /* Компактный заголовок */
    margin-bottom: 10px;
  }

  .description {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .zip-input {
    font-size: 14px;
    padding: 10px;
  }

  .submit-button {
    font-size: 14px;
    padding: 10px;
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

