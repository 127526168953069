
vm-playback-control {
  --vm-control-scale: 4;
}

vm-player {
  --vm-player-border-radius: 0.8rem
}

vm-icon, vm-playback-control, vm-control {
  --vm-icon-fill: #fff;
  --vm-icon-stroke: #fff;
}
