.mobile-app-banner {
  font-family: Montserrat, Sans-Serif;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999999999999;
  background-color: $background-white;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .logo {
    padding: 2rem;
    img {
      height: 67px;
    }
  }

  .banner-image {
    position: relative;
    width: 10rem;
    border-radius: 1rem;
    height: 10rem;
    margin: 0 auto;
    background-color: $grey20;
    background-position: center center;
    background-size: 95%;
    background-repeat: no-repeat;
    transition: background-size .15s ease-in-out;

    &:hover {
      background-size: 100%;
    }
  }
}

.catalog-banner {
  @include respond-below(lg) {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
}

.catalog {
  .banner-item {
    width: 100%;

    &-desktop {
      margin-bottom: .5rem;
      margin-top: .5rem;

      img {
        width: 0;
        height: 0;
      }

      & .banner-item-box {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
      }
    }

    &-box {

      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      padding-left: 15px;
      padding-right: 15px;

      height: 100%;

      display: block;
    }

    &-mobile {
      img {
        width: 100%;
      }
    }
  }
}

.alco-warning {
  margin-top: 20px;
  background: $secondary30;
  border-radius: 4px;
  color: $main-text-color;

  @include respond-below(lg) {
    border-radius: 0px;
  }

  &-text {
    padding: 20px;
    margin-left: 10%;
    margin-right: 10%;

    @include respond-below(lg) {
      font-size: 11px;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &-header {
    font-size: 19px;
    line-height: 110%;
    margin-bottom: 10px;

    @include respond-below(lg) {
      font-size: 15px;
    }
  }
}

.referral-banner-top {
  background: $secondary20;
  height: 40px;
  padding-top: 3px;

  a {
    font-size: 21px;
    line-height: 25px;
    color: $main-text-color;

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $main-text-color;
    }
  }
}

div[is="main-banner"] {
  .slider-item {
    height: 100%;
  }
}

div[is="main-banner"], .main-banner {
  @include respond-below(md) {
    .carousel-btn {
      display: none;
    }
  }
}

.main-banner {
  position: relative;
  overflow: hidden;

  .swiper-container {
    width: 100%;
  }

  .slider-item {
    display: block;
    overflow: hidden;
    border-radius: .8rem;
    width: 100%;
    position: relative;
  }

  .swiper-slide {
    img {
      width: 100%;
    }
  }

  .carousel-btn {
    position: absolute;
    top: 47%;
    z-index: 100;
    background-color: $background-white;

    &:hover {
      background-color: $background-grey30;
    }

    left: 1.5rem;
    right: auto;

    & + .carousel-btn {
      left: auto;
      right: 1.5rem;
    }
  }

  .swiper-pagination {
    @include respond-below(sm) {
      &-bullets {
        bottom: 0;
      }
    }
    &-bullet {
      height: 10px;
      width: 10px;
      background-color: $background-white;
      opacity: 0.5;
      &-active {
        opacity: 1;
      }
    }
  }
}


/** ReferralTopBannerPinemelon */
.referral-top-pinemelon {
  background-color: $secondary20;
  display: block;
  height: 45px;

  &__wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    font-weight: bold;
    color: $main-text-color;
    margin: 0 1.3rem 0 0;
    font-size: 1.2rem;
  }

  &__img {
    height: 100%;
  }
}

@media (max-width: 992px) {
  .referral-top-pinemelon {
    height: 40px;

    &__title {
      font-size: 1rem;
      margin-left: 1rem;
    }
  }
}
/** ReferralTopBannerPinemelon end */
