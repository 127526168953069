@import "../mixin/respond";

$express-popver: #FE530F;

.main-header {
  max-width: 1450px;
  margin: 0 auto;

  .navbar-brand {
    position: relative;
  }

  .badge-corporate {
    position: absolute;
    right: 25px;
    bottom: -15px;
  }
}

.header {
  padding-left: 15px !important;
  padding-right: 15px !important;

  .border-btm {
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  }
  .nav {
    margin-bottom: .5rem;
    margin-top: .5rem;
    font-size: 90%;

    .nav-link {
      padding: .25rem;
      font-weight: normal;
      color: $grey;

      &:hover {
        color: $grey40;
        text-decoration: underline;
      }
    }

    @include respond-below(xl) {
      &.menu-nav {
        font-size: 75%;
      }
    }
  }

  .auth {
    .nav {
      margin-left: .5rem;
      @media (max-width: 1255px) {
        font-size: 80%;
      }
    }

    .nav-link {
      color: $green;
      padding: .25rem .5rem;
      font-size: .8rem;
      line-height: 1.2rem;

      &:hover {
        color: $green70;
      }
    }
  }

  .menu {
    .nav-link {
      color: $grey;
    }
  }

  .auth {
    .nav-link {
      color: $green;
    }
  }

  .v-popover {
    display: flex;
    align-items: center;

    cursor: pointer;

    font-size: .8rem;

    .fa-clock {
      color: $green40;
    }

    & > .trigger {
      display: flex !important;
      align-items: center;
      height: 100%;
      flex-direction: column;
    }

    &.express {
      color: $text-color-white;
      cursor: pointer;
      font-size: .8rem;
      background: $orange;

      img {
        margin-right: 6px;
      }

      &.disabled {
        background: $background-grey40;
      }
    }

    &.delivery {
      color: $grey;

      &.open {
        background-color: $background-grey10;
      }
    }

    .popover-target {
      padding-left: .5rem;
      padding-right: .5rem;
      display: flex;
      align-items: center;
      flex-grow: 1;
      position: relative;
      .sleep-icon {
        position: absolute;
        left: 25px;
        top: 8px;
        width: 10px;
      }
    }
  }

  .subheader {
    border-width: 1px 0 0 0;
    border-color: $grey30;
    border-style: solid;
    padding: .5rem 0 0 0;
    @include respond-below(lg) {
      padding: 0;
      border: 0;
      height: 48px;
    }

    .drawer-button {
      font-size: 24px;
      color: $green;
      margin-top: 6px;
    }
  }

  .header-logo {
    a {
      color: $main-text-color;
      text-decoration: none;
    }

    img {
      width: 165px;

      @include respond-below(md) {
        width: 110px;
        display: block;
        margin-top: 8px;
      }
    }

    span {
      font-size: 1rem;
      letter-spacing: 0.04rem;
      text-transform: lowercase;
      font-weight: 100;
      color: $grey;

      @include respond-below(md) {
        font-size: .675rem;
      }
    }

    .pinemelon-logo {
      width: 110px;
      padding-bottom: 16px;

      @include respond-below(md) {
        width: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 8px;
      }
    }
  }

  .contacts {
    font-size: .85rem;

    .title {

    }

    .phones {
    }

    @media (max-width: 1310px) {
      font-size: .8rem;
    }
    @media (max-width: 1225px) {
      font-size: .75rem;
    }
    @media (max-width: 1070px) {
      font-size: .675rem;
    }
  }

  &-address {
    .btn-link {
      color: $green;
      font-size: 85%;
    }
  }
}


.popover {
  transition: opacity .15s, visibility .15s;

  &[aria-hidden='true'] {
    opacity: 0;
  }

  &[aria-hidden='false'] {
    opacity: 1;
  }

  .popover-body {
    .popover-close {
      float: right;
      margin-left: .5rem;
    }
  }

  &.delivery-popover, &.express-popover {
    @media(max-width: 992px) {
      display: none !important;
    }

    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;

    font-size: 80%;

    border: 0;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
    -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);

  }

  .black {
    color: $contrast;
  }

  &.express-popover {
    .btn {
      background: $express-popver;
    }

    .popover-body {
      padding: 0.75rem 1rem;
    }
  }
}

.express-book {
  border-top: 1px solid $grey30;
  p {
    color: $express-popver;
    @media (max-width: 385px) {
      font-size: 80%;
    }
  }
  a {
    text-decoration: underline;
    font-size: 80%;
    @media (max-width: 385px) {
      font-size: 70%;
    }
  }
  .btn {
    background: $express-popver;
    color: $text-color-white;
    border-radius: 25px;
    max-width: 100%;
    @media (max-width: 385px) {
      font-size: 80%;
    }
    @media (max-width: 330px) {
      font-size: 70%;
    }
  }
  &.booked {
    background: $express-popver;
    a, p {
      color: $text-color-white;
    }
    .btn {
      background: $background-white;
      color: $express-popver;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
  .not-available {
    font-size: 75%;
    &.not-tiny {
      @media (max-width: 375px) {
        font-size: 60%;
      }
    }
    &.tiny {
      @media (max-width: 375px) {
        font-size: 50%;
      }
      @media (max-width: 340px) {
        font-size: 40%;
      }
    }
  }

  .express-text {
    line-height: normal;
  }
  .express-text-time {
    font-variant-numeric: tabular-nums;
  }
}

.header-cart {
  background-color: $green !important;
  padding: 12px 15px;
  color: $text-color-white !important;
  border-radius: 10px;
  display: flex;
  align-items:center;
  cursor: pointer;
  &__icon {
    height: 20px;
    width: auto;
    margin-right: 6px;
  }
  &:hover {
    background-color: $btn-hover-green !important;
  }
}

@media (max-width: 767px) {
  .header-cart {
    padding: 8px 15px;
  }
}

.header-store {
  min-width: 235px;
  color: #4f4f4f;
  font-size: 0.8rem;
  display: flex;
  margin: 0 10px;
  padding: 8px 10px;
  border-radius: 10px;
  line-height: 1.4;
  align-items: center;

  &__icon {
    margin-left: 5px;
    margin-right: 10px;
    display: none;
  }

  @include respond-below(lg) {
    font-size: 0.7rem;
    justify-content: center;
    align-items: center;

    &__icon {
      margin:0;
      margin-right: 0px;
      height: 16px;
    }
  }

  &__pages {
    overflow: hidden;
  }

  &__page {
    display: flex;
    flex-direction: column;
    gap: 2px;
    transition: margin-top .5s ease-in-out;
  }
}

.header-store:hover {
  background-color: #F6FCF7;
  color: black;
}

@media screen and (min-width: 1024px) {
  .header__store {
    &__icon {
      display: block;
    }
  }
}

.become-partner  {
  display: none;
}

@media screen and (min-width: 768px) {
  .become-partner {
    display: block;
    color: #37AF3E;
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-wrap: nowrap;
    margin-right: 22px;
    border: none;
    background: none;
  }
}

.main-header .join-melonclub {
  white-space: nowrap;
  margin: 0 6px;
  color: #000;
}
