@import "../mixin/respond";
@import "../mixin/color";

.side-nav-pills {
  background: $background-grey20;
  border-radius: 2px;
  color: $main-text-color;
}

.auth-btn {
  @include respond-below(sm) {
    border-radius: 10px;
  }
}

.article-nav.side-nav-pills {
  .nav-link.active {
    color: $main-text-color;
  }
}

.divider {
  height: 16px;
  background-color: $background-grey10;
  border-top: 0;
  @include respond-above(lg) {
    display: none;
  }
}

.full-width {
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
}

.full-width-below-lg {
  @include respond-below(lg) {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.full-width-below-md {
  @include respond-below(md) {
    width: calc(100% + 30px);
    margin-left: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.closest-delivery {
  .fa-clock {
    color: $green40;
    font-size: 32px;
  }
  @media (max-width: 385px) {
    a, span {
      font-size: 80%;
    }
  }
}

.digit {
  font-size: 150%;
  font-weight: lighter;
}

.catalog-btn-wrapper {
  background-color: $background-grey10;
  .btn {
    background: $background-white;
    width: 200px;
    border-radius: 20px;
  }
}

.clickable {
  cursor: pointer;
}

.breadcrumb {
  margin-bottom: 0;
  background-color: transparent;

  font-size: 13px;
  font-weight: 700;

  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.25rem;
    color: $grey;
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.25rem;
    color: $grey;
  }
  @include respond-below(lg) {
    font-size: 75%;
    background-color: $background-grey20;
    padding: 0.3rem 1rem;
  }
}

.form-radio-label > .d-block {
  margin-top: -.4em;
}

[type="radio"],
.form-radio-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

[type="radio"] + label:before,
.form-radio-input + .form-radio-label:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 14px;
  vertical-align: -0.10em;
  border-radius: 1em;
  border: 2px solid $background-white;
  box-shadow: 0 0 0 0.1em $radio-default;
  transition: 0.25s ease all;
  margin-bottom: 2px;
}

[type="radio"]:checked + label:before,
.form-radio-input:checked + .form-radio-label:before {
  background: $radio-checked;
  box-shadow: 0 0 0 0.1em $radio-checked;
}
[type="radio"]:disabled + label:before {
  box-shadow: 0 0 0 0.1em $radio-disabled;
}
[type="radio"]:disabled:checked + label:before {
  background: $radio-disabled;
}

.page-text {
  &-free-delivery {
    background: $secondary20;
    border-radius: 2px;
  }
  &-delivery-week {
    .day-content {
      font-size: 80%;
      background-color: #EBF3FC;
      &.none {
        background-color: $background-grey20;
        height: 54px;
      }
    }
  }
  &-description-content {
    border-radius: 2px;
    &.yellow {
      background: #F6F7D0;
    }
    &.orange {
      background: #FAEFDE;
    }
    &.blue {
      background: #EBF3FC;
    }
  }
  &-description-disc {
    color: $grey80;
  }
  &-pricing {
    background: #EBF3FC;
    border-radius: 2px;
  }
}

.user-profile {
  color: $main-text-color;
  .btn-primary {
    background-color: $background-green;
    border-color: $green;
  }
  & .nav-link.active {
    color: $main-text-color;
  }
}

.download-apps {
  background: $background-grey20;
}

.banner-divider {
  height: 10px;
  width: 100%;
  background: $background-grey20;
}

.rate-success {
  &-title {
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    color: $contrast;
  }
  &-subtitle {
    font-size: 16px;
    line-height: 19px;
    color: $contrast;
    @media (max-width: 355px) {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
