
.autocomplete-input-group {
  display: flex;
  flex-direction: row;

  &.form-group--floating {
    > label {
      z-index: 3;
      padding-left: 48px;
    }
  }
}

.autocomplete-input-button {
  margin-left: .25rem;
}

.autocomplete-input {
  background-color: #ffffff;
  border: 1px solid #ced4da;

  padding: 12px 12px 12px 48px;
  padding-left: 48px !important;
  z-index: 2;

  &[aria-expanded="true"] {
    border-radius: 0.25rem !important;
    border-color: #80bdff !important;

    &.is-invalid {
      border-color: $red !important;
    }
  }
}

[data-position="below"] .autocomplete-result-list {
  margin-top: 0;
}
