.font-roboto {
  font-family: Roboto, "sans-serif";
}

.font-montserrat {
  font-family: Montserrat, "sans-serif";
}

.font-inter {
  font-family: Inter, "sans-serif";
}

.wf::before {
  vertical-align: text-bottom !important;
}
