
.modal-small-batch__wrapper {
  padding-top: 20px;
}

.modal-small-batch__content {
  max-width: 375px;
}

.modal-small-batch__icon {
  margin-bottom: 16px !important;
}

.modal-small-batch__close {
  top: 20px;
  background-color: #F5F5F5;
  border-radius: 100%;
  padding: 8px;
  width: 14px;
  height: 14px;
  box-sizing: content-box;
}
