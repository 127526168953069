@import "../mixin/color";

.heading-nav {
  .nav {
    width: 100%;
    background: $background-white;
    border: 1px solid $grey30;
    border-radius: 2px;
    padding: 0;

    .nav-item {

      flex-grow: 1;
      text-align: center;

      &:hover, &.show {
        .nav-link {
          background: $background-grey10;
          border-color: transparent $grey30;
        }
      }

      .nav-link {
        border-width: 0 1px;
        border-color: transparent;
        border-style: solid;
        font-size: 1rem;
        color: $main-text-color;
        padding: 0.5rem 0.3rem;
      }

      &:first-of-type {
        .nav-link {
          &:hover {
            border-left-color: transparent;
          }
        }
      }

      &:last-of-type {
        .nav-link {
          &:hover {
            border-right-color: transparent;
          }
        }
      }

      .dropdown-menu.show {
        width: 85%;
        left: 7.5%;
        background: $background-grey10;
        border: 1px solid$grey30;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
        border-radius: 2px;
        margin-top: 0;

        .item {
          margin-bottom: .5rem;
        }

        .dropdown-item {
          height: 100%;

          background: $background-white;
          border: 1px solid $grey30;
          border-radius: 2px;
          text-decoration: none;
          color: $main-text-color;
          padding: 0.25rem 1rem;
          white-space: pre-line;

          &:hover {
            border: 1px solid $green;
          }

          span {
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
          }
        }
      }
    }

    .dropdown {
      position: static;
    }
  }
}


.site-description {
  color: $main-text-color;
  &__img {
    background-image: url('../assets/banners/landing/promo_velo.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 18px;
  }
}

.nav-menu-line {
  flex-wrap: nowrap;

  .nav-item {
    display: flex;
    flex-shrink: 1;
    width: auto;
  }

  .nav-link {
    font-family: Montserrat, "sans-serif";
    color: $grey90;
    font-weight: 700;
    white-space: nowrap;

    &:hover, &:focus {
      color: $green;
    }

    &-icon {
      display: inline-block;
      height: 20px;
      padding-left: .5rem;
      vertical-align: top;

      img {
        height: 100%;
        vertical-align: inherit;
      }
    }
  }
}
