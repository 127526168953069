$br-0: 0px;
$br-1: 10px;
$br-2: 16px;
$br-3: 20px;

$brs: (
  none: 0px,
  sm: 10px,
  md: 16px,
  lg: 20px
);

$radius-types: (
  l: (
    "top-left",
    "bottom-left"
  ),
  r: (
    "top-right",
    "bottom-right"
  ),
  t: (
    "top-left",
    "top-right",
  ),
  b: (
    "bottom-left",
    "bottom-right",
  ),
  tl: (
    "top-left",
  ),
  tr: (
    "top-right",
  ),
  bl: (
    "bottom-left",
  ),
  br: (
    "bottom-right",
  ),
);
