.featured {
  &__title {
    position: absolute;
    color: $text-color-white !important;
    font-family: "Montserrat", sans-serif;
    font-style: italic;
    font-weight: 700 !important;
    font-size: 1.6rem;
    width: 12rem;
    left: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }

  &__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__img {
    max-height: 100%;
    min-width: 100%;
    object-fit: cover;
    vertical-align: bottom;
    border-radius: 0.8rem;
    width: 100%;
  }

  &__item {
    position: relative;
    flex: 1;
    margin-right: 0.8rem;
  }

  &__item:last-child {
    margin-right: 0;
  }
}

@media (max-width: 1200px) {
  .featured {
    &__title {
      font-size: 1.2rem;
      left: 1rem;
    }
  }
}

@media (max-width: 700px) {
  .featured {
    &__title {
      font-size: 1.5rem;
      left: 1.2rem;
      width: 13rem;
    }

    &__list {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &__item {
      margin: 0;
      flex: none;
      width: 100%;
    }
  }
}
