
.product-item-popup {
  font-size: 0.8125rem;

  a {
    color: #333333;
  }

  b {
    color: #000;
  }

  .cart-btn {
    width: 64px;
  }
}
