
.fadeHeight-enter-active,
.fadeHeight-leave-active {
  transition: opacity .5s ease-in-out, max-height .5s ease-in-out;
  max-height: 230px;
  overflow: hidden;
}

.fadeHeight-enter,
.fadeHeight-leave-to {
  opacity: 0;
  max-height: 0;
}

.slide-fade-left-enter-active, .slide-fade-left-leave-active {
  transition: all .4s ease;
}

.slide-fade-left-enter, .slide-fade-left-leave-to {
  transform: translateX(-100%);
}

.slide-fade-right-enter-active {
  transition: all .4s ease .4s;
  display: block;
}

.slide-fade-right-leave-active {
  transition: all .4s ease .4s;
  display: none;
}

.slide-fade-right-enter, .slide-fade-right-leave-to {
  transform: translateX(100%);
}

@mixin animate-opacity() {
  transition: opacity .3s ease;
}

.flip-list-move {
  transition: transform 0.8s ease-in-out;
}

.flip-list-enter-active, .flip-list-leave-active {
  transition: all .8s ease-in-out;
}

.flip-list-leave-to, .flip-list-enter {
  max-height: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 0;
  overflow: hidden;
}



.width-animated {
  @include transition(width .5s, padding .5s, border .5s);

  overflow: hidden;

  &-leave {
    width: 0;
    padding: 0;
    border: 0;
  }

  &-enter {
    width: auto;
  }

  &.fa {
    vertical-align: middle;
    font-size: 1rem;
    display: inline-block;
  }

  &-enter.fa {
    padding: .025rem;
  }
}

/**
 * Анимация ротации на 2 оборота с последующим ожиданием
 */
@keyframes rotate-4 {
  5% {
    transform: rotate(-20deg);
  }
  20% {
    transform: rotate(740deg);
  }
  25% {
    transform: rotate(720deg);
  }
  100% {
    transform: rotate(720deg);
  }
}

.rotate-4 {
  animation: rotate-4 4s infinite ease-in-out;
}

/* Animation un-fade from bottom (usually on scroll) */
.reveal {
  position: relative;
  transform: translateY(200px);
  opacity: 0;
  transition: .75s all ease;
}

.reveal-left {
  transform: translateX(-300px);
}

.reveal-right {
  transform: translateX(300px);
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
