
.first-order-wrapper {
  z-index: 999;
  display: block;
  position: fixed;
  left: 20px;
  bottom: 20px;

  .fa-popup {
    width: 90px;
    height: 90px;
    background: #53C95A;
    border-radius: 4px;
    align-items: center;
    justify-content: center;
    display: flex;
    @media (max-width: 992px) {
      width: 60px;
      height: 60px;
    }

    img {
      width: 36px;
      height: 38px;
      @media (max-width: 992px) {
        width: 24px;
        height: 26px;
      }
    }
  }
}
