
.accordion {
  color: #333;
}

.accordion-header {
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  margin-bottom: 10px;
  background: none;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  outline: none;
  padding: 0;
  text-align: left;
  color: #000;
  text-wrap: balance;
}

.accordion-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.accordion-icon {
  font-size: 14px;
  transition: 0.3s all ease-in;
}

.accordion-icon--btn {
  transform: rotate(270deg);
  font-size: 14px;
}

.accordion-icon--active {
  transform: rotate(180deg);
}
