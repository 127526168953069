@import "@fortawesome/fontawesome-free/css/all.min.css";
@import "@fortawesome/fontawesome-free/css/v4-shims.min.css";

@import "ionicons/dist/css/ionicons.css";
@import "@site/bootstrap/scss/bootstrap";
//@import "swiper/dist/css/swiper.css";
@import "swiper/swiper-bundle.css";
@import "@trevoreyre/autocomplete-vue/dist/style.css";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import "eonasdan-bootstrap-datetimepicker/build/css/bootstrap-datetimepicker.min.css";

@import "./mixin/respond";
@import "./partial/fonts";
@import "./partial/grid";
@import "./partial/animations";
@import "./partial/auth";
@import "./partial/banners";
@import "./partial/card";
@import "./partial/cart";
@import "./partial/catalog";
@import "./partial/checkout";
@import "./partial/datetimepicker";
@import "./partial/drawer";
@import "./partial/footer";
@import "./partial/farmers";
@import "./partial/forms";
@import "./partial/filters";
@import "./partial/geobox";
@import "./partial/giftcard";
@import "./partial/header";
@import "./partial/heading-nav";
@import "./partial/landing";
@import "./partial/labels";
@import "./partial/modal";
@import "./partial/panel";
@import "./partial/popup";
@import "./partial/products";
@import "./partial/promotion";
@import "./partial/publication";
@import "./partial/sides";
@import "./partial/tags";
@import "./partial/user";
@import "./partial/advantage-banner";
@import "./partial/address-autocomplete";
@import "./partial/elements";
@import "./partial/featured-collections";
@import "./partial/sliders";
@import "./partial/quantity-control";
@import "./partial/action-cart";
@import "./partial/tooltip";
@import "./partial/menu";
@import "./partial/web-components";
@import "./partial/mission";
@import "./partial/local-heroes";
@import "./partial/new-makers";
@import "./partial/order-rates";
@import "./partial/makers-portal";
@import "./partial/storefront.scss";

html {
  position: relative;
  min-height: 100%;
}

body {
  font-family: Inter, "sans-serif";
  font-size: 16px;
}

h1, h2, h3, h4, .h1, .h2, .h3, .h4 {
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
}

h1, .h1 {
  font-size: 30px;
}

h2, .h2 {
  font-size: 24px;
}

h3, .h3 {
  font-size: 21px;
}

h4, .h4 {
  font-size: 16px;
}

.content-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;

  position: relative;
  z-index: 150;

  @include respond-below(lg) {
    top: 0 !important;
  }
}

a {
  color: $green;

  &:hover {
    text-decoration: none !important;
    color: $green70;
  }
}

.lighter {
  font-weight: lighter;
}

.border-green {
  border-color: $green;
}

.nav-pills .nav-link.active {
  background-color: $grey30;
  color: $green;
}

.page-item.active .page-link {
  background-color: $background-green;
  border-color: $green;
}

.page-link {
  color: $green;
}

.link {
  color: $green;
  text-decoration: $link-decoration;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  cursor: pointer;

  @include hover {
    color: $green70;
    text-decoration: $link-hover-decoration;
  }
}

.main-info {
  background: $background-grey10;
  @include respond-below(lg) {
    background: $background-white;
  }
  @media (max-width: 365px) {
    .col-3 {
      padding-left: 8px !important;
      padding-right: 8px !important;
    }
  }

  .advantage-text {
    font-size: 21px;
    line-height: 24px;
    color: $green;
    @include respond-below(lg) {
      color: $grey;
      font-size: 12px;
      line-height: 14px;
    }
    @media (max-width: 340px) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  .advantage-img {
    min-height: 70px;
    @media (max-width: 355px) {
      min-height: 50px;
      img {
        max-width: 75%;
      }
      .img-payment {
        height: 50px;
      }
    }
  }

  .advantage-bonus-label {
    position: absolute;
    right: 15px;
    top: 0;
    z-index: 5;
    border-radius: 50%;
    background: $background-green;
    color: $text-color-white;
    width: 42px;
    font-size: 18px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    vertical-align: middle;
    @include respond-below(sm) {
      width: 21px;
      height: 21px;
      font-size: 10px;
      line-height: 21px;
    }
  }
}

.reviews {
  &-item {
    background: $background-grey10;
    border-radius: 4px;
    color: $grey80;
    line-height: 1.4;
    padding: 1rem;
    @media (max-width: 992px) {
      margin-bottom: .5rem;
    }
  }

  &-user {
    text-align: right;
    color: $grey80;
    margin-top: .75rem;
    font-weight: bolder;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: .5rem;
    }
  }
}

article.content {
  & > .description > p > img {
    max-width: 100%;
    height: auto !important;
  }
}

.content {
  &.catalog {
    .nav {
      &-item {
        padding: .5rem;
      }

      &-link {
        min-height: 4rem;
        text-align: center;
        width: 100%;
        color: $contrast;

        &:hover {
          color: $green;
        }
      }

      &-text {
        margin: auto;
      }
    }
  }
}

.background-darken {
  background: rgba(0, 0, 0, 0.3);
}

.price--wrapper {
  .price--sign.price--prefix {
    margin-right: 0;
  }

  .price--sign.price--suffix {
    margin-left: .25em;
  }
}

.darken-content {
  section.content {
    @include darken-content;
  }
  footer.footer {
    @include darken-content;
    top: -1px; // footer top border width
  }
}

.wrapper-header {
  //border-bottom: 1px solid $grey20;
  // always show on scroll
  position: sticky;
  top: 0;
  z-index: 200;
  background: $background-white;
  // end

}

.main-header {
  max-width: 1450px;
  margin: 0 auto;
}

.main-section {
  padding-top: 0.1px; // needs to include margin of first child element into bounding box
  padding-bottom: 3rem;
  position: relative;
  z-index: 199;
}

.saturation {
  opacity: 0.5;
  filter: saturate(0%);
  -webkit-filter: saturate(0%);
  transition: all .3s ease;
  &:hover {
    opacity: 1;
    filter: saturate(100%);
    -webkit-filter: saturate(100%);
  }
}

.vue-slider-process {
  background-color: $green;
  background-color: #D4F2D6;
  height: 5px !important;
}

.vue-slider-dot-handle {
  background-color: $green;
  box-shadow: none;
}

.vue-slider-dot {
  width: 23px !important;
  height: 23px !important;
}
.vue-slider-dot-tooltip-inner {
  border: none;
  background-color: $green;
}

.vue-slider-dot-tooltip-inner {
  font-size: 13px;
}

.vue-lottie-player {
  background: transparent !important;
}

.faq {
  .faq--item {
    font-size: 18px;
    font-style: italic;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .faq--header {
  }

  @media (max-width: 768px) {
    .faq--item {
      font-size: 14px;
      font-style: italic;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .faq--header {
      text-align: center;
    }
  }
}

@media screen and (min-width: 768px) {
  .hide-desktop {
    display: none;
  }
}

.hide-mobile {
  display: none !important;
}

@media screen and (min-width: 768px) {
  .hide-mobile {
    display: block !important;
  }
}

.checkbox-lg
{
  width: 1.5em;
  height: 1.5em;
}

.merchant-table-header {
  .form-group > label {
    font-weight: bold;
  }

  > div {
    display: inline-block;
  }
}
