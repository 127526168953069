@import "../mixin/respond";

$footer-height: 156px;

.layout-boxed > main {
  flex: 1 0 auto;
}

.layout-panel > main {
  flex: 1 0 auto;
}

html, body, #layout {
  height: 100%;
}

#layout {
  display: flex;
  flex-direction: column;
}

.footer {
  width: 100%;
  z-index: 69;
  flex-shrink: 0;
  position: relative;
  bottom: 0;
  padding-top: 2rem;
  border-top: 1px solid $grey30;
  color: $grey;
  background-color: $background-grey20;
  font-size: 14px;
  line-height: 20px;
  &-wrapper {
    max-width: 1450px;
    margin: 0 auto;
    padding: 0 20px;
    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .logo {
        margin-bottom: 18px;
      }
      &-main {
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      .main {
        &-left {
          width: 32%;
        }
        &-center {
          width: 50%;
          display: flex;
          flex-direction: column;
        }
        &-right {
          width: 18%;
          display: flex;
          justify-content: flex-end;
        }
      }
      & .info {
        &-contacts {
          &-city, &-phone, &-email {
            margin-bottom: 2px;
            a {
              white-space: nowrap;
              color: $green !important;
            }
          }
          &-city {
            font-size: 12px;
          }
          &-email {
            margin-top: 12px;
          }
        }
      }

      & .social {
        &-icons {
          display: flex;
          flex-direction: row;
          &-link {
            margin-left: 20px;
            & i {
              font-size: 28px;
            }
            .fa-facebook-square {
              color: #4267B2;
            }
            .fa-instagram-square {
              background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            .fa-whatsapp-square {
              color: #25D366;
            }
            .fa-linkedin {
              color: #0072b1;
            }
          }
        }
      }
    }

    & .download_app {
      &-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        &-content {
          margin-right: 1vw;
        }
      }
    }
    & .copyright {
      margin-top: 16px;
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
      border-top: 1px solid $grey30;
    }
  }

  .footer-nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 60px;
    width: 85%;
    margin-bottom: 10px;
    .nav-item {
      padding-right: 10px;
      .nav-link {
        padding: 0;
        padding-bottom: 10px;
      }
      a {
        color: $grey;
        &:hover {
          color: $green;
        }
      }
    }
  }
}

@include respond-below(lg) {
  .footer-wrapper {
    padding: 0 16px;
    &-content {
      .main {
        &-left {
          width: 25%;
        }
        &-center {
          width: 55%;
          height: auto;
          gap: 0;
        }
        &-right {
          justify-content: center;
        }
      }
      .social {
        &-icons {
          flex-direction: column;
          &-link {
            margin-left: 0;
            margin-bottom: auto;
          }
        }
      }
    }
  }

  .footer .footer-nav .nav-item {
    padding-right: 28px;
  }

}

@include respond-below(md) {
  .footer {
    &-wrapper {
      padding: 0;
      margin-left: 28px;
      &-content {
        flex-direction: column;
        &-logo {
          margin-bottom: 20px;
        }
        &-main {
          flex-direction: column;
        }
        .main {
          &-left {
            margin-bottom: 24px;
            width: 80%;
            address {
              margin-bottom: 0;
            }
          }
          &-center {
            width: 100%;
            .footer-nav {
              width: 100%;
              margin-bottom: 22px !important;
              display: grid;
              grid-template-columns: 1fr 1fr;

              &-item {
                font-size: 14px;
                padding-bottom: 6px;
              }
            }
            .download_app {
              margin-bottom: 24px;
            }
          }
          &-right {
            justify-content: flex-start;
          }
        }
        .social {
          margin-bottom: 0;
          &-icons {
            flex-direction: row;
            &-link {
              margin-right: 8vw;
              i {
                font-size: 44px;
              }
            }
          }
        }
        &-info {
          font-size: 14px
        }
      }
    }

    .download_app-wrapper-content {
      margin-right: 3vw;
    }
    .info-contacts-city, .info-contacts-phone {
      font-size: 14px
    }
    .pb-50 {
      padding-bottom: 50px;
    }
  }
}

@include respond-below(sm) {
  .footer {
    &-wrapper {
      margin-left: 5%;
      width: 90%;
      .main {
        &-right {
          margin-bottom: 0;
        }
      }
    }
    .social {
      margin-bottom: 6px;
    }
    &-nav {
      height: 100px !important;
      .nav-item {
        width: auto !important;
        padding-right: 0 !important;
      }
    }
    .download_app {
      &-wrapper-content {
        margin-right: 5vw;
      }
    }
  }
}

