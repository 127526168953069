@import "../mixin/respond";
@import "../mixin/color";

.user-bonus {
  @include respond-below(sm) {
    width: auto;
  }
  &-data {
    background: $orange;
    align-items: center;
    height: 100%;
    @media (max-width: 600px) {
      margin-right: 15px;
    }
    @include respond-below(sm) {
      align-items: unset;
    }
    &-text {
      color: $text-color-white;
      &-value {
        font-size: 150%;
      }
      @include respond-below(sm) {
        line-height: normal;
        font-size: 20px;
        &-value {
          font-size: 32px;
          font-weight: bold !important;
        }
        &-text {
          font-size: 75%;
          font-weight: bold;
        }
      }
      @media (max-width: 368px) {
        &-text {
          font-size: 57%;
        }
      }
    }
    &-img {
      @include respond-below(sm) {
        padding-top: 6px;
        img {
          width: 25px;
        }
      }
    }
  }
  &-condition {
    height: 100%;
    background: $background-grey20;
    color: $orange;
    @include respond-below(sm) {
      text-align: center;
      line-height: normal;
      span {
        margin-bottom: 3px;
      }
    }
    span {
      font-size: 32px;
      line-height: 38px;
    }
  }
  &-info {
    font-size: 12px;
    line-height: 14px;
    p {
      color: $grey;
    }
  }
  &-link {
    text-decoration: underline;
    @include respond-below(sm) {
      font-size: 75%;
    }
    @media (max-width: 600px) {
      margin-bottom: 20px;
    }
  }
}

.content > .referral-profile {
  width: auto;
  margin-left: 0;
}

.referral-profile {

  .btn {
    border-radius: 10px;
  }

  .image-block {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */

    img {
      height: 75%
    }
  }

  .has-delimiter {

    @include respond-above(md) {
      background: url('../assets/banners/referral-block-delimiter.png');
      background-position-x: 340px;
      background-repeat: no-repeat;
    }

  }

  .has-delimiter-y {

    @include respond-below(md) {
      background: url('../assets/banners/referral-block-delimiter-y.png');
      background-position-y: bottom;
      background-position-x: center;
      background-repeat: no-repeat;
      background-size: 220px;

      margin-bottom: 20px;
      padding-bottom: 20px;
    }

  }

  .text-block {
    position: relative;

    small {
      font-size: 12px;
    }

    @include respond-below(md) {
      text-align: center;
    }
    p {
      line-height: 1;
    }
    .h4 {
      font-family: "Montserrat", Sans-Serif;
    }
  }

  .yellow {
    color: #FFF764;
    font-weight: 600;
    font-size: 90px;
    line-height: 60px;
    margin-bottom: 5px;

    @media (max-width: 890px) {
      font-size: 65px;
    }

    span {
      font-size: 50px;
    }

  }

  .header-text {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 0;
    p {
      margin-bottom: 0;
    }
  }

  .referral-main {
    background: $green20;
    box-sizing: border-box;
    border-radius: 12px;
    color: $main-text-color;
    @include respond-below(md) {
      border-radius: 0;
      border-left: 0;
      border-right: 0;
    }

    .img {
      img {
        float: right;
        @include respond-below(md) {
          float: unset;
          margin-left: auto;
          margin-right: auto;
          display: block;
        }
      }
    }
    .info {
      .title {
        font-size: 32px;
        line-height: 38px;
      }
      .subtitle {
        font-size: 21px;
        line-height: 25px;
        font-weight: 500;
      }
      .description {
        font-size: 14px;
        line-height: 20px;
        max-width: 500px;
        a {
          color: $main-text-color;
          text-decoration: underline;
        }
      }
      @include respond-below(md) {
        text-align: center;
        .description {
          max-width: unset;
        }
      }
    }

    .share {
      background: $background-white;
      border-radius: 10px;
      width: 55%;
      padding: 30px 60px 30px 60px;
      margin: 0 auto;

      @media (max-width: 900px) {
        width: 90%;
        padding: 40px 20px 40px 20px;
        margin-left: 5.5%;
      }


      &-block {
        input, button {
          border-radius: 10px;
        }
        input {
          background-color: white;
        }
      }

      &-code {
        border-radius: 10px;
        background-color: #FFF4D6;
      }
    }
  }
  .referral-friends {
    &-content {
      background: rgba(229, 229, 229, 0.3);
      box-sizing: border-box;
      font-size: 14px;
      line-height: 21px;
      border-radius: 12px;
      color: $main-text-color;
      @include respond-below(sm) {
        max-width: unset;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
      }
      &-phone, &-status {
        color: $grey40;
      }
      &-status.active {
        color: $green;
      }
      &-bonus {
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        color: $green;
      }
    }
    &-summary {
      max-width: 560px;
      font-size: 16px;
      line-height: 19px;
      color: $green;
    }
  }

  .referral-promo {
    background: url('../assets/landing/referral-promo-bg.jpg') no-repeat center center;
    background-size: cover;
    border-radius: 16px;

    @include respond-below(md) {
      border-radius: 0;
    }

    .share {
      background-color: #fff;
      border-radius: 12px;
    }
  }
}

.user-friend-label {
  background: url('../assets/banners/friends_badge.svg');
  display: inline-block;
  width: 39px;
  height: 17px;
  position: absolute;
  top: -4px;
  right: -3px;
}

.delivery-date {
  &__tabs {
    display: flex;
    overflow: auto;
    margin: 0.5rem 0;
  }

  &__tab {
    margin-right: 0.3rem;
    margin-bottom: 0.5rem;
  }

  &__day-ctrl {
    background-color: white;
    border: 2px solid $green70;;
    border-radius: 1.3rem;
    color: black;
    padding: 0.3rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 4px;
    min-width: 105px;
    height: 100%;
  }

  &__time {
    padding: 0.3rem 0;
    font-size: 1.2rem;
  }
  button {
    cursor: pointer;
  }
  button:focus {
    outline: none;
  }
}

.delivery-date .active {
  background-color: $green70;;
  color: white;
}

.profile-favorites {
  .list-group {
    button {
      text-align: left;
      margin-bottom: .5rem;
      border-radius: $br-1;
      position: relative;
      padding: 0.5rem 1.75rem 0.5rem 0.75rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: normal;

      &::after {
        content: '›';
        position: absolute;
        right: -10px;
        color: #FFF;
        transition: right .3s ease-in-out;
        font-size: 150%;
        font-weight: bold;
      }

      &.active::after, &.btn-success::after {
        right: 10px;
      }

      i {
        margin-right: .5rem;
      }

    }

  }

  &--products {
    .product-card--md .controls-container .quantity-control > div .btn {
      padding: 0.2rem 0.55rem;

    }
    .product-card--md .controls-container input.form-control {
      width: 2.25rem;
      padding-right: 0.25rem;
    }
  }

  &__product-row {
    display: flex;
  }

  &__product {
    display: block;
    flex-grow: 1;
  }

  &__action {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: start;
    width: 52px;

    &-btn {
      border-radius: 10rem;
      padding: .35rem .5rem;
      color: $grey40;
    }
  }

  &__btn {
    .btn {
      width: 42px;
      height: 42px;
      border-radius: 42px;

      i {
        vertical-align: middle;
        opacity: .5;
        color: $grey;
        transition: opacity .2s ease-in-out, color .2s ease-in-out;
      }

      &:hover, &:active {
        i {
          opacity: 1;
          color: $pink;
        }
      }
    }
  }

  &__timer {
    width: 42px;
    height: 42px;
    color: $pink;
  }
}
