.banner-advantage {
  $grey: #5E6366;
  letter-spacing: 0;

  .first-banner {
    & > div, & > a {
      width: 32%;
      height: 90px;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: top right;
      cursor: pointer;
      display: block;
      border-radius: 10px;
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
    }
    &__text {
      font-size: 14px;
      line-height: 17px
    }
    &__ban1 {
      background-image: url("../assets/banners/advantage/ban_1.png");
      background-color: #8BBBE9;
    }
    &__ban2 {
      background-image: url("../assets/banners/advantage/ban_2.png");
      background-color: #F7C359;
    }
    &__ban3 {
      background-image: url("../assets/banners/advantage/ban_3.png");
      background-color: #B7D052;
    }
  }

  $first-banner-sm-height: 170px;
  $first-banner-sm-width: 31%;
  .first-banner-sm {

    & > div, & > a {
      display: block;
      width: $first-banner-sm-width;
      height: $first-banner-sm-height;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: top right;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(8, 35, 48, 0.14);
      overflow-x: hidden;
    }

    &__bottom {
      position:relative;
      height: $first-banner-sm-height;
    }
    &__title {
      position: absolute;
      bottom: 0.7rem;
      font-size: 16px;
      line-height: 16px;
    }
    &__ban1 {
      background-image: url("../assets/banners/advantage/ban_1_mob.png");
      background-color: #8BBBE9;

    }
    &__ban2 {
      background-image: url("../assets/banners/advantage/ban_2_mob.png");
      background-color: #F7C359;

    }
    &__ban3 {
      background-image: url("../assets/banners/advantage/ban_3_mob.png");
      background-color: #BDCE78;
    }
  }
  .first-banner-sm-hint {
    & > div {
      width: $first-banner-sm-width;
    }
    &__text {
      font-size: 12px;
      line-height: 15px;
    }
  }

  $inner-banner-height: 64px;
  .inner-banner {
    background: #F4F3F1;
    height: $inner-banner-height;
    border-radius: 4px;
    & > div {
      width: 24%;
      background-repeat: no-repeat;
      background-size: 45px 45px;
      background-position: left center;
      background-origin: content-box, border-box;
    }

    &_checkout {
      background: white;
      border: 1px solid #E3E5E5;
    }

    &__ban1 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/icon1_2x.png");
      &:hover .inner-banner__hint {
        display: block;
      }
    }

    &__ban2 {
      background-image: url("../assets/banners/advantage/icon2_2x.png");
      &:hover .inner-banner__hint {
        display: block;
      }
    }

    &__ban3 {
      background-image: url("../assets/banners/advantage/icon3_2x.png");
      &:hover .inner-banner__hint {
        display: block;
      }
    }

    &__ban4 {
      background-image: url("../assets/banners/advantage/icon4_2x.png");
      &:hover .inner-banner__hint {
        display: block;
      }
    }

    &__ban-checkout1 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/ban_checkout_1.png");
    }

    &__ban-checkout2 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/ban_checkout_2.png");
    }

    &__ban-checkout3 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/ban_checkout_3.png");
    }

    &__ban-checkout4 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/ban_checkout_4.png");
    }


    &__middle {
      display: flex;
      justify-content: start;
      align-items: center;
      height: $inner-banner-height;
      margin-left: 60px;
    }

    &__text {
      padding-right: 1em;

      span {
        font-size: 15px;
        line-height: 17px;
        color: $grey;
        border-bottom: 1px dashed $grey;
      }
    }
    &__text_checkout {
      p {
        font-size: 14px;
        border-bottom: 0;
        display: block;
      }
    }

    &__hint {
      $border-color: #F1F3F4;
      display: none;
      position: absolute;
      margin-top: 5px;
      margin-left: 20px;
      width: 243px;
      box-shadow: 0 8px 16px rgba(8, 35, 48, 0.15);
      border-radius: 10px;
      border: 1px solid $border-color;
      background: #FFFFFF;
      z-index: 100;

      &::before, &::after {
        content: '';
        position: absolute;
        left: 40px;
        top: -30px;
        border: 10px solid transparent;
        border-bottom: 20px solid $border-color;
      }
      &::after {
        border-bottom: 20px solid white;
        top: -29px;
      }
    }
    &__hint-text {
      font-size: 12px;
      line-height: 16px;
      color: $grey;
    }

  }

  $inner-banner-height-sm: 74px;
  .inner-banner-sm {
    background: #F4F3F1;
    height: $inner-banner-height-sm;
    overflow: hidden;

    &__ban {
      padding-left: 20px;
      height: $inner-banner-height-sm - 10px;
      background-repeat: no-repeat;
      background-size: 32px 32px;
      background-position: left center;
      background-origin: content-box, border-box;
    }

    &__middle {
      height: $inner-banner-height-sm - 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      margin-left: 50px;
    }

    &__text {
      font-size: 16px;
      line-height: 16px;
      border-bottom: 0;
      display: block;
    }

    &__ban1 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/icon1_2x.png");
    }
    &__ban2 {
      background-image: url("../assets/banners/advantage/icon2_2x.png");
    }

    &__ban3 {
      background-image: url("../assets/banners/advantage/icon3_2x.png");
    }

    &__ban4 {
      background-image: url("../assets/banners/advantage/icon4_2x.png");
    }

    &_checkout {
      background: #F29D42;
      .inner-banner-sm__text {
        color: #FFFFFF;
      }
    }

    &__checkout-ban1 {
      margin: 0 auto;
      background-image: url("../assets/banners/advantage/scroll_icon_1.png");
    }
    &__checkout-ban2 {
      background-image: url("../assets/banners/advantage/scroll_icon_2.png");
    }

    &__checkout-ban3 {
      background-image: url("../assets/banners/advantage/scroll_icon_3.png");
    }

    &__checkout-ban4 {
      background-image: url("../assets/banners/advantage/scroll_icon_4.png");
    }

    .swiper-pagination-bullet-active {
      background: #FFFFFF;
    }

    .swiper-slide {
      width: 75%;
    }
  }

  .swiper-pagination {
    bottom: 0;

    &-bullet {
      background: #fff;
    }
  }
}
