@import "../mixin/color";
@import "../mixin/respond";

.popup-screen-bottom {
  position: fixed;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  bottom: 0;
  width: 50%;
  left: 25%;

  @include respond-below(md) {
    left: 4%;
    width: 92%;
  }

  z-index: 10000;

  background-color: #fff;
  border-radius: 12px 12px 0 0;

  cursor: pointer;
  transition: bottom 1s ease-in-out, top 1s ease-in-out;

  &--hidden {
    bottom: -20%;
  }

  &--shaking {
    animation-name: popup-screen-bottom-shaking;
    animation-duration: 4s;
    animation-delay: 2s;
    animation-iteration-count: infinite;

    &:hover {
      animation-iteration-count: 0;
    }
  }

  &__dragbar {
    height: .35rem;
    width: 10%;
    margin: .35rem auto;
    background-color: $background-grey30;
    border-radius: 1rem;

    @include respond-below(md) {
      width: 25%;
    }
  }
}

$popup-shaking-height: 10px;

@keyframes popup-screen-bottom-shaking {
  0% {
    padding-bottom: 0;
  }
  4% {
    padding-bottom: $popup-shaking-height;
  }
  8% {
    padding-bottom: 0;
  }
  12% {
    padding-bottom: $popup-shaking-height;
  }
  16% {
    padding-bottom: 0;
  }
  100% {
    padding-bottom: 0;
  }
}

.popup-feedback-products {
  text-align: center;
  width: 100%;
  left: 0;
  padding-top: 30px;

  &__list {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    height: auto;
    flex-wrap: nowrap;
    align-items: center;

    &--center {
      justify-content: center;
    }
  }

  &__btns {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px;
    margin-bottom: 16px;

    .icon {
      font-size: 20px;
      transform: rotateY(180deg) rotateZ(75deg);
    }

    button:first-child {
      background-color: #D4F2D6;
      border-radius: 16px;
      border: none;
      padding: 10px 0;
      font-family: 'Montserrat';
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      color: #53C95A;
      display: flex;
      justify-content: center;
      gap: 8px;
      align-items: center;
    }

    button:last-child {
      font-family: 'Montserrat';
      font-size: 15px;
      font-weight: 700;
      line-height: 18px;
      color: #fff;
      padding: 16px 0;
      border-radius: 16px;
      border: none;
      background-color: #53C95A;
    }
  }

  &__close {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 15px;
    top: 30px;
  }

  &__title {
    max-width: 250px;
    text-align: center;
    margin: 0 auto 16px auto;

    h3 {
      font-family: 'Montserrat';
      font-size: 21px;
      font-weight: 700;
      line-height: 26px;
      color: #333;
    }

    p {
      margin-bottom: 0;
      margin-top: 4px;
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      color: #828282;
    }
  }

  &__item {
    min-width: 83px;
    max-width: 83px;
    max-height: 83px;
    min-height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $grey20;

    border-radius: 6px;

    img {
      max-width: 100%;
      max-height: 100%;
      mix-blend-mode: darken;
    }
  }

  &.popup-screen-bottom {
    @include respond-above(md) {
      bottom: -2.5rem;
      transition-duration: .2s;

      &:hover {
        bottom: 0;
      }
    }
  }
}

.popup-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 400;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 4s;
}


/*
 * Floating cart element "FloatingCart"
 */
@include respond-below(md) {
  .floating-cart {
    display: flex !important;
  }
}
.floating-cart {
  height: 56px;
  position: fixed;
  bottom: 12px;
  left: 10px;
  right: 10px;
  background-color: #53C95A;
  border-radius: 1.125rem;
  text-align: center;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;

  .cart-text {
    font-size: 15px;
    font-weight: bold;
  }
  .cart-total {
    font-size: 12px;
    font-weight: 500;
  }
}

/*
 * Membership bottom-screen black popup element "MembershipSavingsPopup"
 */

@include respond-below(md) {
  body .membership-popup {
    bottom: 4px;
    border-radius: 1.125rem;
    font-size: 80%;

    left: 10px;
    right: 10px;

    &--padded {
      bottom: 80px;
    }

    .arrow {
      display: flex;
      align-items: center;
      font-size: 30px;
    }

    .img {
      display: none;
    }
  }
}

.membership-popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  height: 64px;
  transition: height .5s ease-in-out;
  overflow: hidden;

  .arrow {
    display: none;
  }

  > .content {
    position: relative;
    z-index: 2;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #FFF;
    height: 100%;

    &:hover {
      color: #FFF;
      cursor: pointer;
    }

    img {
      height: 100%;
    }
  }

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
}
