.publication-item {
  .image {
    height: 140px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .image-full {
    img {
      width: 100%;
    }
  }
  
  .delimiter {
    border-bottom: 1px dashed $grey30;
  }
}