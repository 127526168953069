.merchant-container {
  max-width: 1450px;
}

.title-tooltip {
  display: flex;
  gap: 5px;
  justify-content: center;
}

.title-tooltip-icon {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  align-items: center;
}

.tooltip {
  z-index: 999;
}

.menu-label-counter-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 100%;
}

.menu-label-wrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
