@import '../mixin/respond';
@import '@site/bootstrap/scss/mixins/breakpoints';

.layout-panel {
  background-color: #F1F1F1;
}

@include media-breakpoint-up(md) {
  .container-md {
    max-width: 720px;
  }
}

@include media-breakpoint-up(lg) {
  .container-md, .container-lg {
    max-width: 960px;
  }
}

@include media-breakpoint-up(xl) {
  .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
}

$grid-columns: 12;

/* Extra sizes */

$extra-sizes: (
  auto: auto,
);

// Each 5%
@for $i from 1 through 20 {
  $extra-sizes: map-merge($extra-sizes, (#{$i * 5}p: $i * 5%))
}

// Each column, like "col-2 = 12 (100%) / 2"
@for $i from 1 through $grid-columns {
  $extra-sizes: map-merge($extra-sizes, ($i: 100% / $grid-columns * $i))
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $name, $percent in $extra-sizes {
      .w#{$infix}-#{$name} {
        width: $percent;
      }
      .h#{$infix}-#{$name} {
        height: $percent;
      }
      .col#{$infix}-#{$name} {
        flex: 0 0 $percent;
        max-width: $percent;
      }
      .offset#{$infix}-#{$name} {
        margin-left: $percent;
      }
    }
  }
}
