
.see-all {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94px;
  max-height: 94px;
  max-width: 115px;
  margin: 0 .25rem;
  background-color: #F5F5F5;
  border-radius: 10px;
  color: #1E1E1E;
  gap: 2px;
  font-weight: 500;
}
