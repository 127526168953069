@import "../mixin/color";
@import "../mixin/respond";

[is="drawer"],.drawer-component {
  height: 40px;
  padding: 5px 14px;
  border-radius: 10px;
}

.navbar-toggler {
  .drawer-button {
    .fa-bars {
      height: 20px;
      padding: 5px;
    }
  }
}

.drawer {
  position: fixed;
  top: 0;
  left: -100%;
  bottom: 0;
  background-color: $background-white;
  z-index: 1010;

  transition: left .4s ease;

  display: block;

  overflow: hidden;

  white-space: nowrap;

  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;

  &-backdrop {
    z-index: 1000;
    opacity: 0;
    transition: opacity .5s ease;
    width: 0;
    display: block;

    &.show {
      width: 100%;
      opacity: .5;
    }
  }

  width: 90%;

  &-show {
    left: 0;
  }

  .text-center {
    position: absolute;
    z-index: 10;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  &-root-content{
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: .5rem;
    padding-bottom: 20px;

    .login-btn {
      background-color: $background-green;
      border-color: $green;
      border-radius: 10px;
    }
    .static-menu {
      color: $grey80 !important;

      a {
        color: $grey80 !important;

        &:hover, &:active {
          color: $grey70 !important;
        }
      }
    }


  }

  &-catalog {
    & > .nav {
      flex-wrap: nowrap;
    }

    .catalog-item {
      color: $grey90;

      display: flex;
      padding: 0 1rem 0 .5rem;
      align-items: center;
      border: 0;
      transition: padding-left .2s ease-in-out;

      & > img {
        width: 24px;
      }

      .catalog-item__pin-new {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 9px;
        background-color: $pink;
        margin-right: .25rem;
      }

      .catalog-name {
        flex-grow: 1;
        padding: 0.75rem;
      }

      .chevron {
        transition: transform 0.2s linear;
        color: $grey40;
      }

      &.active {
        padding-left: .75rem;

        .chevron {
          transform: rotate(180deg);
        }
      }
    }

    .catalog-item__farmers {
      font-weight: bold;
    }

    .catalog-child {
      padding: 0 1.5rem 0 1.2rem;

      &-item {
        display: block;
        padding: 0 .25rem;

        a {
          color: $grey90;
        }

        img {
          width: 100%;
          display: block;
          border-radius: 10px;
          position: relative;
          overflow: hidden;
          transition: opacity .2s ease-out;
          opacity: 0;
          object-fit: cover;

          @include aspect-ratio(106, 87);

          &.loaded {
            opacity: 100%;
          }
        }

        .catalog-name {
          white-space: normal;
          font-size: 85%;
          padding: 0.35rem 0;
        }
      }
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
  }

  &-referral {
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }

  .header-login {
    display: block;
    height: auto;

    &:hover {
      background-color: #f5f5f5;
    }

    &__holder {
      color: $grey90;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .fa {
        transition: transform .2s ease-in-out;
      }
    }

    .list-group {
      max-height: 0;
      overflow: hidden;

      transition: max-height .4s ease-in-out;

      &.visible {
        max-height: 10rem;
      }
    }

    .list-group-item {
      background-color: transparent;
      border: 0;
      padding: 0.5rem 1.25rem 0.5rem 0;

      &:first-child {
        margin-top: .5rem;
      }
    }

  }

  &-membership {
    display: flex;
    align-items: center;

    div:first-child img {
      max-width: 100%;
    }

    div:nth-child(2) {
      padding: 0 .75rem;
      color: #000;
      font-size: 90%;
      font-weight: bold;
    }

    div:last-child {
      padding-right: .5rem;
      > div {
        color: #000;
        line-height: 1;
        font-weight: bold;
        background-color: #ffd458;
        font-size: 90%;
        border-radius: 3rem;
      }
    }
  }
}
