@import "../mixin/color";

.filter-v2 {
  &__section > * {
    padding-bottom: 1.5rem;
  }

  &__content {
    display: flex;
    gap: 0.12rem;
    flex-wrap: wrap;
    max-height: 245px;
    overflow-y: scroll;
    padding-right: 8px;
    margin-right: 9px;
    padding-inline-start: 0;

    li {
      list-style: none;
    }
  }

  &__title {
    align-items: baseline;
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    color: $main-text-color;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__title-price {
    padding-bottom: 0.2rem;
  }

  &__title-ctrl {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__range {
    margin-bottom: 2.1rem;
    padding-left: 14px;
    padding-right: 16px;
  }

  &__checkbox {
    display: inline-block;

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] + label {
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      font-size: 0.78rem;
      cursor: pointer;
      border-radius: 10px;
      background-color: $grey20;
      padding: 5px 12px;
      display: inline-block;
      -moz-user-select: -moz-none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    input[type="checkbox"]:checked + label {
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
      background-color: $green; color: white;
      border-color: none;
    }

    input[type="checkbox"]:not(:checked)+ label:hover  {
        background-color: #EEFAEF;
        color:  #53C95A;
    }
  }

  &__toggler {
    display: flex;
    justify-content: space-between;
    margin-right: 1px;
  }

  &__togler-label {
    font-size: 1rem;
    font-family: "Montserrat", sans-serif;
    color: $main-text-color;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    span {
      font-weight: normal;
      font-size: 13px;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }
  }

  &__reset {
    border: 0;
    border-bottom: 2px dotted #828282;
    padding: 0;
    color: #828282;
    font-size: 0.75rem;
    background-color: transparent;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
  }
}

.filter-scroll::-webkit-scrollbar {
  width: 3px;
  background-color: $background-grey30;
  border-radius: 3px;
}

.filter-scroll::-webkit-scrollbar-thumb {
  background: $background-grey40;
  border-radius: 3px;
}


summary {list-style: none}
summary::-webkit-details-marker {display: none; }

details[open] .caret-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: url("../assets/icons/caret-up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 22px;
  height: 22px;
}

details .caret-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  background-image: url("../assets/icons/caret-down.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 22px;
  height: 22px;
}

.filters-modal {
  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__title {
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }

  &__items {
    margin-bottom: 4rem;
  }

  &__footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    bottom: 1rem;
  }

  &__apply-btn {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 95%;
    align-items: center;
    background-color: #53C95A;
    border-radius: 16px;
    color: white;
    padding: 6px 10px;
    border: none;
    height: 58px;

    &:disabled {
      background-color: #EEFAEF;
      color: #80d487;
    }
  }
}

.filters-mobile-btn {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  background-color: transparent;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 2px 4px;
  color: #4F4F4F;
  font-size: inherit;
  height: 100%;

  &__icon {
    background: url("../assets/icons/filters-icon.svg") no-repeat;
    width: 22px;
    height: 16px;
  }
  &__count {
    display: inline-block;
    background-color: #F57D40;
    border-radius: 100%;
    color: white;
    width: 24px;
    height: 24px;
    padding-top: 2px;
    font-size: 14px;
  }
}

select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  z-index: 1;
  color: #4F4F4F;

  &::-ms-expand {
    display: none;
  }

  outline: none;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  min-width: 170px;

  border: 1px solid #E6E6E6;
  border-radius: 10px;
  background-color: transparent;
  padding: 0 0.5em;
  font-size: inherit;
  cursor: pointer;
  line-height: 1.1;

  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: #4F4F4F;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: inherit;
}
