
.delivery-label {
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: #4F4F4F;
  margin-bottom: 4px;
}

@media screen and (min-width: 1024px) {
  .delivery-label {
    margin-bottom: 0;
  }
}
