.landing-referral {
  margin-top: 2rem;
  border-radius: 16px;
  overflow: hidden;

  &-header {
    background: $background-green10;
    color: $main-text-color;

    padding-top: 4rem;

    &-logo {
      margin-bottom: 100px;
      img {
        margin-top: 18px;
        margin-left: 40px;
      }
    }
    &-head {
      &-logo {
        margin-bottom: 8px;
      }
      &-logo-2 {
        margin-top: 60px;
        margin-bottom: 30px;
      }
      &-title {
        font-size: 34px;
        font-weight: 700;
        line-height: 45px;
        text-align: center;
        margin-bottom: 28px;
        font-style: italic;
        @include respond-below(sm) {
          font-size: 26px;
          line-height: 33px;
        }
      }
      &-promocode {
        background: $background-white;
        font-size: 18px;
        text-align: center;
        color: $contrast;
        padding: 14px;
        max-width: 300px;
        margin-bottom: 28px;
        cursor: pointer;
        border-radius: 14px;

        @include respond-below(sm) {
          font-size: 16px;
          line-height: 27px;
          padding: 12px 20px;
        }
      }
      &-conditions {
        font-size: 16px;
        line-height: 25px;
        max-width: 450px;
        margin-bottom: 32px;
        a {
          color: $main-text-color;
          text-decoration: underline;
          &:hover {
            color: $green;
          }
        }
      }
      &-btn {
        margin-bottom: 24px;
        a {
          background-color: $background-green;
          border-radius: 40px;
          font-size: 21px;
          line-height: 25px;
          color: $text-color-white;
          padding: 16px 26px;
          max-width: 325px;
          border-top: 0;
          font-weight: 700;
          &:hover {
            background-color: $btn-hover-green;
            color: $text-color-white;
          }
        }
      }
      &-apps {
        max-width: 320px;
        margin-bottom: 60px;
        img {
          width: 150px;
          height: 100%;
        }
      }
    }
  }
  &-advantages {
    background-color: rgba(251, 244, 238, 0.5);
    border-top: 1px solid $grey30;
    border-bottom: 1px solid $grey30;
    padding: 60px 0;
    @include respond-below(sm) {
      padding: 40px 0;
    }
    &-title {
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      color: $main-text-color;
      margin-bottom: 24px;
    }
    .main-info {
      background: unset;
      .advantage-text {
        font-size: 16px;
        line-height: 19px;
        color: $main-text-color;
        @include respond-below(sm) {
          font-size: 12px;
          line-height: 14px;
          text-align: center;

          color: $grey;
        }
      }
      .col-sm-3.col-2-5 {
        flex: 0 0 20% !important;
        max-width: 20% !important;
      }
      .col-4.col-2-5 {
        @include respond-below(sm) {
          flex: 0 0 33.3% !important;
          max-width: 33.3% !important;
        }
      }
      .col-6.col-2-5 {
        @include respond-below(sm) {
          flex: 0 0 50% !important;
          max-width: 50% !important;
        }
      }
    }
  }
  &-fresh {
    height: 700px;
    color: $text-color-white;
    z-index: 999;
    overflow-y: hidden;
    position: relative;
    @include respond-below(lg) {
      background-image: url('https://arbuz.kz/static/platform/frontend/assets/landing/image.jpg');
      background-position: top;
      background-repeat: no-repeat;
      background-attachment: unset;
      background-size: cover;
      height: 500px;
    }
    .parallax-container {
      z-index: 9;
      img {
        width: 100%;
        margin-top: 25px;
      }
    }
    &-wrapper {
      @include respond-below(lg) {
        padding-bottom: 100px;
      }
    }
    &-text {
      position: absolute;
      left: 0;
      width: 100%;
      top: 213px;
    }
    &-title {
      font-size: 54px;
      line-height: 64px;
      max-width: 650px;
      font-weight: 800;
      @include respond-below(lg) {
        font-size: 44px;
        line-height: 53px;
        letter-spacing: -1.5px;
      }
      @include respond-below(sm) {
        font-size: 34px;
        line-height: 43px;
        letter-spacing: -1.5px;
      }
    }
    &-subtitle {
      font-size: 21px;
      line-height: 25px;
      max-width: 450px;
    }
  }
  &-delivery {
    color: $text-color-white;
    height: auto;
    position: relative;
    &-content {
      background: #02bb63;
      @include respond-below(lg) {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
    &-inside {
      max-width: 410px;
      margin-left: 80px;
      @include respond-below(lg) {
        margin-left: unset;
        padding-left: 25px;
        max-width: unset;
        height: 480px;
      }
      &-title {
        font-size: 54px;
        font-weight: 700;
        line-height: 64px;
        margin-bottom: 8px;
        @include respond-below(lg) {
          font-size: 44px;
          line-height: 53px;
          letter-spacing: -1.5px;
        }
        @include respond-below(sm) {
          font-size: 34px;
          line-height: 43px;
          letter-spacing: -1.5px;
        }
      }
      &-subtitle {
        font-size: 28px;
        line-height: 140%;
      }
      &-text {
        @include respond-below(lg) {
          font-size: 18px;
          line-height: 170%;
        }
      }
    }
    &-img {
      background-image: url('https://arbuz.kz/static/platform/frontend/assets/landing/Mask%20Group.jpg');
      background-attachment: scroll;
      background-position: center 60%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 900px;
      margin-left: -15px;
      margin-right: -15px;
      @include respond-below(lg) {
        height: 700px;
      }
    }
  }
  &-bonus {
    background: #56c0f5;
    position: relative;
    color: $text-color-white;
    padding-top: 40px;
    padding-bottom: 40px;
    &-wrapper {
      align-items: center;
      @include respond-below(lg) {
        align-items: unset;
        flex-direction: column;
      }
    }
    &-inside {
      margin-left: 80px;
      max-width: 520px;
      @include respond-below(lg) {
        max-width: unset;
        width: 100%;
        margin-left: unset;
        padding-left: 25px;
      }
      &-title {
        font-size: 54px;
        font-weight: 700;
        line-height: 64px;
        margin-bottom: 8px;
        @include respond-below(lg) {
          font-size: 44px;
          line-height: 53px;
          letter-spacing: -1.5px;
        }
        @include respond-below(sm) {
          font-size: 34px;
          line-height: 43px;
          letter-spacing: -1.5px;
        }
      }
      &-subtitle {
        font-size: 28px;
        line-height: 140%;
      }
    }
    &-screen {
      img {
        max-height: 520px;
        @include respond-below(lg) {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
    .arrow {
      margin-top: -150px;
    }
  }
  &-quality {
    position: relative;
    height: 700px;
    color: $text-color-white;
    z-index: 999;
    overflow-y: hidden;
    @include respond-below(lg) {
      background-attachment: unset;
      background-size: cover;
      background-image: url('https://arbuz.kz/static/platform/frontend/assets/landing/CI1A1084-14.jpg');
      background-position: center;
      background-repeat: no-repeat;
      height: 500px;
    }
    &-wrapper {
      @include respond-below(lg) {
        padding-bottom: 100px;
      }
    }
    .parallax-container {
      z-index: 9;
      img {
        width: 100%;
        margin-top: 25px;
      }
    }
    &-text {
      position: absolute;
      left: 0;
      width: 100%;
      top: 213px;
    }
    &-title {
      font-size: 54px;
      line-height: 64px;
      max-width: 650px;
      font-weight: 800;
      @include respond-below(lg) {
        font-size: 44px;
        line-height: 53px;
        letter-spacing: -1.5px;
      }
      @include respond-below(sm) {
        font-size: 34px;
        line-height: 43px;
        letter-spacing: -1.5px;
      }
    }
    &-subtitle {
      font-size: 21px;
      line-height: 25px;
      max-width: 450px;
    }
  }
  &-reviews {
    background: #f3f6f9;
    &-title {
      font-size: 38px;
      line-height: 45px;
      text-align: center;
      color: $contrast;
      font-weight: 800;
      margin-top: 18px;
    }
    &-item {
      background: $background-white;
      height: 300px;
      position: relative;
      padding-left: 16px;
      padding-right: 16px;
      @include respond-below(lg) {
        flex: 0 0 auto;
        width: calc(100% - 25px);
        margin-right: 14px;
        &:last-of-type {
          margin-right: 0;
        }
      }
      &-text {
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: $main-text-color;
        padding-top: 50px;
      }
      &-sign {
        position: absolute;
        bottom: 8px;
        width: calc(100% - 30px);
      }
      &-author,
      &-source {
        font-size: 16px;
        line-height: 19px;
        text-align: center;
      }
      &-author {
        color: #1b8fca;
        margin-bottom: 4px;
      }
      &-source {
        color: $grey40;
      }
    }
    .scrolling-wrapper {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      width: calc(100% + 30px);
      margin-left: -15px;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &-download {
    position: relative;
    background: $pink;
    color: $text-color-white;
    padding-top: 80px;
    padding-bottom: 80px;
    @include respond-below(lg) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &-wrapper {
      justify-content: space-between;
      @include respond-below(lg) {
        justify-content: unset;
        flex-direction: column;
      }
    }
    &-inside {
      margin-left: 80px;
      max-width: 520px;
      @include respond-below(lg) {
        max-width: unset;
        margin-left: unset;
        padding-left: 25px;
      }
      &-title {
        font-size: 54px;
        font-weight: 700;
        line-height: 64px;
        margin-bottom: 24px;
        margin-top: 80px;
        @include respond-below(lg) {
          font-size: 44px;
          line-height: 53px;
          margin-top: 0;
          letter-spacing: -1.5px;
        }
        @include respond-below(sm) {
          font-size: 34px;
          line-height: 43px;
          letter-spacing: -1.5px;
        }
      }
      &-icons {
        margin-bottom: 42px;
        span {
          cursor: pointer;
        }
      }
      &-content {
        font-size: 21px;
        line-height: 140%;
        height: 160px;
        @include respond-below(sm) {
          height: 180px;
        }
        .badges {
          line-height: 42px;
          @include respond-below(lg) {
            line-height: 32px;
          }
          .badge {
            font-size: 14px;
            color: $main-text-color;
            background: $background-white;
            line-height: normal;
            border-radius: 35px;
            padding: 8px 16px;
            font-weight: 400;
            @include respond-below(lg) {
              font-size: 10px;
              border-radius: 30px;
              padding: 4px 12px;
            }
            &:last-of-type {
              padding-right: 3px;
              @include respond-below(lg) {
                padding-right: 1px;
              }
            }
            .mc {
              background: $background-green;
              border-radius: 35px;
              color: $text-color-white;
              padding: 5px 3px;
              @include respond-below(lg) {
                padding: 3px;
              }
            }
          }
        }
      }
      &-apps {
        img {
          width: 160px;
          @include respond-below(lg) {
            width: calc(47.5% - 25px);
          }
        }
      }
    }
    &-screen {
      margin-right: 80px;
      @include respond-below(lg) {
        margin-right: unset;
        margin-top: 42px;
        img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
      img {
        max-height: 520px;
      }
    }
  }
  .white-logo {
    position: absolute;
    left: 95px;
    top: 40px;
    z-index: 2;
    @include respond-below(lg) {
      display: none;
    }
  }
}

.referral-popover {
  border: 0 !important;
  color: $main-text-color !important;
  max-width: 220px !important;
  font-weight: 600 !important;
  font-size: 14px;
  top: -16px !important;
  background: $background-white !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border: 0;
    z-index: 1;
  }

  &[x-placement^='top'] {
    margin-bottom: 5px;

    .tooltip-arrow {
      margin-top: 0;
      margin-bottom: 0;
    }

    .tooltip-arrow {
      background: $background-white;
      border: 1px solid $background-white;
      position: inherit !important;
    }
    .tooltip-arrow:after,
    .tooltip-arrow:before {
      top: 100%;
      left: calc(50% - 5px) !important;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .tooltip-arrow:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: $background-white;
      border-width: 5px;
      margin-left: -5px;
    }
    .tooltip-arrow:before {
      border-color: rgba(224, 224, 224, 0);
      border-top-color: $background-white;
      border-width: 6px;
      margin-left: -6px;
    }
  }

  .tooltip-content {
    line-height: normal;
    font-size: 80%;
  }
}

.referral-advantages {
  max-width: 1600px;
}

.referral-card {
  list-style: none;
  margin: 10px;
  background-color: $background-grey10;
  border-radius: 14px;
  flex: 1 1 0;

  &__content {
    padding-top: 26px;
    padding: 22px;
    padding-bottom: 0;
    height: 150px;

    @include respond-below(lg) {
      height: 123px;
      padding: 18px;
    }

    h3 {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &__header {
    font-style: italic;
  }

  &__img {
    object-fit: cover;
    width: 100%;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }

  @include respond-below(lg) {
    flex: 100%;
  }
}

.referral-footer {
  margin: 50px;

  h3 {
    padding: 40px 0;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    font-style: italic;
  }
}

.advert-popup-app {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9000;
  transition: opacity .5s ease-in-out;
  overflow-y: scroll;

  .popup-body {
    transition: height .5s ease-in-out;
    overflow: hidden;
    border-radius: 1rem;
    max-width: 540px;
    margin: 0 auto;
    position: relative;
  }

  .h1 {
    font-size: 36px;
    margin: 0 1rem;
  }

  .h2 {
    margin: 2rem 2rem;
  }

  p {
    margin: 1rem 2rem;
    font-size: 13px;
    font-weight: 500;
  }

  .logo {
    width: 90px;
    margin-top: 1rem;
    margin-bottom: 2rem;

    img {
      width: 100%;
    }
  }

  .go-to-site {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #000;
    z-index: 100;
  }

  .swiper-holder {
    position: relative;
    aspect-ratio: 1 / 1.2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .screen-slider {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: auto;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { /* Chrome */
      display: none;
    }

    .screen-slide {
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: stretch;

      &:nth-child(1) {
        .scroll-btn-wrapper {
          margin-bottom: 1rem;
        }

        padding-bottom: 1rem;

        .swiper-container {
          margin: 1rem 0 0 0;
          width: 100%;
          position: relative;

          &:before, &:after {
            content: ' ';
            position: absolute;
            top: 0;
            bottom: 0;
            width: 20%;
            z-index: 100;
          }

          &:before {
            left: 0;
            background-image: linear-gradient(to left, rgba(246, 252, 247, 0), rgba(246, 252, 247, 1))
          }

          &:after {
            right: 0;
            background-image: linear-gradient(to right, rgba(246, 252, 247, 0), rgba(246, 252, 247, 1))
          }
        }

        .swiper-slide {
          display: flex;
          align-items: center;

          //div {
          //  position: relative;
          //  width: 100%;
          //}

          img {
            width: 80%;
            transition: width .2s ease-in-out;
          }

          &-active {
            img {
              width: 100%;
            }
          }
        }
      }
      &:nth-child(2) {
        .btn--app {
          color: #000000;
          background-color: $secondary50;
        }
        .btn--scroll, .btn--scroll-up {
          color: $secondary70;
          background-color: $secondary20;
        }
      }
      &:nth-child(3) {
        .btn--app {
          color: #FFFFFF;
          background-color: $accent;
        }
        .btn--scroll, .btn--scroll-up {
          color: $accent;
          background-color: $accent20;
        }
      }
      &:nth-child(4) {
        .btn--app {
          color: #000000;
          background-color: $secondary50;
        }
        .btn--scroll, .btn--scroll-up {
          color: $secondary70;
          background-color: $secondary20;
        }
      }
      &:nth-child(5) {

      }

      .img {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        margin-bottom: 1rem;

        img {
          width: 100%;
        }
      }
    }
  }

  .review {
    position: relative;
    width: 100%;

    .h4 {
      font-size: 1.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }

  .review-content {

    img {
      width: 100%;
    }
  }

  .btn-action-secondary {
    color: $grey90;
    background-color: $secondary;

    padding: .5rem 2rem;
    border-radius: 1rem;

    &:hover {
      color: black;
      background-color: $btn-hover-secondary;
    }
  }

  .scroll-btn-wrapper {
    display: block;
    margin-bottom: 3rem;
    margin-top: 1rem;
    font-weight: bold;
    text-align: center;

    .btn--scroll-up {
      display: inline-block;
      margin-right: 1rem;
      padding: .5rem 1rem;
      border-radius: 1rem;
    }

    .btn--scroll {
      display: inline-block;
      padding: .5rem 1rem;
      border-radius: 1rem;

      .fa {
        margin-right: .5rem;
      }
    }
  }

  .btn--app {
    display: block;
    width: 80%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 1.2rem;
    color: #ffffff;
    font-weight: bold;
  }
}
