
.sponsored {
  color: #FFFFFF;
  font-family: 'Inter';
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

@media screen and (min-width: 768px) {
  .sponsored {
    color: #8C8C8C;
  }
}
