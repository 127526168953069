// * Green palette
$green: #53C95A;
$green10: #F6FCF7;
$green20: #EEFAEF;
$green30: #D4F2D6;
$green40: #A9E4AC;
$green50: $green;
$green60: #2DB333;
$green70: #29A630;
$green80: #29652D;
$green90: #193C1B;

// * Secondary color palette
$secondary: #FFD458;
$secondary10: #FFFDF7;
$secondary20: #FFFBEF;
$secondary30: #FFF4D6;
$secondary40: #FEE9AE;
$secondary50: #F8C94F;
$secondary60: #FED362;
$secondary70: #F0AE2E;
$secondary80: #AD7F00;
$secondary90: #4D401D;

$secondary: $secondary60;

$btn-hover-secondary: $secondary50;

// * Grey palette
$grey: #828282;
$grey10: #FCFCFC;
$grey20: #F5F5F5;
$grey30: #E6E6E6;
$grey40: #BDBDBD;
$grey50: #757575;
$grey60: #696969;
$grey70: #5c5c5c;
$grey80: #4F4F4F;
$grey90: #333333;

// * Background colors
$background-white: #FFFFFF;
$background-grey: $grey;
$background-grey10: $grey10;
$background-grey20: $grey20;
$background-grey30: $grey30;
$background-grey40: $grey40;
$background-grey80: $grey80;
$background-green: $green;
$background-green10: $green10;
$background-green20: $green20;
$background-green70: $green70;

// * Other colors
$main-text-color: #333333;
$text-black: #1E1E1E;
$contrast: #000000;
$text-color-white: #FFFFFF;
$green-secondary: #89D345;
$orange: #F57D40;
$pink: #E56058;
$red: #FD4438;
// TODO Уточнить цвет красного ховера
$red-hover: #f41000;

$accent10: #F6FCF7;
$accent20: #EEFAEF;
$accent30: #D4F2D6;
$accent40: #A9E4AC;
$accent50: #68CC6E;
$accent60: #53C95A;
$accent70: #3E9743;
$accent80: #29652D;
$accent90: #193C1B;

$accent: $accent60;

$radio-default: $grey40;
$radio-disabled: $grey30;
$radio-checked: $green;

$btn-hover-green: $accent50;

$favourites: $pink;
$error: $red;

$corporate-bg: #0d6aad;

// * Border Color: Grey30
// * Border Color Light: Grey20

@mixin darken-content {
  position: relative;

  &:after {
    position: absolute;
    content: '';
    display: block;
    top: 0; left: 0; height: 100%; width: 100%;
    background: rgba(27, 27, 27, 0.55);
    mix-blend-mode: multiply;
    z-index: 100 !important;
  }
}

@function encode-color($string) {
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');

    @return '%23' + $string;
  }

  @return $string;
}

$quantity-control-bg: $background-grey20;
$quantity-control-bg--hover: $background-grey30;
$quantity-control-text: $grey80;
