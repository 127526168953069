@import "../mixin/color";
@import "../mixin/border";

.giftcard__amount {
  .btn {
    border-width: 3px;
    border-color: transparent;

    padding: 6px 10px;

    &--accent30 {
      border-color: $accent60;
    }
  }
}

.giftcard-checkout__label {
  color: $grey80;
}

.giftcard-templates {

  &__item {
    margin-top: .5rem;
  }

  &__item--active &__template {
    border-width: 3px;
  }

  &__template {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    aspect-ratio: 4/2;
    position: relative;

    cursor: pointer;

    border-radius: $br-1;

    border: 0 solid $green;

    transition: border .1s ease-in-out;

    .fa-stack {
      position: absolute;
      top: .5rem;
      left: .5rem;
    }
  }
}
