@import '../mixin/color';
@import '../mixin/respond';

.labels {
  display: flex;
  align-items: flex-start;
  gap: 0.2rem;

  &-item {
    border-radius: 8px;
    padding: 0 5px;
    font-size: 12px;
    position: relative;
    z-index: 1000;
    background-color: $secondary;
    display: flex;
    align-items: center;
    column-gap: 2px;

    &__gift {
      background: #F57D40;
    }

    &__white-on-green {
      color: $text-color-white;
      background: #53C95A;
    }
  }

  &-discount {
    background-color: $background-grey80;
  }

  &-element {
    &-icon {
      padding: 0 0 0 0;
      height: 10px;
      border-radius: 0;
    }
  }
}
