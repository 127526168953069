
.formen-modal-dialog {
  max-width: 680px !important;
  max-height: 400px !important;
  .first-order-modal {
    background: #FED674 !important;
    border-radius: 16px !important;
    margin-top: 7rem;
    .close {
      position: absolute;
      right: 0;
      text-shadow: none;
      color: white;
      z-index: 999;
      span {
        background-image: url("../../assets/en/banners/close-btn.svg");
        background-repeat: no-repeat;
        background-size: 100% auto;
        height: 40px;
        width: 40px;
        display: block;
      }
    }
    .pinemelon-text {
      background-image: url("../../assets/mail/pinemelon-logo-text.png");
      background-repeat: no-repeat;
      background-size: 100% auto;
      height: 40px;
      width: 47px;
      display: block;
    }
    .promo-field {
      border-style: dashed;
      border-width: 1px;
      border-radius: 10px;
      padding: 5px;
      background-color: #fee9ae;
    }
    .font-200 {
      font-size: 130%;
      color: black;
    }
    .font-150 {
      font-size: 100%;
      color: black;
    }
    .desc {
      font-size: 12px;
      line-height: 16px;
    }
    .form-content {
      p {
        line-height: normal;
      }
      @media (max-width: 992px) {
        .font-200 {
          font-size: 150%;
        }
        .font-150 {
          font-size: 100%;
        }
      }
      @media (max-width: 400px) {
        .font-200 {
          font-size: 100%;
        }
        .font-150 {
          font-size: 75%;
        }
      }
    }
  }
  #right-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0 16px 16px 0;
  }
}
